<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'Forget_password' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<form [formGroup]="form" (ngSubmit)="send()">
    <div mat-dialog-content style="text-align: center;">
        <div [ngClass]="{'row': appServiceScreenDesktop}" style="text-align: left;">
            <div class="col-12" style="margin-bottom: 20px;">
                <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Email' | translate}}</label>
                <input formControlName="email" type="email" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="Example: your_mail@domain.com">
            </div>
        </div>
    </div>
    <mat-dialog-actions style="justify-content: center;">
        <button *ngIf="isLoading else hideLoading" [disabled]="isButton" [ngStyle]="{'margin': appServiceScreenMobile ? '0 0 10px 0' : '20px 20px 5px 20px', 'background-color': isButton ? '#CCCCCC' : '#0e8667', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="width: 100%;border-radius: 8px;font-weight: 700;color: white;" mat-button>{{'Submit' | translate}}</button>
        <ng-template #hideLoading>
            <app-loading></app-loading>
        </ng-template>
    </mat-dialog-actions>
</form>
<p [ngStyle]="{'padding': appServiceScreenMobile ? '0 5px' : '0 25px 30px', 'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="display: flex;justify-content: space-between;">
    Already an account? <label style="font-weight: 300;cursor: pointer;color: #0e8667;" (click)="callLogin()">Log In</label>
</p>
