import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { ParentComponent } from './components/pages/parent/parent.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { PrintComponent } from './components/pages/print/print.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { MyOrderComponent } from './components/pages/my-order/my-order.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { SuccessComponent } from './components/pages/my-order/success/success.component';
import { UploadPhotoComponent } from './components/pages/photo/upload-photo/upload-photo.component';
import { AuthguardGuard } from './authguard.guard';
import { PhotoComponent } from './components/pages/photo/photo.component';
import { ListPhotoComponent } from './components/pages/photo/list-photo/list-photo.component';
import { EditorComponent } from './components/pages/photo/editor/editor.component';
import { UnsuccessComponent } from './components/pages/my-order/unsuccess/unsuccess.component';
import { FailedComponent } from './components/pages/my-order/failed/failed.component';
import { AccountComponent } from './components/pages/account/account.component';
import { EmailVerifyComponent } from './components/pages/email-verify/email-verify.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: '', component: ParentComponent, children: [
      {path: '', component: HomeComponent},
      {path: 'about-us', component: AboutUsComponent},
      {path: 'print', component: PrintComponent},
      {path: 'contact', component: ContactComponent},
      {path: 'reset-password/:token', component: ResetPasswordComponent},
      {path: 'email-verify/:text', component: EmailVerifyComponent},
      {path: 'my-order', component: MyOrderComponent, canActivate: [AuthguardGuard]},
      {path: 'order-finish', component: SuccessComponent, canActivate: [AuthguardGuard]},
      {path: 'order-unfinish', component: UnsuccessComponent, canActivate: [AuthguardGuard]},
      {path: 'order-error', component: FailedComponent, canActivate: [AuthguardGuard]},
      {path: 'cart', component: CartComponent, canActivate: [AuthguardGuard]},
      {path: 'account', component: AccountComponent, canActivate: [AuthguardGuard]},
      {path: 'privacy-policy', component: PrivacyPolicyComponent},
    ]
  },
  {
    path: 'photo/:id/:ordered/:uploaded/:addToCart', component: PhotoComponent, children: [
      {path: '', component: UploadPhotoComponent, canActivate: [AuthguardGuard]},
      {path: 'list', component: ListPhotoComponent, canActivate: [AuthguardGuard]},
      {path: 'editor', component: EditorComponent, canActivate: [AuthguardGuard]}
    ]
  },
  {path: 'editor/:id/:addToCart/:page', component: EditorComponent, canActivate: [AuthguardGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
