<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label style="font-size: 20px;font-weight: 700;">{{'Order_confirmation' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<div mat-dialog-content>
    <p style="font-size: 16px;font-weight: 400;color: #555555;" [innerHtml]="myHtml"></p>
</div>
<mat-dialog-actions style="display: flex;justify-content: center;" [ngStyle]="{'display': appServiceScreenMobile ? 'flex' : '', 'align-items': appServiceScreenMobile ? 'center' : '', 'margin': appServiceScreenMobile ? '' : '0px 20px 20px 20px'}">
    <app-loading *ngIf="isLoading == false; else hideLoading"></app-loading>
    <ng-template #hideLoading>
        <div class="col-6" *ngIf="urlPayment == ''">
            <button (click)="closeBottomSheet()" [mat-dialog-close]="true" style="margin: -5px;width: 100%;border-radius: 8px;border: 1px solid #DDDDDD;background-color: white;font-size: 16px;font-weight: 700;color: black;" mat-button>{{'Review_Again' | translate}}</button>
        </div>
        <div class="col-6" *ngIf="urlPayment == ''">
            <button (click)="callPaymentMethod()" style="margin: 5px;width: 100%;border-radius: 8px;background-color: #0e8667;font-size: 16px;font-weight: 700;color: white;" mat-button>{{'Confirm_Order' | translate}}</button>
        </div>
        <div class="col-12" *ngIf="urlPayment != ''">
            <button (click)="payment(urlPayment)" style="margin: 5px;width: 100%;border-radius: 8px;background-color: #0e8667;font-size: 16px;font-weight: 700;color: white;" mat-button>{{'order_payment' | translate}}</button>
        </div>
    </ng-template>
</mat-dialog-actions>
