<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'Log_in' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<form [formGroup]="form" (ngSubmit)="existingUser ? send() : checkUser()">
    <div mat-dialog-content style="text-align: center;">
        <div [ngClass]="{'row': appServiceScreenDesktop}" style="text-align: left;">
            <div class="col-12" style="margin-bottom: 20px;">
                <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Email_or_Phone_number' | translate}}</label>
                <input formControlName="email" type="email" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="Example: 0811xxxxx / your_mail@domain.com">
            </div>
            <div class="col-12" *ngIf="existingUser">
                <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Password' | translate}}</label>
                <div class="input-group">
                    <input formControlName="password" type="{{showPassword}}" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-right: 1px;border-bottom-left-radius: 8px;border-top-left-radius: 8px;" class="form-control" placeholder="Example: P4ssw0rd">
                    <div (click)="showHidePassword()" class="input-group-btn" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-bottom-right-radius: 8px;border-top-right-radius: 8px;border: 1px solid #ced4da;display: flex;justify-content: center;align-items: center;">
                        <span class="btn btn-default">
                          <i [ngClass]="{'bi bi-eye-slash': showPassword == 'password', 'bi bi-eye': showPassword == 'text'}"></i>
                        </span>
                    </div>
                </div>
                <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 300;cursor: pointer;text-align: end;padding-top: 5px;color: #0e8667;" (click)="callForgetPassword()">
                    {{'Forget_password' | translate}}
                </p>
            </div>
        </div>
    </div>
    <mat-dialog-actions style="justify-content: center;">
        <button *ngIf="isLoading else hideLoading" [disabled]="isButton" [ngStyle]="{'margin': appServiceScreenMobile ? '0 0 10px 0' : '20px 20px 5px 20px', 'background-color': isButton ? '#CCCCCC' : '#0e8667', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="width: 100%;border-radius: 8px;font-weight: 700;color: white;" mat-button>{{setButton | translate}}</button>
        <ng-template #hideLoading>
            <app-loading></app-loading>
        </ng-template>
    </mat-dialog-actions>
</form>
<p [ngStyle]="{'padding': appServiceScreenMobile ? '0 5px' : '0 25px', 'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="display: flex;justify-content: space-between;">
    {{'Dont_have_an_account' | translate}} &nbsp;<label style="font-weight: 300;cursor: pointer;color: #0e8667;" (click)="callRegistration()"> {{'Create_Account' | translate}}</label>
</p>
