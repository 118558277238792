import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { BannerModel } from 'src/app/models/banner-model';
import { ApiService as ApiBanner } from 'src/app/services/banner/api.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent {
  sliderArrowShow: boolean = false;
  sliderInfinite: boolean = true;
  sliderImagePopup: boolean = false;
  sliderAutoSlide: boolean = true;
  sliderSlideImage: Number = 1;
  sliderAnimationSpeed: any = 1;
  slideOrderType:string = 'DESC';
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  isLoading: boolean = true;
  data!: BannerModel.Result[];

  constructor(appService: AppService, private apiBanner: ApiBanner) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.getBanner()
  }

  async getBanner() {
    (await this.apiBanner.getBanner())
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.data = v.results
      },
      error: (e) => {
        this.isLoading = false
      },
      complete: () => {
        this.isLoading = false
      }
    });
  }

}
