import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, interval, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService as ApiTransaction } from 'src/app/services/transaction/api.service';
import { TransactionModel } from 'src/app/models/transaction-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.css']
})
export class MyOrderComponent implements OnInit, OnDestroy {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  isLoading: boolean = true;
  data!: TransactionModel.Result[];
  dataOnGoing!: TransactionModel.Result[];
  dataCompleted!: TransactionModel.Result[];
  dataCancelled!: TransactionModel.Result[];
  updateSubscription: Subscription = new Subscription;
  subscription: Subscription | undefined;
  intervalId: any;

  constructor(private appService: AppService, private translate: TranslateService, private apiTransaction: ApiTransaction) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.appService.setIsPrintMobile(false)
    this.appService.setIsFooterMobileBottom('')

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en')
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!)
    }

    this.getTransaction('')
    this.getTransactionOnGoing('on_going')
    this.getTransactionCompleted('completed')
    this.getTransactionCancelled('canceled')
  }

  ngOnInit(): void {
    this.updateSubscription = interval(10000).subscribe(
      () => {
        this.getTransaction('')
        this.getTransactionOnGoing('on_going')
        this.getTransactionCompleted('completed')
        this.getTransactionCancelled('canceled')
      }
    )
  }

  async getTransaction(status: string) {
    (await this.apiTransaction.getTransaction(status))
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.data = v.results
      },
      error: (e) => {
        this.isLoading = false
        if(e.error == 'Unauthorized.')
          localStorage.clear()
          window.location.assign(environment.urlIndex)
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }

  async getTransactionOnGoing(status: string) {
    (await this.apiTransaction.getTransaction(status))
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.dataOnGoing = v.results
      },
      error: (e) => {
        this.isLoading = false
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }

  async getTransactionCompleted(status: string) {
    (await this.apiTransaction.getTransaction(status))
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.dataCompleted = v.results
      },
      error: (e) => {
        this.isLoading = false
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }

  async getTransactionCancelled(status: string) {
    (await this.apiTransaction.getTransaction(status))
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.dataCancelled = v.results
      },
      error: (e) => {
        this.isLoading = false
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

}
