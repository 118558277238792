import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-print-button-mobile',
  templateUrl: './print-button-mobile.component.html',
  styleUrls: ['./print-button-mobile.component.css']
})
export class PrintButtonMobileComponent {
  @Input() isPrintMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  destroyed = new Subject<void>();

  constructor(private cd: ChangeDetectorRef, private appService: AppService) {
    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })
  }

  ngOnInit(): void {
    this.appService.getIsPrintMobile().subscribe(appPrintMobile => this.isPrintMobile = appPrintMobile);
    this.cd.detectChanges();
  }
}
