<div [ngClass]="{'container': appServiceScreenMobile || appServiceScreenMobileSmall}" style="background-repeat: no-repeat;background-image: url(../../../../../assets/img/bg-home-1.webp);margin-bottom: 10px;text-align: center;">
    <h2 *ngIf="appServiceScreenDesktop" [ngStyle]="{'margin-top': appServiceScreenDesktop ? '80px' : '50px', 'margin-bottom': appServiceScreenDesktop ? '60px' : '30px'}" style="color: #222222;font-weight: 700;">{{'tema_testi' | translate}}</h2>
    <h3 *ngIf="appServiceScreenMobile || appServiceScreenMobileSmall" [ngStyle]="{'margin-top': appServiceScreenDesktop ? '80px' : '50px', 'margin-bottom': appServiceScreenDesktop ? '60px' : '30px'}" style="color: #222222;font-weight: 700;">{{'tema_testi' | translate}}</h3>
    <div *ngIf="isLoading == false; else hideLoading" class="list-with-scroll">
        <div class="list" tabindex="0">
            <div *ngFor="let i of data;let iArr = index;">
                <div [ngStyle]="{'margin-left': iArr != 0 ? '' : appServiceScreenDesktop ? '70px' : '20px', 'height': appServiceScreenDesktop ? '309px' : '250px'}" style="padding: 20px;border-radius: 16px;box-shadow: 0 0 10px #0000001A;width: 288px;margin: 20px;background-color: white;">
                    <div style="display: flex;">
                        <!-- <img alt="chatfoto" ngSrc="../../../../../assets/img/en.webp" width="48px" height="48px" style="border-radius: 50px;"> -->
                        <div style="text-align: start;">
                            <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '16px' : '14px'}" style="font-weight: 700;color: #222222;">{{i.full_name}}</label>
                            <p style="font-size: 12px;font-weight: 400;color: #717171;">{{i.province_name}}</p>
                        </div>
                    </div>
                    <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '16px' : '14px'}" style="font-weight: 400;text-align: start;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;">{{i.content}}</p>
                </div>
            </div>
        </div>
    </div>
    <ng-template #hideLoading>
        <div style="display: flex;justify-content: center;width: 100%;">
            <app-loading></app-loading>
        </div>
    </ng-template>
</div>