import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin) { }

  public async getProfile() {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken())
      .set('Content-Type', 'application/json');
    return this.httpClient.get<any>(`${this.baseUrl}profile`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async putProfile(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}profile-update`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async putProfilePassword(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}profile-change-password`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async resendEmail(formData: FormData) {
    const httpHeaders = new HttpHeaders();
    return this.httpClient.post<any>(`${this.baseUrl}resend-email`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async putForgetPassword(formData: FormData) {
    const httpHeaders = new HttpHeaders();
    return this.httpClient.post<any>(`${this.baseUrl}reset-password`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
