import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService as ApiContact } from 'src/app/services/contact/api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  panelOpenState4 = false;
  panelOpenState5 = false;
  isButton: boolean = true;
  isLoading: boolean = true;
  form!: FormGroup;
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;

  constructor(private appService: AppService, private translate: TranslateService, public fb: FormBuilder, private apiContact: ApiContact, private snackBar: MatSnackBar, private router: Router) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.appService.setIsPrintMobile(false)
    this.appService.setIsFooterMobileBottom('')

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en');
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
    }

    this.form = this.fb.group({
      topic: ['', Validators.required],
      fullname: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required],
    })
  }

  send() {
    this.isLoading = false;

    this.apiContact.sendContact(this.form.value.topic, this.form.value.fullname, this.form.value.email, this.form.value.message)
    .subscribe({
      next: (response) => {
        this.isLoading = true;
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })
        this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoading = true;
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  

  ngAfterContentChecked(): void {
    const hp = this.form.value.email == null ? '' : this.form.value.email == '' ? '' : this.form.value.email;
    const tp = this.form.value.topic == null ? '' : this.form.value.topic == '' ? '' : this.form.value.topic;
    const fn = this.form.value.fullname == null ? '' : this.form.value.fullname == '' ? '' : this.form.value.fullname;
    const ms = this.form.value.message == null ? '' : this.form.value.message == '' ? '' : this.form.value.message;

    if(hp == '' || tp == '' || fn == '' || ms == '') {
      this.isButton = true;
    } else {
      this.isButton = false;
    }
  }
}
