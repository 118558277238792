import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin) { }

  public async getCart() {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.get<any>(`${this.baseUrl}cart`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async addQtyInCart(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}cart/add-qty`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async deleteCartPhotoAll(id: number) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    let params = new HttpParams();
      params = params.append("cart", true);
    return this.httpClient.delete<any>(`${this.baseUrl}photo/${id}`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }

  public async removeVoucher() {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.get<any>(`${this.baseUrl}voucher-unselect`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async confirmOrder() {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.get<any>(`${this.baseUrl}confirm-order`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async cartQty() {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.get<any>(`${this.baseUrl}cart/show-qty`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
