import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient) { }

  public async getTestimonial() {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.httpClient.get<any>(`${this.baseUrl}testimony`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
