<div class="container" [ngStyle]="{'width': appServiceScreenDesktop ? '80%' : '100%'}">
    <div>
        <p [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '80px' : '110px', 'margin-bottom': appServiceScreenMobileSmall ? '20px' : '30px', 'font-size': appServiceScreenMobileSmall ? '18px' : '24px'}" style="color: #222222;font-weight: 700;">{{'My_Orders' | translate}}</p>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="{{'All_Order' | translate}}">
                <app-loading *ngIf="isLoading; else hideLoading"></app-loading>
                <ng-template #hideLoading>
                    <app-list [data]="data"></app-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="{{'On_going' | translate}}">
                <app-list [data]="dataOnGoing"></app-list>
            </mat-tab>
            <mat-tab label="{{'Completed' | translate}}">
                <app-list [data]="dataCompleted"></app-list>
            </mat-tab>
            <mat-tab label="{{'Canceled' | translate}}">
                <app-list [data]="dataCancelled"></app-list>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>