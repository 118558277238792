<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'Registration' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<form [formGroup]="form" (ngSubmit)="send()">
    <div mat-dialog-content style="text-align: center;">
        <div [ngClass]="{'row': appServiceScreenDesktop}" style="text-align: left;">
            <div class="col-12" style="margin-bottom: 20px;">
                <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Full_name' | translate}}</label>
                <input formControlName="full_name" type="text" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="Example: your name" required>
            </div>
            <div class="col-12" style="margin-bottom: 20px;">
                <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Email' | translate}}</label>
                <input formControlName="email" type="email" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="Example: your_mail@domain.com" required>
            </div>
            <div class="col-12" style="margin-bottom: 20px;">
                <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Phone_number' | translate}}</label>
                <input formControlName="phone_number" type="number" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="Example: 0811xxxxxx" required>
            </div>
            <div class="col-12">
                <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Password' | translate}}</label>
                <div class="input-group">
                    <input formControlName="password" type="{{showPassword}}" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-right: 1px;border-bottom-left-radius: 8px;border-top-left-radius: 8px;" class="form-control" placeholder="Example: P4ssw0rd" required>
                    <div (click)="showHidePassword()" class="input-group-btn" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-bottom-right-radius: 8px;border-top-right-radius: 8px;border: 1px solid #ced4da;display: flex;justify-content: center;align-items: center;">
                        <span class="btn btn-default">
                            <i [ngClass]="{'bi bi-eye-slash': showPassword == 'password', 'bi bi-eye': showPassword == 'text'}"></i>
                        </span>
                    </div>
                </div>
                <div [ngStyle]="{'margin-bottom': appServiceScreenMobile ? '20px' : '0px'}" class="text-red-400 text-sm">
                    <span style="padding-top: 5px;font-size: small;color: #747474;">Password must contain more than <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('.{8,}')}">8 characters</label>, <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('^(?=.*[A-Z])')}">1 upper case letter</label>, <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('(?=.*[a-z])')}">1 lower case letter</label>, <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('(.*[0-9].*)')}">1 digit number</label> and <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('(?=.*[!@#$%^&*])')}">1 special character</label></span>
                </div>
            </div>
            <div class="col-12" [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '8px' : '10px'}">
                <section>
                    <mat-checkbox checked="true">{{'Aggree_to' | translate}} <u (click)="callDetail()" style="cursor: pointer;color: #0e8667;">{{'tnc' | translate}}</u></mat-checkbox>
                </section>
            </div>
        </div>
    </div>
    <mat-dialog-actions style="justify-content: center;">
        <button *ngIf="isLoading else hideLoading" [disabled]="isButton" [ngStyle]="{'margin': appServiceScreenMobile ? '0 0 10px 0' : '10px 20px 5px 20px', 'background-color': isButton ? '#CCCCCC' : '#0e8667', 'margin-top': appServiceScreenMobile ? '10px' : '0px', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="width: 100%;border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;" mat-button>{{'Submit' | translate}}</button>
        <ng-template #hideLoading>
            <app-loading></app-loading>
        </ng-template>
    </mat-dialog-actions>
</form>
<p [ngStyle]="{'padding': appServiceScreenMobile ? '0 5px' : '0 25px', 'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="display: flex;justify-content: space-between;">
    {{'Already_an_account' | translate}} <label style="font-weight: 300;cursor: pointer;color: #0e8667;" (click)="callLogin()">{{'Log_in' | translate}}</label>
</p>
