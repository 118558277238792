<div [ngStyle]="{'height': appServiceScreenDesktop ? '400px' : appServiceScreenMobileSmall ? '120px' : '220px'}" style="background-repeat: no-repeat;background-image: url(../../../../../assets/img/bg-about.webp);display: flex;align-items: center;justify-content: center;flex-direction: column;">
    <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '35pt' : appServiceScreenMobileSmall ? '15pt' : '18pt'}" style="font-weight: 700;color: #222222;">{{'Get_Help_Whenever' | translate}}</label>
    <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '35pt' : appServiceScreenMobileSmall ? '15pt' : '18pt'}" style="font-weight: 700;color: #222222;">{{'You_Need_It' | translate}}</label>
</div>
<div class="container" [ngStyle]="{'width': appServiceScreenDesktop ? '80%' : '100%'}">
    <div>
        <p [ngStyle]="{'margin-top': appServiceScreenDesktop ? '110px' : appServiceScreenMobileSmall ? '20px' : '30px', 'font-size': appServiceScreenDesktop ? '24px' : appServiceScreenMobileSmall ? '16px' : '20px'}" style="margin-bottom: 30px;color: #222222;font-weight: 700;">{{'Frequently_asked_questions' | translate}}</p>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="{{'Upload_Photo' | translate}}">
                <div style="margin-top: 20px;">
                    <app-faq1></app-faq1>
                </div>
            </mat-tab>
            <mat-tab label="{{'Photo_Print' | translate}}">
                <div style="margin-top: 20px;">
                    <app-faq2></app-faq2>
                </div>
            </mat-tab>
            <mat-tab label="{{'Service_Level_Agreement' | translate}}">
                <div style="margin-top: 20px;">
                    <app-faq3></app-faq3>
                </div>
            </mat-tab>
            <mat-tab label="{{'Complain' | translate}}">
                <div style="margin-top: 20px;">
                    <app-faq4></app-faq4>
                </div>
            </mat-tab>
            <mat-tab label="{{'Payment' | translate}}">
                <div style="margin-top: 20px;">
                    <app-faq5></app-faq5>
                </div>
            </mat-tab>
            <mat-tab label="{{'Delivery' | translate}}">
                <div style="margin-top: 20px;">
                    <app-faq6></app-faq6>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div>
        <p [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '40px' : '80px', 'font-size': appServiceScreenMobileSmall ? '18px' : '24px'}" style="margin-bottom: 30px;color: #222222;font-weight: 700;">{{'Still_Have_Questions' | translate}}</p>
        <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #717171;font-weight: 400;">{{'Feel_free_to_reach_us_by_filling_up_the_form_or_contact_us_at' | translate}} <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #0e8667;font-weight: 700;"><u>support&#64;chatfoto.id</u></label></p>
        <form [formGroup]="form" (ngSubmit)="send()">
            <div class="row">
                <div class="col-12" style="margin-bottom: 20px;">
                    <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;width: 100%;">{{'Topic' | translate}}
                        <input formControlName="topic" type="text" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control">
                    </label>
                </div>
                <div class="col-sm" style="margin-bottom: 20px;">
                    <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;width: 100%;">{{'Full_name' | translate}}
                        <input formControlName="fullname" type="text" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control">
                    </label>
                </div>
                <div class="col-sm" style="margin-bottom: 20px;">
                    <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;width: 100%;">{{'Email_address' | translate}}
                        <input formControlName="email" type="email" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control">
                    </label>
                </div>
                <div class="col-12" style="margin-bottom: 20px;">
                    <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;width: 100%;">{{'Message' | translate}}
                        <textarea formControlName="message" type="text" style="border-radius: 8px;" class="form-control" rows="4" cols="50"></textarea>
                    </label>
                </div>
                <div class="col-12" style="text-align: center;">
                    <button *ngIf="isLoading else hideLoading" [disabled]="isButton" [ngStyle]="{'width': appServiceScreenDesktop ? '230px' : '100%', 'height': appServiceScreenMobileSmall ? '36px' : '56px'}" style="border-radius: 8px;background-color: #0e8667;font-size: 16px;font-weight: 700;color: white;padding: 5px 25px;" mat-button>{{'Submit' | translate}}</button>
                    <ng-template #hideLoading>
                        <app-loading></app-loading>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>
</div>