import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';
import { AddressModel } from 'src/app/models/address-model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin) { }

  public showAddress() {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.get<any>(`${this.baseUrl}address`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async createAddress(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}address`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async updateAddress(id: number, addressModel: AddressModel.Result) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    let params = new HttpParams()
      params = params.append("address", addressModel.address);
      params = params.append("province", addressModel.province);
      params = params.append("city", addressModel.city);
      params = params.append("district", addressModel.district);
      params = params.append("sub_district", addressModel.sub_district);
      params = params.append("postal_code", addressModel.postal_code);
      params = params.append("destination_code", addressModel.destination_code);
      params = params.append("is_default", addressModel.default ? 1 : 0);
      params = params.append("label", addressModel.label);
    return this.httpClient.put(`${this.baseUrl}address/${id}`, params, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async deleteAddress(id: number) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.delete<any>(`${this.baseUrl}address/${id}`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async setDefaultAddress(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}address-default`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async setAddress(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}address-select`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
