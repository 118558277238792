<header id="header">
  <div class="header fixed-top d-flex align-items-center" [ngStyle]="{'height': appServiceScreenMobileSmall ? '62px' : '72px'}" style="justify-content: space-between;" *ngIf="appServiceScreenMobile">
    <div class="d-flex"> 
      <i (click)="sidebarToggle()" class="bi bi-list toggle-sidebar-btn" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '22px' : '32px'}"></i>
    </div>
    <a aria-label="logo chatfoto" href="/" [ngStyle]="{'height': appServiceScreenMobileSmall ? '100%' : '95px'}" style="width: 150px;cursor: pointer;background: url(../../../../assets/img/logo.webp) center / contain no-repeat;" class="logo text-hide"></a>
    <nav class="header-nav" style="padding-right: 5%;">
      <app-badget-qty [isQty]="isQty"></app-badget-qty>
    </nav>
  </div>
  <div class="header fixed-top d-flex align-items-center" style="justify-content: space-between;" *ngIf="appServiceScreenDesktop">
    <a aria-label="logo chatfoto" href="/" style="cursor: pointer;" class="logo navbar-brand text-hide"></a>
    <div style="display: flex;padding-left: 18%;">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" style="font-size: 16px;font-weight: 700;color: #747474;" routerLink="/" aria-label="home"> {{'Home' | translate}} </a>
        <a class="nav-link" [routerLinkActive]="['active']" style="font-size: 16px;font-weight: 700;color: #747474;" routerLink="print" aria-label="print"> {{'Print' | translate}} </a>
        <a class="nav-link" [routerLinkActive]="['active']" style="font-size: 16px;font-weight: 700;color: #747474;" routerLink="my-order" aria-label="my-order"> {{'My_Order' | translate}} </a>
        <a class="nav-link" [routerLinkActive]="['active']" style="font-size: 16px;font-weight: 700;color: #747474;" routerLink="about-us" aria-label="about-us"> {{'About_us' | translate}} </a>
        <a class="nav-link" [routerLinkActive]="['active']" style="font-size: 16px;font-weight: 700;color: #747474;" routerLink="contact" aria-label="contact"> {{'Contact' | translate}} </a>
    </div>
    <nav class="header-nav" style="padding-right: 5%;">
      <div style="display: flex;" class="align-items-center">
        <app-badget-qty [isQty]="isQty"></app-badget-qty>
        <!-- <i class="bi bi-cart3" [routerLinkActive]="['active']" routerLink="cart" style="color: #717171;margin-right: 20px;font-size: x-large;cursor: pointer;"></i> -->
        <button *ngIf="isLogin == false; else show" (click)="callLogin()" style="width: 100%;height: 42px;border-radius: 8px;margin-right: 20px;background-color: #0e8667;font-size: 16px;font-weight: 700;color: white;padding: 5px 25px;" mat-button>{{'Log_in' | translate}}</button>

        <ng-template #show>
          <button data-bs-toggle="dropdown" type="button" style="margin-right: 20px;height: 42px;display: flex;align-items: center;border: 1px solid #DDDDDD;width: 100%;border-radius: 8px;background-color: transparent;font-size: 14px;font-weight: 700;color: black;padding: 5px 10px;" class="btn">
            <i class="bi bi-person-circle" style="background: -webkit-linear-gradient(304deg, #A5C296, #1DAB87, #0092CC);-webkit-background-clip: text;-webkit-text-fill-color: transparent;"></i> <label style="margin-left: 10px;margin-right: 10px;cursor: pointer;inline-size: max-content;">{{fullName}}</label> <i class="bi bi-caret-down-fill"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications" 
            style="border-radius: 15px;background-color: white;min-height: auto;max-height: 500px;overflow-x: hidden;min-width: 300px;padding-bottom: 0px;">
            <li routerLink="account" class="notification-item" style="padding-top: 5px;padding-bottom: 5px;cursor: pointer;">
              <i class="bi bi-person-bounding-box"></i> <label style="margin-left: 10px;padding: 10px;cursor: pointer;">{{'profile' | translate}}</label>
            </li>
            <li><hr style="margin: 0px;"></li>
            <li (click)="callLogout()" class="notification-item" style="padding-top: 5px;padding-bottom: 5px;cursor: pointer;color: #b8505b;">
              <i class="bi bi-box-arrow-right"></i> <label style="margin-left: 10px;padding: 10px;cursor: pointer;">{{'Log_out' | translate}}</label>
            </li>
          </ul>
        </ng-template>
        
        <button data-bs-toggle="dropdown" type="button" style="height: 42px;display: flex;align-items: center;border: 1px solid #DDDDDD;width: 100%;border-radius: 8px;background-color: transparent;font-size: 14px;font-weight: 700;color: black;padding: 5px 10px;" class="btn">
          <img alt="chatfoto" ngSrc="../../../../../assets/img/{{flag}}.webp" width="20px" height="16px"> <label style="margin-left: 10px;margin-right: 10px;cursor: pointer;">{{textFlag}}</label> <i class="bi bi-caret-down-fill"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications" 
          style="border-radius: 15px;background-color: white;min-height: auto;max-height: 500px;overflow-x: hidden;min-width: 300px;padding-bottom: 0px;">
          <li (click)="switchLanguage(flagNext)" class="notification-item" style="padding-top: 5px;padding-bottom: 5px;cursor: pointer;">
            <img alt="chatfoto" ngSrc="../../../../../assets/img/{{flagNext}}.webp" width="20px" height="16px"> <label style="margin-left: 10px;padding: 10px;cursor: pointer;">{{textFlagNext}}</label>
          </li>
          <!-- <li><hr style="margin: 0px;"></li>
          <li (click)="switchLanguage('en')" class="notification-item" style="padding-top: 5px;padding-bottom: 5px;cursor: pointer;">
            <img alt="chatfoto" ngSrc="../../../../../assets/img/en.webp" width="20px" height="16px"> <label style="margin-left: 10px;padding: 10px;cursor: pointer;">ENGLISH</label>
          </li> -->
        </ul>
      </div>
    </nav>
  </div>
  <app-print-button-mobile *ngIf="appServiceScreenMobile" [isPrintMobile]="isPrintMobile"></app-print-button-mobile>
</header>
