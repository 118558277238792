import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { DeliveryModel } from 'src/app/models/delivery-model';
import { ApiService as ApiDelivery } from 'src/app/services/delivery/api.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  isLoading: boolean = true;
  dataModel!: DeliveryModel.Result[];
  addressId!: number;
  setDelivery!: DeliveryModel.Result;
  setDeliveryTarif!: DeliveryModel.Tarif;

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<DeliveryComponent>,
    private bottomSheetRef: MatBottomSheetRef<DeliveryComponent>,
    private apiDelivery: ApiDelivery,
    private snackBar: MatSnackBar,
    public appService: AppService
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA);
    
      this.addressId = this.data == undefined ? '' : this.data['addressId']
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA);
    
      this.addressId = this.dataMobile == undefined ? '' : this.dataMobile['addressId']
    }

    this.showData(this.addressId)
  }

  showData(addressId: number) {
    this.isLoading = false;
    
    this.apiDelivery.showDelivery(addressId)
    .subscribe({
      next: (response: any) => {
        this.isLoading = true
        this.dataModel = response.results
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async setRecipientTemp() {
    this.isLoading = false;
    
    (this.apiDelivery.setDelivery(this.setDelivery.id, this.setDeliveryTarif))
    .subscribe({
      next: (response) => {
        this.isLoading = true

        if(this.appServiceScreenMobile) {
          this.bottomSheetRef.dismiss()
        } else {
          this.dialogRef.close()
        }

        // this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoading = true
      },
    })
  }

  selectedDelivery(data: DeliveryModel.Result, tarif: DeliveryModel.Tarif) {
    this.setDelivery = data
    this.setDeliveryTarif = tarif
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }

  
}
