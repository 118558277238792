import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Md5 } from 'md5-typescript';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrlsicepat: string = environment.apiURLsicepat;
  currentDate = new Date();
  tempDate: string | null;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe) { 
    this.tempDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd hh:mm:ss');
  }

  // SICEPAT
  public async loadProvince(){
    const httpHeaders = new HttpHeaders();
    const data = JSON.stringify({
      rt: this.tempDate,
      sa: Md5.init('DMM'+this.tempDate+'SICEPAT@SRCPB->2021'),
      cmd: 'list_province'
    });
    return this.httpClient.post<any>(`${this.baseUrlsicepat}apipickuppartner_json.php`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async loadCity(province: String){
    const httpHeaders = new HttpHeaders();
    const data = JSON.stringify({
      rt: this.tempDate,
      sa: Md5.init('DMM'+this.tempDate+'SICEPAT@SRCPB->2021'),
      cmd: 'list_city',
      shipperprovince: province
    });
    return this.httpClient.post<any>(`${this.baseUrlsicepat}apipickuppartner_json.php`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async loadDistrict(province: String, city: String){
    const httpHeaders = new HttpHeaders();
    const data = JSON.stringify({
      rt: this.tempDate,
      sa: Md5.init('DMM'+this.tempDate+'SICEPAT@SRCPB->2021'),
      cmd: 'list_district',
      shipperprovince: province,
      shippercity: city
    });
    return this.httpClient.post<any>(`${this.baseUrlsicepat}apipickuppartner_json.php`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async loadSubDistrict(province: String, city: String, district: String){
    const httpHeaders = new HttpHeaders();
    const data = JSON.stringify({
      rt: this.tempDate,
      sa: Md5.init('DMM'+this.tempDate+'SICEPAT@SRCPB->2021'),
      cmd: 'list_subdistrict',
      shipperprovince: province,
      shippercity: city,
      shipperdistrict: district
    });
    return this.httpClient.post<any>(`${this.baseUrlsicepat}apipickuppartner_json.php`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async loadDestination(recipientdistrict: String, shippercity: String, recipientcity: String){
    const httpHeaders = new HttpHeaders();
    const data = JSON.stringify({
      rt: this.tempDate,
      sa: Md5.init('DMM'+this.tempDate+'SICEPAT@SRCPB->2021'),
      cmd: 'destination',
      shippercity: shippercity,
      recipientcity: recipientcity,
      recipientdistrict: recipientdistrict
    });
    return this.httpClient.post<any>(`${this.baseUrlsicepat}apipickuppartner_json.php`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}