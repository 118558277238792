import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.css']
})
export class WhyComponent implements OnInit {
  @Input() isHomeWhy!: boolean;

  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;

  constructor(private appService: AppService, private cd: ChangeDetectorRef, private router: Router) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(router.url == '/')
      this.appService.setIsPrintMobile(true)
  }

  ngOnInit(): void {
    this.appService.getIsHomeWhy().subscribe(appHomeWhy => this.isHomeWhy = appHomeWhy);
    this.cd.detectChanges();
  }

}
