import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { LoginComponent } from '../login/login.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  form!: FormGroup;
  isButton: boolean = true;
  isLoading: boolean = true;

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<ForgetPasswordComponent>,
    private bottomSheetRef: MatBottomSheetRef<ForgetPasswordComponent>,
    appService: AppService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    public fb: FormBuilder,
    private apiLogin: ApiLogin,
    private snackBar: MatSnackBar,
  ) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.form = this.fb.group({
      email: ['', Validators.required],
    })
  }

  callLogin() {
    if(this.appServiceScreenMobile) {
      this.bottomSheetRef.dismiss()
      this.bottomSheet.open(LoginComponent, { disableClose: false });
    } else {
      this.dialogRef.close()
      this.dialog.open(LoginComponent, { disableClose: false })
    }
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }

  send() {
    this.isLoading = false;

    var formData: any = new FormData()
    formData.append('email', this.form.get('email')?.value)
    
    this.apiLogin.forgotPassword(formData)
    .subscribe({
      next: (response) => {
        this.isLoading = true;
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })
        
        this.callLogin()
      },
      error: (error) => {
        this.isLoading = true;
        console.log(error.error.message)
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  ngAfterContentChecked(): void {
    const hp = this.form.value.email == null ? '' : this.form.value.email == '' ? '' : this.form.value.email;

    if(hp == '') {
      this.isButton = true;
    } else {
      this.isButton = false;
    }
  }
}
