<div *ngIf="isUpload; else hideProgress">
  <!-- <app-progress-bar [progress]="progress"></app-progress-bar> -->
  <div class="container" [ngStyle]="{'display': appServiceScreenDesktop ? 'flex' : 'block'}" style="margin-top: 110px;justify-content: center;">
    <div [ngStyle]="{'width': appServiceScreenMobile ? '' : '723px', 'height': appServiceScreenDesktop ? '476px' : '', 'border': appServiceScreenMobile ? 'none' : '1px solid #DDDDDD', 'padding': appServiceScreenDesktop ? '20px' : ''}" style="border-radius: 12px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <p [ngStyle]="{'font-size': appServiceScreenMobile ? '12px' : '14px'}" style="width: 100%;font-weight: 400;color: #717171;text-align: center;">Uploading, please wait...</p>
        <!-- <mat-progress-bar [value]="progress" mode="buffer" bufferValue="0"></mat-progress-bar> -->
        <div class="progress my-3" style="width: 100%;">
          <div
            class="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            attr.aria-valuenow="{{ progress }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: progress + '%' }">
            {{ progress }}%
          </div>
      </div>
    </div>
  </div>
</div>

<ng-template #hideProgress>
  <div [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '70px' : '90px'}">
    <div class="container" style="text-align: center;">
      <div [ngClass]="{'row': appServiceScreenDesktop}" style="align-items: center;">
        <div *ngIf="appServiceScreenDesktop" class="col-4" style="text-align: -webkit-right;">
          <button (click)="previous()" style="display: flex;align-items: center;" mat-icon-button aria-label="back"><i class="bi bi-arrow-left-circle"></i></button>
        </div>
        <div [ngClass]="{'col-4': appServiceScreenDesktop}" [ngStyle]="{'height': appServiceScreenMobileSmall ? '300px' : '430px'}" style="padding: 0;background-color: white;border-radius: 12px;border: 1px solid #DDDDDD;">
          <body>
            <header style="display: flex;justify-content: space-between;align-items: center;padding: 10px;">
              <label>Photo {{currentIndex + 1}}</label>
              <button (click)="callDeleted(data[currentIndex].id)" [ngStyle]="{'margin': appServiceScreenDesktop ? '0px 10px' : '0px'}" type="button" style="height: 36px;border-radius: 8px;background-color: white;font-size: 14px;font-weight: 700;color: #b8505b;border: 2px solid #DDDDDD;" mat-button>
                <i [ngStyle]="{'margin-right': appServiceScreenDesktop ? '5px' : '0px'}" style="color: #b8505b;" class="bi bi-trash"></i> 
                <mat-label *ngIf="appServiceScreenDesktop">{{'Delete' | translate}}</mat-label>
              </button>
            </header>
            <main [ngStyle]="{'overflow-y': data[currentIndex].orientation == 'LANDSCAPE' ? '' : 'scroll', 'align-content': data[currentIndex].orientation == 'LANDSCAPE' ? 'center' : ''}">
              <div [ngStyle]="{'height': data[currentIndex].orientation == 'LANDSCAPE' ? '' : '100%', 'width': data[currentIndex].orientation == 'LANDSCAPE' ? '100%' : ''}">
                <img [ngStyle]="{'padding': isEdited == true ? '0.40rem' : '', 'border': isEdited == true ? '1px solid #dee2e6' : '', 'height': data[currentIndex].orientation == 'LANDSCAPE' ? '' : '100%', 'width': data[currentIndex].orientation == 'LANDSCAPE' ? '100%' : ''}" style="background-position: center;background-size: contain;background-repeat: no-repeat;" src="{{data[currentIndex].file_name}}">
              </div>
            </main>
            <footer style="display: flex;justify-content: space-between;align-items: center;padding: 10px;">
              <mat-slide-toggle [(ngModel)]="isWhiteBorder" (change)="selectWhiteBorder(data[currentIndex].id,$event)">White border</mat-slide-toggle>
              <button [disabled]="isEdited" (click)="goToEditor(data[currentIndex].id)" type="button" [ngStyle]="{'color': isEdited ? '#DDDDDD' : '#0e8667'}" style="height: 36px;border-radius: 8px;background-color: white;font-size: 14px;font-weight: 700;border: 2px solid #DDDDDD;margin: 0px 10px;" mat-button>
                <i [ngStyle]="{'margin-right': appServiceScreenDesktop ? '5px' : '0px', 'color': isEdited ? '#DDDDDD' : '#0e8667'}" class="bi bi-pencil-square"></i> 
                <mat-label *ngIf="appServiceScreenDesktop">{{'Edit' | translate}}</mat-label>
              </button>
            </footer>
          </body>
        </div>
        <div *ngIf="appServiceScreenDesktop" class="col-4" style="text-align: -webkit-left;">
          <button (click)="next()" style="display: flex;align-items: center;" mat-icon-button aria-label="back"><i class="bi bi-arrow-right-circle"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="header" [ngStyle]="{'padding-bottom': appServiceScreenDesktop ? '0px' : '10px'}" style="height: auto;position: fixed;bottom: 0;right: 0;left: 0;display: flex;align-items: center;flex-direction: column;">
    <div style="height: auto;background-color: #FAFAFA;width: 100%;display: flex;align-items: center;justify-content: center;padding: 10px 0px;">
      <label matRipple (click)="removeQty(data[currentIndex].id)" style="cursor: pointer;background-color: black;border-radius: 8px;padding: 0px 5px;"><i style="color: white;" class="bi bi-dash-lg"></i></label>
      <label style="padding: 0px 15px;">{{currentQty}}</label>
      <label matRipple (click)="addQty(data[currentIndex].id)" style="cursor: pointer;background-color: black;border-radius: 8px;padding: 0px 5px;"><i style="color: white;" class="bi bi-plus"></i></label>
    </div>
    <div class="header" [ngStyle]="{'height': appServiceScreenMobileSmall ? '65px' : '100px'}" style="box-shadow: 1px -1px 1px rgba(1, 41, 112, 0.1);background-color: white;display: flex;justify-content: center;width: 100%;align-items: center;">
        <div class="list-with-scroll">
            <div
              class="list"
              nextPreviousScrollbar
              #list="nextPreviousScrollbar"
              [scrollUnit]="scrollUnit">
              <div *ngFor="let i of data; let indexArray = index;" class="item" [ngStyle]="{'min-width': appServiceScreenMobileSmall ? '50px' : '90px', 'height': appServiceScreenMobileSmall ? '50px' : '90px', 'margin': appServiceScreenMobileSmall ? '5px' : '10px'}" style="background-image: url({{i.file_name_thumbnail}});background-size: contain;background-repeat: no-repeat;background-position: center center;">
                <div *ngIf="i.is_click == 0; else one" (click)="imageSelectNormal(indexArray)" style="width: 100%;height: 100%;text-align: end;padding: 2px 5px;display: flex;flex-direction: column;">
                  <i style="cursor: pointer;color: white;" [ngStyle]="{'display': appServiceScreenMobileSmall ? 'none' : ''}" (click)="callDeleted(i.id)" class="bi bi-x-circle-fill"></i>
                </div>
                <ng-template #one>
                  <div style="width: 100%;height: 100%;text-align: end;padding: 2px 5px;display: flex;flex-direction: column;">
                    <i style="cursor: pointer;color: white;" [ngStyle]="{'display': appServiceScreenMobileSmall ? 'none' : ''}" (click)="callDeleted(i.id)" class="bi bi-x-circle-fill"></i>
                    <span (click)="imageSelect(indexArray, i.id)" *ngIf="i.status == 'uploaded'" style="display: flex;align-self: center;font-size: smaller;color: white;padding: 10px;height: 40px;width: 40px;background-color: #1eaa87;border-radius: 50%;align-items: center;justify-content: center;cursor: pointer;">{{'new' | translate}}</span>
                  </div>
                </ng-template>
              </div>
            </div>
          
            <label matRipple class="scroll-button scroll-left"
            [ngStyle]="{'padding': appServiceScreenDesktop ? '10px 10px' : appServiceScreenMobileSmall ? '2px 6px' : '5px 8px'}"
            [class.disable]="!list.canScrollStart"
            (click)="list.scroll(-1)"><i [ngStyle]="{'font-size': appServiceScreenDesktop ? 'x-large' : 'medium'}" style="color: #9b9b9b;display: flex;align-items: center;" class="bi bi-arrow-left-circle"></i></label>
          
            <label matRipple class="scroll-button"
            [ngStyle]="{'padding': appServiceScreenDesktop ? '10px 10px' : appServiceScreenMobileSmall ? '2px 6px' : '5px 8px'}"
            [class.disable]="!list.canScrollEnd"
            (click)="list.scroll(1)"><i [ngStyle]="{'font-size': appServiceScreenDesktop ? 'x-large' : 'medium'}" style="color: #9b9b9b;display: flex;align-items: center;" class="bi bi-arrow-right-circle"></i></label>
        </div>
        <div [ngStyle]="{'margin': appServiceScreenDesktop ? '0 15px' : '0 10px', 'border-left': appServiceScreenDesktop ? '1px solid #DDDDDD' : '0px'}" style="height: 100%;"></div>
        <input style="display: none" multiple type="file" accept="image/png, image/jpg, image/jpeg, image/webp" (change)="selectFile($event)" #hiddenfileinput>
        <div (click)="hiddenfileinput.click()" matRipple [ngStyle]="{'height': appServiceScreenMobileSmall ? '50px' : '80px'}" style="border: 1px dashed #DDDDDD;border-radius: 4px;cursor: pointer;display: flex;flex-direction: column;justify-content: center;align-items: center;">
          <i [ngStyle]="{'font-size': appServiceScreenDesktop ? 'x-large' : 'medium'}" class="bi bi-plus-circle-fill" style="color: #0e8667;"></i>
          <label style="font-size: small;padding: 0 10px;color: #717171;">{{'Add_more' | translate}}</label>
        </div>
    </div>
    <div *ngIf="appServiceScreenMobile" style="height: auto;background-color: white;width: 100%;display: flex;align-items: center;">
      <button (click)="addToCart()" mat-button [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="border-radius: 8px;background: linear-gradient(90deg, #A5C296 0%, #1DAB87 21.87%, #1DAB87 47.92%, #0092CC 100%);font-weight: 700;color: white;inline-size: max-content;width: 100%;margin: 0 10px;"> Save & add to cart ({{totalQty}})</button>
    </div>
  </div>
</ng-template>