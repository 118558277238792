import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressModel } from 'src/app/models/address-model';
import { ApiService as ApiAddress } from 'src/app/services/address/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SicepatModel } from 'src/app/models/sicepat-model';
import { ApiService as ApiSicepat } from 'src/app/services/sicepat/api.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  isNew: boolean = false;
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  dataModel!: AddressModel.Result[];
  dataEditModel: AddressModel.Result | undefined;
  form!: FormGroup;
  isButton: boolean = true;
  isLoading: boolean = true;
  isLoadingSave: boolean = true;
  isDefault: any;
  title!: string;
  isIndeterminate = false;
  postal_code!: string;
  destination_code!: string;
  protected onDestroy = new Subject<void>();
  dataAddress!: number;
  hintProvince!: string | null;
  hintCity!: string | null;
  hintDistrict!: string | null;
  hintSubDistrict!: string | null;
  label!: string | null

  public provinceName: FormControl = new FormControl();
  public provinceFilterCtrl: FormControl = new FormControl();
  public filteredProvinces: ReplaySubject<SicepatModel.DataProvince[]> = new ReplaySubject<SicepatModel.DataProvince[]>(1);

  public city: FormControl = new FormControl();
  public cityFilterCtrl: FormControl = new FormControl();
  public filteredCitys: ReplaySubject<SicepatModel.DataCity[]> = new ReplaySubject<SicepatModel.DataCity[]>(1);

  public district: FormControl = new FormControl();
  public districtFilterCtrl: FormControl = new FormControl();
  public filteredDistricts: ReplaySubject<SicepatModel.District[]> = new ReplaySubject<SicepatModel.District[]>(1);

  public sub_district: FormControl = new FormControl();
  public subDistrictFilterCtrl: FormControl = new FormControl();
  public filteredSubDistricts: ReplaySubject<SicepatModel.Subdistrict[]> = new ReplaySubject<SicepatModel.Subdistrict[]>(1);

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<AddressComponent>,
    private bottomSheetRef: MatBottomSheetRef<AddressComponent>,
    private apiAddress: ApiAddress,
    private snackBar: MatSnackBar,
    public fb: FormBuilder,
    private apiSicepat: ApiSicepat,
    public appService: AppService
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.form = this.fb.group({
      address: ['', Validators.required],
      note: [''],
      provinceName: ['', Validators.required],
      city: ['', Validators.required],
      district: ['', Validators.required],
      subdistrict: ['', Validators.required],
      postal_code: ['', Validators.required],
      destination_code: ['', Validators.required],
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA)
    
      this.dataAddress = this.data == undefined ? '' : this.data['address']
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA)
    
      this.dataAddress = this.dataMobile == undefined ? '' : this.dataMobile['address']
    }

    this.showData()
  }

  ngOnInit(): void {
    this.getProvinces()
  }

  selectLabel(is: string) {
    this.label = is
  }

  showData() {
    this.isLoading = false;
    
    this.apiAddress.showAddress()
    .subscribe({
      next: (response) => {
        this.isLoading = true
        this.dataModel = response.results
        if(this.dataModel.length > 0)
          this.isNew = false
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async send(title: string) {
    this.isLoadingSave = false;
    
    let api
    if(title == 'Edit_new_address') {
      let editData: AddressModel.Result = {
        address: this.form.get('address')?.value,
        note: this.form.get('note')?.value,
        province: this.hintProvince == null ? this.provinceName.value : this.hintProvince,
        city: this.hintCity == null ? this.city.value : this.hintCity,
        district: this.hintDistrict == null ? this.district.value : this.hintDistrict,
        sub_district: this.hintSubDistrict == null ? this.sub_district.value.subdistrict : this.hintSubDistrict,
        postal_code: this.form.get('postal_code')?.value,
        destination_code: this.form.get('destination_code')?.value,
        id: this.dataEditModel?.id!,
        default: this.isDefault == 1 ? true : false,
        active: this.dataEditModel?.active!,
        label: this.dataEditModel?.label == null ? this.label! : this.label!
      }
      api = (await this.apiAddress.updateAddress(this.dataEditModel?.id!, editData))
    } else {
      var formData: any = new FormData();
      formData.append('address', this.form.get('address')?.value);
      formData.append('note', this.form.get('note')?.value);
      formData.append('province', this.provinceName.value);
      formData.append('city', this.city.value);
      formData.append('district', this.district.value);
      formData.append('sub_district', this.sub_district.value.subdistrict);
      formData.append('postal_code', this.postal_code);
      formData.append('destination_code', this.destination_code);
      formData.append('is_default', this.isDefault);
      formData.append('label', this.label);
      api = (await this.apiAddress.createAddress(formData))
    }
    
    api
    .subscribe({
      next: (response) => {
        this.isLoadingSave = true
        this.clearForm()
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })
        this.showData()
      },
      error: (error) => {
        this.isLoadingSave = true
        this.showData()
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  clearForm() {
    this.hintProvince = null
    this.hintCity = null
    this.hintDistrict = null
    this.hintSubDistrict = null
    this.form.get('address')?.setValue(null)
    this.form.get('note')?.setValue(null)
    this.form.get('postal_code')?.setValue(null)
    this.form.get('destination_code')?.setValue(null)
  }

  clearFormWitOutAddress() {
    this.hintProvince = null
    this.hintCity = null
    this.hintDistrict = null
    this.hintSubDistrict = null
    this.form.get('postal_code')?.setValue(null)
    this.form.get('destination_code')?.setValue(null)
  }

  async deleteData(id: number) {
    this.isLoading = false;
    
    (await this.apiAddress.deleteAddress(id))
    .subscribe({
      next: (response) => {
        this.isLoading = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })

        this.showData()
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async setDefaultData(id: number) {
    this.isLoading = false;

    var formData: any = new FormData();
    formData.append('id', id);
    
    (await this.apiAddress.setDefaultAddress(formData))
    .subscribe({
      next: (response) => {
        this.isLoading = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })

        this.showData()
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async setAddressTemp(id: number) {
    this.isLoading = false;
    
    var formData: any = new FormData();
    formData.append('address_id', id);
    
    (await (this.apiAddress.setAddress(formData)))
    .subscribe({
      next: (response) => {
        this.isLoading = true

        if(this.appServiceScreenMobile) {
          this.bottomSheetRef.dismiss()
        } else {
          this.dialogRef.close()
        }

        // this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoading = true
      },
    })
  }

  checkListDefault(event: any) {
    this.isDefault = event.checked ? true : false
  }

  ngAfterContentChecked(): void {
    const adr = this.form.value.address == null ? '' : this.form.value.address == '' ? '' : this.form.value.address;
    const prv = this.form.value.provinceName == null ? '' : this.form.value.provinceName == '' ? '' : this.form.value.provinceName;
    const cty = this.form.value.city == null ? '' : this.form.value.city == '' ? '' : this.form.value.city;
    const dis = this.form.value.district == null ? '' : this.form.value.district == '' ? '' : this.form.value.district;
    const sds = this.form.value.sub_district == null ? '' : this.form.value.sub_district == '' ? '' : this.form.value.sub_district;

    if(adr == '') {
      this.isButton = true;
    } else {
      this.isButton = false;
    }
  }

  addNew(title: string, addressModel?: AddressModel.Result) {
    this.isNew = true
    this.title = title
    if('Edit_new_address') {
      this.dataEditModel = addressModel
      this.isDefault = this.dataEditModel?.default
      this.hintProvince = this.dataEditModel!.province
      this.hintCity = this.dataEditModel!.city
      this.hintDistrict = this.dataEditModel!.district
      this.hintSubDistrict = this.dataEditModel!.sub_district
      this.form.get('address')?.setValue(this.dataEditModel?.address)
      this.form.get('note')?.setValue(this.dataEditModel?.note)
      this.form.get('postal_code')?.setValue(this.dataEditModel?.postal_code)
      this.form.get('destination_code')?.setValue(this.dataEditModel?.destination_code == null ? this.dataEditModel?.destination_code_jne : this.dataEditModel.destination_code_jne == null ? this.dataEditModel.destination_code_sicepat : this.dataEditModel.destination_code_sicepat == null ? this.dataEditModel.destination_code_tiki : this.dataEditModel.destination_code)
      this.label = this.dataEditModel?.label.toLowerCase()!
      // this.form.patchValue({address: this.dataEditModel?.address, provinceName: this.dataEditModel?.province, city: this.dataEditModel?.city, district: this.dataEditModel?.district, sub_district: this.dataEditModel?.sub_district});
    } else {
      this.clearForm()
    }
  }

  back_() {
    this.isNew = false
    this.clearForm()
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
      this.clearForm()
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  async getProvinces() {
    (await this.apiSicepat.loadProvince())
    .subscribe({
      next: (response) => {
        this.filteredProvinces.next(response.data.province.slice())

        this.provinceFilterCtrl.valueChanges
          .pipe(takeUntil(this.onDestroy))
          .subscribe(() => {
            if (!response.data.province) {
              return;
            }

            let search = this.provinceFilterCtrl.value
            if (!search) {
              this.filteredProvinces.next(response.data.province.slice())
              return
            } else {
              search = search.toLowerCase()
            }

            this.filteredProvinces.next(
              response.data.province.filter((province: string) => province.toLowerCase().indexOf(search) > -1)
            )

            let filteredLength = 0
            if (this.provinceName && this.provinceName.value) {
              response.data.province.forEach((el: any) => {
                if (this.provinceName.value.indexOf(el) > -1) {
                  filteredLength++
                }
              })
              this.isIndeterminate = filteredLength > 0 && filteredLength < response.data.province.length
            }
          })

        this.provinceName.valueChanges
          .pipe(takeUntil(this.onDestroy)).subscribe(() => {
          let filteredLength = 0
          if('Edit_new_address') this.clearFormWitOutAddress()
          if (this.provinceName && this.provinceName.value) {
            response.data.province.forEach((el: any) => {
              if (this.provinceName.value.indexOf(el) > -1) {
                filteredLength++
              }
            })
            this.isIndeterminate = filteredLength > 0 && filteredLength < response.data.province.length
            this.getCity(this.provinceName.value)
            this.isButton = true
          }
        })
      }, 
      error: (error) => {
        
      }
    })
  }

  async getCity(_province: string) {
    (await this.apiSicepat.loadCity(_province))
    .subscribe({
      next: (response) => {
        this.filteredCitys.next(response.data.city.slice())

        this.cityFilterCtrl.valueChanges
          .pipe(takeUntil(this.onDestroy))
          .subscribe(() => {
            if (!response.data.city) {
              return;
            }

            let search = this.cityFilterCtrl.value
            if (!search) {
              this.filteredCitys.next(response.data.city.slice())
              return
            } else {
              search = search.toLowerCase()
            }

            this.filteredCitys.next(
              response.data.city.filter((city: string) => city.toLowerCase().indexOf(search) > -1)
            )

            let filteredLength = 0
            if (this.city && this.city.value) {
              response.data.city.forEach((el: any) => {
                if (this.city.value.indexOf(el) > -1) {
                  filteredLength++
                }
              })
              this.isIndeterminate = filteredLength > 0 && filteredLength < response.data.city.length
            }
          })

        this.city.valueChanges
          .pipe(takeUntil(this.onDestroy)).subscribe(() => {
          let filteredLength = 0
          if (this.city && this.city.value) {
            response.data.city.forEach((el: any) => {
              if (this.city.value.indexOf(el) > -1) {
                filteredLength++
              }
            })
            this.isIndeterminate = filteredLength > 0 && filteredLength < response.data.city.length
            this.getDistrict(this.provinceName.value, this.city.value);
            this.isButton = true
          }
        })
      }, 
      error: (error) => {
        
      }
    })
  }

  async getDistrict(_province: string, _city: string) {
    (await this.apiSicepat.loadDistrict(_province, _city))
    .subscribe({
      next: (response) => {
        this.filteredDistricts.next(response.data.district.slice())

        this.districtFilterCtrl.valueChanges
          .pipe(takeUntil(this.onDestroy))
          .subscribe(() => {
            if (!response.data.district) {
              return;
            }

            let search = this.districtFilterCtrl.value
            if (!search) {
              this.filteredDistricts.next(response.data.district.slice())
              return
            } else {
              search = search.toLowerCase()
            }

            this.filteredDistricts.next(
              response.data.district.filter((district: any) => district.district.toLowerCase().indexOf(search) > -1)
            )

            let filteredLength = 0
            if (this.district && this.district.value) {
              response.data.district.forEach((el: any) => {
                if (this.district.value.indexOf(el) > -1) {
                  filteredLength++
                }
              })
              this.isIndeterminate = filteredLength > 0 && filteredLength < response.data.district.length
            }
          })

        this.district.valueChanges
          .pipe(takeUntil(this.onDestroy)).subscribe(() => {
          let filteredLength = 0
          if (this.district && this.district.value) {
            response.data.district.forEach((el: any) => {
              if (this.district.value.indexOf(el) > -1) {
                filteredLength++
              }
            })
            this.isIndeterminate = filteredLength > 0 && filteredLength < response.data.district.length
            this.getSubDistrict(this.provinceName.value, this.city.value, this.district.value)
            this.isButton = true
          }
        })
      }, 
      error: (error) => {
        
      }
    })
  }

  async getSubDistrict(_province: string, _city: string, _district: string) {
    (await this.apiSicepat.loadSubDistrict(_province, _city, _district))
    .subscribe({
      next: (response) => {
        this.filteredSubDistricts.next(response.data.subdistrict.slice())

        this.subDistrictFilterCtrl.valueChanges
          .pipe(takeUntil(this.onDestroy))
          .subscribe(() => {
            if (!response.data.subdistrict) {
              return;
            }

            let search = this.subDistrictFilterCtrl.value
            if (!search) {
              this.filteredSubDistricts.next(response.data.subdistrict.slice())
              return
            } else {
              search = search.toLowerCase()
            }

            this.filteredSubDistricts.next(
              response.data.subdistrict.filter((subdistrict: any) => subdistrict.subdistrict.toLowerCase().indexOf(search) > -1)
            )

            let filteredLength = 0
            if (this.sub_district && this.sub_district.value) {
              response.data.subdistrict.forEach((el: any) => {
                if (this.sub_district.value.subdistrict.indexOf(el) > -1) {
                  filteredLength++
                }
              })
              this.isIndeterminate = filteredLength > 0 && filteredLength < response.data.subdistrict.length
            }
          })

        this.sub_district.valueChanges
          .pipe(takeUntil(this.onDestroy)).subscribe(() => {
          let filteredLength = 0
          if (this.sub_district && this.sub_district.value) {
            response.data.subdistrict.forEach((el: any) => {
              if (this.sub_district.value.subdistrict.indexOf(el) > -1) {
                filteredLength++
              }
            })
            this.isIndeterminate = filteredLength > 0 && filteredLength < response.data.subdistrict.length
            this.getDestination(this.sub_district.value.subdistrict, _city)
            this.postal_code = this.sub_district.value.zipcode
            this.form.get('postal_code')?.setValue(this.postal_code)
            this.isButton = true
          }
        })
      }, 
      error: (error) => {
        
      }
    })
  }

  async getDestination(_district: string, _city: string) {
    (await this.apiSicepat.loadDestination(_district, _city, _city))
    .subscribe({
      next: (response) => {
        if (response.status == '0') {
          this.destination_code = response.data.destination[0]["destination_code"]
          this.form.get('destination_code')?.setValue(this.destination_code)
          this.isButton = false
        } else {
          this.snackBar.open(response.message, '', {
            duration: 3 * 1000,
            panelClass: ['failed-snackbar']
          })
        }
      }, 
      error: (error) => {
        
      }
    })
  }

  
}
