<div *ngFor="let row of data;let indexArray = index;" style="background-color: white;margin-top: 20px;width: 100%;border: 1px solid #E8E8E8;border-radius: 12px;padding: 15px;">
    <div>
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px'}" style="color: #717171;font-weight: 700;">{{'Print_order' | translate}}</label>
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px', 'padding': appServiceScreenMobileSmall ? '0px 10px' : '0px 20px'}" style="color: #717171;font-weight: 400;">{{row.purchase_date}}</label>
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px', 'padding': appServiceScreenMobileSmall ? '0px 10px' : '0px 20px', 'color': row.delivery_status == 'Canceled' ? '#484848' : '#0e8667', 'background-color': row.delivery_status == 'Canceled' ? '#b9bbba' : '#EDFDF9'}" style="font-weight: 700;border-radius: 20px;">{{row.delivery_status}}</label>
    </div>
    <div class="row" [ngStyle]="{'padding': appServiceScreenDesktop ? '' : '0px 12px'}">
        <div *ngFor="let rowProduct of row.detail_product;let arrIndex = index" class="col-sm" [ngStyle]="{'margin': appServiceScreenMobileSmall ? '10px 0px 10px 0px' : '20px 12px 10px 12px'}" style="display: flex;align-items: center;background-color: #F9F9F9;border-radius: 8px;padding: 10px;justify-content: space-between;">
            <div style="display: flex;">
                <div [ngStyle]="{'margin-right': appServiceScreenMobileSmall ? '10px' : '20px'}">
                    <img alt="chatfoto" ngSrc="{{rowProduct.image_icon_url}}" style="border-radius: 4px;" width="48px" height="48px">
                </div>
                <div style="display: flex;flex-direction: column;justify-content: center;">
                    <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '16px'}" style="color: #222222;font-weight: 700;">{{rowProduct.name}}</label>
                    <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 400;">{{rowProduct.qty}} {{'items' | translate}}</label>
                </div>
            </div>
            <div style="margin-left: 1rem;">
                <button (click)="callDetailPhoto(row.detail_product[arrIndex])" type="button" style="height: 30px;font-size: small;border-radius: 8px;background-color: #0e8667;font-weight: 400;color: white;" mat-button>See All</button>
            </div>
        </div>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: center;background-color: #F9F9F9;border-radius: 8px;padding: 10px;">
        <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #222222;font-weight: 700;">{{'Total_bill' | translate}}</label>
        <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '20px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #222222;font-weight: 700;">{{(row.total| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</label>
    </div>
    <div [ngStyle]="{'justify-content': row.delivery_status == 'Pending Payment' ? 'flex-end' : 'space-between'}" style="display: flex;align-items: center;margin-top: 20px;">
        <button (click)="getInvoice(row.transaction_id)" *ngIf="row.delivery_status != 'Pending Payment'" [ngStyle]="{'font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px'}" type="button" style="border-radius: 8px;font-weight: 700;color: #0e8667;" mat-button>
            <i class="bi bi-download" style="color: #0e8667;margin-right: 5px;"></i>
            <mat-label *ngIf="appServiceScreenDesktop">{{'Download_invoice' | translate}}</mat-label>
            <mat-label *ngIf="appServiceScreenMobile">{{'Invoice' | translate}}</mat-label>
        </button>
        <div>
            <button *ngIf="row.delivery_status == 'Pending Payment'" (click)="getCancelOrder(row.transaction_id, 'cancel_order_warning')" [ngStyle]="{'width': appServiceScreenMobile || appServiceScreenMobileSmall ? '100%' : '','font-size': appServiceScreenDesktop ? '14px' : appServiceScreenMobileSmall ? '10px' : '12px','height': appServiceScreenDesktop ? '48px' : '','padding': appServiceScreenDesktop ? '5px 25px' : ''}" type="button" style="border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;" mat-button>{{'cancel_order' | translate}}</button>
            <button (click)="callDetailTransaction(indexArray)" [ngStyle]="{'width': row.delivery_status != 'Pending Payment' ? '' : appServiceScreenDesktop ? '' : '100%','font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px','height': appServiceScreenDesktop ? '48px' : '','padding': appServiceScreenDesktop ? '5px 25px' : '','margin': appServiceScreenDesktop ? '0px 10px' : row.delivery_status == 'Pending Payment' ? '5px 0px' : '0px 5px'}" type="button" style="border-radius: 8px;background-color: white;font-weight: 700;color: #0e8667;border: 2px solid #DDDDDD;" mat-button>{{'See_details' | translate}}</button>
            <app-loading *ngIf="isLoading == false; else hideLoading"></app-loading>
            <ng-template #hideLoading>
                <button (click)="getReOrder(row.transaction_id)" *ngIf="row.delivery_status != 'Pending Payment'" [ngStyle]="{'width': appServiceScreenDesktop ? '240px' : '','font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px','height': appServiceScreenDesktop ? '48px' : '','padding': appServiceScreenDesktop ? '5px 25px' : ''}" type="button" style="border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;" mat-button>{{'Re_order' | translate}}</button>
            </ng-template>
            <button *ngIf="row.delivery_status == 'Pending Payment'" (click)="continuePayment(row.payment_token_and_redirect_url.redirect_url)" [ngStyle]="{'width': appServiceScreenDesktop ? '240px' : appServiceScreenMobile || appServiceScreenMobileSmall ? '100%' : '','font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px','height': appServiceScreenDesktop ? '48px' : '','padding': appServiceScreenDesktop ? '5px 25px' : ''}" type="button" style="border-radius: 8px;background-color: red;font-weight: 700;color: white;" mat-button>{{'continue_payment' | translate}}</button>
        </div>
    </div>
</div>