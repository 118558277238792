import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { LoginComponent } from '../../pages/dialog/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  isPrintMobile!: boolean;
  flag: string;
  textFlag: string;
  flagNext: string;
  textFlagNext: string;
  isLogin: boolean;
  fullName!: string | null;
  @Input() isQty: number = 0;

  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;

  constructor(private appService: AppService, private dialog: MatDialog, private translate: TranslateService, private apiLogin: ApiLogin) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.appService.setIsPrintMobile(true)

    if(localStorage.getItem('my') == undefined && localStorage.getItem('key')) {
      this.isLogin = false
    } else {
      this.isLogin = apiLogin.isLoggedIn()
      let retrievedObject = localStorage.getItem('my')
      let results = JSON.parse(retrievedObject!)
      this.fullName = results?.result['full_name']
    }

    if(localStorage.getItem('lang') == undefined || localStorage.getItem('lang') == 'en') {
      this.flag = 'en'
      this.textFlag = 'ENG'
      this.flagNext = 'id'
      this.textFlagNext = 'INDONESIA'
    } else {
      this.flag = 'id'
      this.textFlag = 'IND'
      this.flagNext = 'en'
      this.textFlagNext = 'ENGLISH'
    }
  }

  callLogin() {
    this.dialog.open(LoginComponent, { disableClose: false })
  }

  callLogout() {
    localStorage.clear()
    window.location.assign(environment.urlIndex)
  }

  sidebarToggle() {
    this.appService.sideBar();
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language)
    if(language == 'id') {
      this.flag = 'id'
      this.textFlag = 'IND'
      this.flagNext = 'en'
      this.textFlagNext = 'ENGLISH'
    } else {
      this.flag = 'en'
      this.textFlag = 'ENG'
      this.flagNext = 'id'
      this.textFlagNext = 'INDONESIA'
    }
    location.reload()
  }

}
