<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'Detail_transaction' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<div mat-dialog-content>
    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="background-color: #F2FBFE;font-weight: 400;color: #0e8667;padding: 10px 15px;border-radius: 10px;">
        <i style="color: #0e8667;margin-right: 5px;" class="bi bi-truck"></i> {{showData.delivery_status}}
    </p>
    <div style="display: flex;justify-content: space-between;margin-bottom: 5px;margin-top: 10px;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Invoice' | translate}}</label>
        <label matRipple [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #222222;">{{showData.invoice}}</label>
    </div>
    <div style="display: flex;justify-content: space-between;margin-bottom: 20px;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Purchase_date' | translate}}</label>
        <label matRipple [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #222222;">{{showData.purchase_date}}</label>
    </div>
    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '16px' : '18px'}" style="font-weight: 700;color: #222222;">{{'Detail_product' | translate}}</p>
    <div *ngFor="let row of showData.detail_product" style="display: flex;justify-content: space-between;align-items: center;align-content: center;background-color: white;margin-bottom: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;padding: 10px;">
        <div style="display: flex;">
            <img alt="chatfoto" ngSrc="{{row.image_icon_url}}" style="border-radius: 4px;" width="48px" height="48px">
            <div [ngStyle]="{'padding': appServiceScreenMobile ? '0px 10px' : '0px 20px'}" style="display: flex;flex-direction: column;align-items: start;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 700;">
                    {{row.name}}
                </label>
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{row.qty}} {{'items' | translate}}</label>
            </div>
        </div>
        <div [ngStyle]="{'padding': appServiceScreenMobile ? '0px 10px' : '0px 20px'}" style="display: flex;flex-direction: column;align-items: start;">
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Total_price' | translate}}</label>
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #0e8667;">{{(row.total_price| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</label>
        </div>
    </div>
    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '16px' : '18px'}" style="font-weight: 700;color: #222222;">{{'Shipping_info' | translate}}</p>
    <div style="display: flex;justify-content: flex-start;align-items: center;background-color: white;margin-bottom: 10px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;padding: 15px;">
        <div style="border: 1px solid #DDDDDD;border-radius: 8px;padding: 10px;">
            <img alt="chatfoto" ngSrc="{{showData.shipping_info.image_url}}" width="56" height="22">
        </div>
        <div style="display: flex;flex-direction: column;align-items: start;padding: 0px 20px;">
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;">
                {{showData.shipping_info.delivery_name}}
            </label>
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{showData.shipping_info.delivery_type}}&nbsp;|&nbsp;<label style="color: #0e8667;font-size: 14px;font-weight: 400;">{{(showData.shipping_info.tarif| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</label></label>
        </div>
    </div>
    <div style="display: flex;justify-content: space-between;margin-bottom: 20px;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'No_receipt' | translate}}</label>
        <label cdkCopyToClipboard="{{showData.shipping_info.no_receipt}}" (click)="copy(showData.shipping_info.no_receipt)" matRipple [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #222222;cursor: pointer;">{{showData.shipping_info.no_receipt}}<i style="color: #222222;margin-left: 5px;" class="bi bi-clipboard"></i></label>
    </div>
    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '16px' : '18px'}" style="font-weight: 700;color: #222222;">{{'Recipient_Address' | translate}}</p>
    <div style="display: flex;justify-content: space-between;align-items: center;background-color: white;margin-top: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;padding: 15px;">
        <div style="display: flex;flex-direction: column;">
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 700;"><i style="color: #0e8667;margin-right: 5px;" class="bi bi-person-badge"></i> {{'Recipient' | translate}}</label>
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;">{{showData.recipient.name}}</label>
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;">{{showData.recipient.phone_number}}</label>
        </div>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: center;background-color: white;margin-top: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;padding: 15px;">
        <div style="display: flex;flex-direction: column;">
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 700;"><i style="color: #0e8667;margin-right: 5px;" class="bi bi-geo-alt"></i> {{'Address' | translate}}</label>
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;">{{showData.address.address}}, {{showData.address.province}}, {{showData.address.city}}, {{showData.address.postal_code}}</label>
        </div>
    </div>
    <div style="padding-top: 50px;">
        <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;color: #222222;">{{'Shopping_summary' | translate}}</p>
        <div style="display: flex;justify-content: space-between;">
            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Total_price' | translate}}</p>
            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{(showData.print_cost| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p>
        </div>
        <div style="display: flex;justify-content: space-between;">
            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Shipping_costs' | translate}}</p>
            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{(showData.delivery_cost| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p>
        </div>
        <div style="display: flex;justify-content: space-between;">
            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Total_discount' | translate}}</p>
            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: red;">{{(showData.print_discount| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p>
        </div>
        <hr>
        <div style="display: flex;justify-content: space-between;">
            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;color: #222222;">{{'Total_bill' | translate}}</p>
            <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;color: #222222;">{{(showData.total| currency:'Rp ':'symbol':'1.0')?.replace(',', '.')}}</p>
        </div>
    </div>
</div>
<mat-dialog-actions *ngIf="showData.delivery_status != 'Pending Payment'" [ngStyle]="{'display': appServiceScreenMobile ? 'flex' : '', 'align-items': appServiceScreenMobile ? 'center' : '', 'margin': appServiceScreenMobile ? '' : '0px 20px 20px 20px'}">
    <div class="col-6">
        <app-loading *ngIf="isLoading == false; else hideLoading"></app-loading>
        <ng-template #hideLoading>
            <button (click)="getReOrder(showData.transaction_id)" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="margin: -5px;width: 100%;border-radius: 8px;border: 1px solid #DDDDDD;background-color: white;font-weight: 700;color: black;" mat-button>{{'Re_order' | translate}}</button>
        </ng-template>
    </div>
    <div class="col-6">
        <button (click)="closeBottomSheet()" [mat-dialog-close]="true" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="margin: 5px;width: 100%;border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;" mat-button>{{'Close' | translate}}</button>
    </div>
</mat-dialog-actions>
