<div [ngStyle]="{'height': appServiceScreenDesktop ? '400px' : appServiceScreenMobileSmall ? '150px' : '220px'}" style="background-repeat: no-repeat;background-image: url(../../../../../assets/img/bg-about.webp);display: flex;align-items: center;justify-content: center;flex-direction: column;">
    <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '35pt' : appServiceScreenMobileSmall ? '15pt' : '18pt'}" style="font-weight: 700;color: #222222;text-align: center;">{{'We_re_here_to_celebrate_your' | translate}}</label>
    <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '35pt' : appServiceScreenMobileSmall ? '15pt' : '18pt'}" style="font-weight: 700;color: #222222;">{{'cherish_moments' | translate}}</label>
</div>
<!-- <div [ngClass]="{'container': appServiceScreenMobile}" [ngStyle]="{'text-align': appServiceScreenDesktop ? 'center' : 'start','padding-top': appServiceScreenDesktop ? '80px' : appServiceScreenMobileSmall ? '30px' : '50px'}">
    <label [ngStyle]="{'width': appServiceScreenDesktop ? '70%' : '100%', 'font-size': appServiceScreenDesktop ? '30px' : appServiceScreenMobileSmall ? '16px' : '20px'}" style="font-weight: 300;color: #222222;">
        {{'about_description' | translate}}
    </label>
</div> -->
<div [ngClass]="{'container': appServiceScreenMobile}" [ngStyle]="{'text-align': appServiceScreenDesktop ? '-webkit-center' : 'start'}">
    <h2 [ngStyle]="{'margin-bottom': appServiceScreenDesktop ? '50px' : '30px', 'margin-top': appServiceScreenDesktop ? '80px' : appServiceScreenMobileSmall ? '30px' : '50px'}" style="color: #222222;font-weight: 700;">{{'Who_we_are' | translate}}</h2>
</div>
<div *ngIf="appServiceScreenDesktop" class="row" style="margin-bottom: 50px;">
    <div class="col-1"></div>
    <div class="col-5">
        <div style="border: 2px solid #DDDDDD;border-radius: 24px;text-align: center;">
            <img alt="chatfoto" ngSrc="../../../../../assets/img/logo-fuji.webp" width="320px" height="79px" style="margin: 10%;">
        </div>
    </div>
    <div class="col-5">
        <p style="font-size: 24px;font-weight: 400;color: #222222;text-align: left;">
            {{'who_1' | translate}}
        </p>
        <p style="font-size: 24px;font-weight: 400;color: #222222;text-align: left;">
            {{'who_2' | translate}}
        </p>
    </div>
    <div class="col-1"></div>
</div>
<div [ngClass]="{'container': appServiceScreenMobile}" *ngIf="appServiceScreenMobile" style="margin-bottom: 30px;">
    <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '14px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #222222;text-align: left;">
        {{'who_3' | translate}}
    </p>
    <div style="border: 2px solid #DDDDDD;border-radius: 24px;text-align: center;">
        <img alt="chatfoto" ngSrc="../../../../../assets/img/logo-fuji.webp" width="128px" height="32px" style="margin: 10%;">
    </div>
    <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '14px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #222222;text-align: left;margin-top: 5%;">
        {{'who_4' | translate}}
    </p>
</div>
<div [ngClass]="{'row': appServiceScreenDesktop}">
    <div [ngClass]="{'col-sm': appServiceScreenDesktop}">
        <img alt="chatfoto" class="img-fluid" ngSrc="../../../../../assets/img/visi-misi-cf.png" style="padding: 0 20px 0 0;" width="{{appServiceScreenDesktop ? '656px' : 'auto'}}" height="{{appServiceScreenDesktop ? '480px' : appServiceScreenMobileSmall ? '280px' : '280px'}}">
    </div>
    <div [ngClass]="{'col-sm': appServiceScreenDesktop, 'container': appServiceScreenMobile}" [ngStyle]="{'align-self': appServiceScreenDesktop ? 'center' : 'start', 'margin-top': appServiceScreenDesktop ? '0px' : '10%'}">
        <div>
            <h2 style="font-weight: 700;color: #717171;">{{'Vision' | translate}}</h2>
            <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '24px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #222222;">{{'Vision_description' | translate}}</p>
        </div>
        <div>
            <h2 style="font-weight: 700;color: #717171;">{{'Mission' | translate}}</h2>
            <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '24px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #222222;">{{'Mission_description' | translate}}</p>
        </div>
    </div>
</div>
<app-why [isHomeWhy]="isHomeWhy"></app-why>
<div [ngStyle]="{'text-align': appServiceScreenDesktop ? '-webkit-center' : 'start'}">
    <h2 [ngStyle]="{'margin-top': appServiceScreenDesktop ? '80px' : appServiceScreenMobileSmall ? '30px' : '50px', 'margin-bottom': appServiceScreenDesktop ? '50px' : '30px', 'padding': appServiceScreenDesktop ? '0px' : '0px 10px'}" style="color: #222222;font-weight: 700;">{{'Product_usage' | translate}}</h2>
    <app-gallery></app-gallery>
</div>