<div [ngStyle]="{'height': appServiceScreenDesktop ? '533px' : appServiceScreenMobileSmall ? '230px' : '333px'}" fetchpriority="high" style="background-repeat: no-repeat;background-image: url(../../../../../assets/img/bg-home.webp);display: flex;flex-direction: column;justify-content: center;align-items: center;">
    <div *ngIf="appServiceScreenDesktop" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <label style="font-weight: 700;font-size: 56px;color: #222222;">{{'Transform_Your_Memories_into' | translate}}</label>
        <label style="font-weight: 700;font-size: 56px;color: #222222;">{{'Timeless_Prints_with' | translate}} <img alt="chatfoto" ngSrc="../../../../../assets/img/logo.webp" width="271px" height="44px"></label>
        <label style="font-weight: 400;font-size: 24px;color: #222222;">{{'Online_Photo_Printing_Made_Easy_and_Affordable' | translate}}</label>
        <button routerLink="print" style="width: 240px;height: 48px;border-radius: 8px;background-color: #0e8667;font-size: 16px;font-weight: 700;color: white;padding: 5px 25px;margin-top: 30px;box-shadow: 0 0 50px #30a487b3;" mat-button>{{'Print_now' | translate}}</button>
    </div>
    <div *ngIf="appServiceScreenMobile" style="text-align: center;padding: 20px;">
        <!-- <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '22px' : '32px'}" style="font-weight: 700;color: #222222;">{{'Online_Photo_Printing_Service_Easy_with_High_Quality_Paper' | translate}}</label> -->
        <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '35pt' : appServiceScreenMobileSmall ? '15pt' : '18pt'}" style="font-weight: 700;color: #222222;">{{'Transform_Your_Memories_into' | translate}}</label>
        <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '35pt' : appServiceScreenMobileSmall ? '15pt' : '18pt'}" style="font-weight: 700;color: #222222;">{{'Timeless_Prints_with' | translate}} <img alt="chatfoto" ngSrc="../../../../../assets/img/logo.webp" width="110px" height="18px"></label>
        <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '24pt' : appServiceScreenMobileSmall ? '12pt' : '14pt'}" style="font-weight: 400;color: #222222;">{{'Online_Photo_Printing_Made_Easy_and_Affordable' | translate}}</label>
    </div>
</div>
<app-gallery></app-gallery>
<!-- <div style="text-align: center;text-align: -webkit-center;">
    <h2 style="margin-top: 80px;margin-bottom: 80px;color: #222222;font-weight: 700;">How to print on ChatFoto</h2>
    <div style="width: 502px;">
        <button type="button" style="border-radius: 50px;background-color: white;font-size: 50px;font-weight: 700;color: #dadada;padding: 5px 25px;position: absolute;left: 10%;margin-top: 10%;" mat-button><i class="bi bi-arrow-left-circle"></i></button>
        <ng-image-slider #nav
            [images]="dataHowTo"
            [infinite]="sliderInfinite"
            [imagePopup]="sliderImagePopup"
            [showArrow]="false"
            [autoSlide]="0 ? 1 : 0"
            [slideImage]="+sliderSlideImage"
            [lazyLoading]="true"
            [animationSpeed]="sliderAnimationSpeed"
            [imageSize]="{width: 502, height: 320, space: 0}"
            [manageImageRatio]="true"
            [paginationShow]="false"
            [orderType]="slideOrderType"></ng-image-slider>
        <button type="button" style="border-radius: 50px;background-color: white;font-size: 50px;font-weight: 700;color: #dadada;padding: 5px 25px;position: absolute;right: 10%;margin-top: 10%;" mat-button><i class="bi bi-arrow-right-circle"></i></button>
    </div>
    <div style="display: flex;align-items: center;padding: 10px;border: 1px solid #DDDDDD;width: 50%;border-radius: 16px;margin-top: 30px;">
        <div style="font-size: 48px;font-weight: 700; color: #0e8667;padding: 0px 50px 0px 50px;">
            <label>1</label>
        </div>
        <div style="display: flex;flex-direction: column;align-items: flex-start;">
            <label style="font-size: 20px;font-weight: 700;">Upload Your Photos</label>
            <p style="font-size: 16px;font-weight: 400;">Select the photos you want to print from your computer or mobile device</p>
        </div>
    </div>
</div> -->
<app-highlight-product></app-highlight-product>
<app-why [isHomeWhy]="isHomeWhy"></app-why>
<app-testimonial></app-testimonial>
<img alt="chatfoto" src="../../../../assets/img/home-image-footer.webp" style="object-fit: cover;width: 100%;" [ngStyle]="{'height': appServiceScreenMobileSmall || appServiceScreenMobile ? '' : '480px'}">
<div [ngClass]="{'container': appServiceScreenMobile}" [ngStyle]="{'text-align': appServiceScreenDesktop ? '-webkit-center' : 'start'}">
    <h2 [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '50px' : '80px'}" style="margin-bottom: 50px;color: #222222;font-weight: 700;">{{'Frequently_asked_questions' | translate}}</h2>
    <div [ngStyle]="{'width': appServiceScreenDesktop ? '60%' : '100%'}">
        <app-faq></app-faq>
    </div>
</div>
