import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;
  baseFilesUrl: string = environment.apiFilesURL;
  baseUrlFrame: string = environment.apiFrame;

  private isProgress = new BehaviorSubject<number>(0);
  private isProgress$ = this.isProgress.asObservable();

  private isUpload = new BehaviorSubject<boolean>(false);
  private isUpload$ = this.isUpload.asObservable();

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin, private snackBar: MatSnackBar, private router: Router, private dialog: MatDialog, public appService: AppService) { }

  public async getPhoto(photoSizeId: number, ordered: boolean, uploaded: boolean, added_to_cart: boolean) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken())
      .set('Content-Type', 'application/json');
    let params = new HttpParams();
      params = params.append("photo_size_id", photoSizeId);
      params = params.append("ordered", ordered);
      params = params.append("uploaded", uploaded);
      params = params.append("added_to_cart", added_to_cart);
    return this.httpClient.get<any>(`${this.baseUrl}photo`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }

  public async getPhotoById(id: number) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken())
      .set('Content-Type', 'application/json');
    return this.httpClient.get<any>(`${this.baseUrl}photo-id/${id}`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public postPhoto(formData: FormData): Observable<HttpEvent<any>>{
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken())
      .set('ngsw-bypass', '');
    return this.httpClient.post<any>(`${this.baseUrl}photo`, formData, {
      reportProgress: true,
      observe: 'events',
      headers: httpHeaders
    })
      .pipe(map(response => {
        return response;
      }));
  }

  public async deletePhotoById(id: number) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.delete<any>(`${this.baseUrl}photo-id/${id}`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async saveToCart(photoId: any, qty: any, whiteBorder: any) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken())
      .set('Content-Type', 'application/json');
    const data = JSON.stringify({
      "photo_id": photoId,
      "qty": qty,
      "white_border": whiteBorder
    })
    return this.httpClient.post<any>(`${this.baseUrl}add-to-cart`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async putPhoto(id: number, formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}photo-edit/${id}`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public showFrameNew(){
    const httpHeaders = new HttpHeaders();
    return this.httpClient.get<any>(`${this.baseUrlFrame}frame`, { headers: httpHeaders });
  }

  setIsProgress(isProgress: number) {
    this.isProgress.next(isProgress);
  }

  getIsProgress(): Observable<number> {
    return this.isProgress$;
  }

  setIsUpload(isUpload: boolean) {
    this.isUpload.next(isUpload);
  }

  getIsUpload(): Observable<boolean> {
    return this.isUpload$;
  }

  public uploadFoto(formData: FormData, pathReloadCurrent: boolean, pathReload: string) {
    this.setIsUpload(true);
    this.setIsProgress(1)

    this.postPhoto(formData)
    .subscribe({
      next: (event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          let a = Math.round((99 / event.total) * event.loaded)
          this.setIsProgress(a)
        } 
        else if (event instanceof HttpResponse) {
          this.setIsUpload(false)
          if(event.body['code'] == 201 || event.body['status'] == 201) {
            if(pathReloadCurrent == true) {
              this.appService.reloadPage(pathReloadCurrent)
            } else {
              this.appService.reloadPage(pathReloadCurrent, pathReload)
              this.dialog.closeAll()
            }
          } else {
            this.snackBar.open(event.body['message'], '', {
              duration: 3 * 1000,
              panelClass: ['failed-snackbar']
            })
          }
        }
      },
      error: (err: any) => {
        this.setIsUpload(false)
        this.snackBar.open(err.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      }
    });
  }

  public postWhiteBorder(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}photo/update-white-border`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public postClickNew(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}photo/update-new`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public postTempQty(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}photo/update-temporary-qty`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
