import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { TestimonialModel } from 'src/app/models/testimonial-model';
import { ApiService as ApiTestimonial } from 'src/app/services/testimonial/api.service';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data!: TestimonialModel.Result[];
  isLoading: boolean = true;

  constructor(appService: AppService, private apiTestimonial: ApiTestimonial) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })
    // this.data = [{id: 1, name: 'data 1', description: ''},{id: 2, name: 'data 2', description: ''},{id: 3, name: 'data 3', description: ''},{id: 4, name: 'data 4', description: ''},{id: 5, name: 'data 5', description: ''},{id: 6, name: 'data 6', description: ''},{id: 7, name: 'data 7', description: ''},{id: 8, name: 'data 8', description: ''},{id: 9, name: 'data 9', description: ''},{id: 10, name: 'data 10', description: ''}]
    this.getTestimonial()
  }

  async getTestimonial() {
    (await this.apiTestimonial.getTestimonial())
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.data = v.results
      },
      error: (e) => {
        this.isLoading = false
      },
      complete: () => {
        this.isLoading = false
      }
    });
  }
}
