import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService as ApiPhoto } from 'src/app/services/photo/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PhotoModel } from 'src/app/models/photo-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements AfterViewInit {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  isLoading: boolean = true;
  photoModel!: PhotoModel.Daum[];
  photoSizeModel!: PhotoModel.PhotoSize;
  photoHeader!: PhotoModel.Results;
  id: number;
  ordered: boolean = false;
  uploaded: boolean = false;
  addToCart: boolean = false;
  idPhoto: any = [];
  qty: any = [];
  whiteBorder: any = [];
  totalQty!: number;

  constructor(private appService: AppService, private translate: TranslateService, private apiPhoto: ApiPhoto, private snackBar: MatSnackBar, private activedRoute: ActivatedRoute, private router: Router, private elementRef: ElementRef) { 
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en')
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!)
    }

    this.id = this.activedRoute.snapshot.params['id']
    this.ordered = this.activedRoute.snapshot.params['ordered']
    this.uploaded = this.activedRoute.snapshot.params['uploaded']
    this.addToCart = this.activedRoute.snapshot.params['addToCart']
    this.callPhoto(this.id, this.ordered, this.uploaded, this.addToCart)
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument
        .body.style.backgroundColor = '#FAFAFA';
  }

  async callPhoto(id: number, ordered: boolean, uploaded: boolean, addToCart: boolean) {
    this.isLoading = false;
    
    (await this.apiPhoto.getPhoto(id, ordered, uploaded, addToCart))
    .subscribe({
      next: (response) => {
        this.isLoading = true
        this.photoModel = response.results.data
        this.photoSizeModel = response.results.photo_size
        this.photoHeader = response.results
        if(this.photoModel.length > 0) {
          for(let value of this.photoModel.values()) {
            this.idPhoto.push(value.id)
            this.qty.push(value.cart != null ? value.cart.qty : value.temporary_qty == null ? 1 : value.temporary_qty)
            this.whiteBorder.push(value.white_border != null ? value.white_border : 0)
          }
  
          localStorage.setItem('idPhoto', JSON.stringify(this.idPhoto))
          localStorage.setItem('qty', JSON.stringify(this.qty))
          localStorage.setItem('whiteBorder', JSON.stringify(this.whiteBorder))
  
          this.totalQty = this.qty.map((item: any) => item).reduce(function(total: number, score: string | number) {return total + +score}, 0)
          this.appService.setIsTotalQty(this.totalQty)

          if(localStorage.getItem('qty') != undefined)
            this.qty = JSON.parse(localStorage.getItem('qty')!)
        } else {
          localStorage.removeItem('idPhoto')
          localStorage.removeItem('qty')
          localStorage.removeItem('whiteBorder')
        }
      },
      error: (error) => {
        this.isLoading = true
        if(error.error == 'Unauthorized.')
          localStorage.clear()
          window.location.assign(environment.urlIndex)
        
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  
}
