import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { TransactionModel } from 'src/app/models/transaction-model';
import { Clipboard } from '@angular/cdk/clipboard';
import { ApiService as ApiTransaction } from 'src/app/services/transaction/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detail-transaction',
  templateUrl: './detail-transaction.component.html',
  styleUrls: ['./detail-transaction.component.css']
})
export class DetailTransactionComponent {
  panelOpenState = false;
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  showData!: TransactionModel.Result;
  isLoading: boolean = true;

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<DetailTransactionComponent>,
    private bottomSheetRef: MatBottomSheetRef<DetailTransactionComponent>,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private apiTransaction: ApiTransaction,
    public appService: AppService
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA);
    
      this.showData = this.data == undefined ? '' : this.data['data']
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA);
    
      this.showData = this.dataMobile == undefined ? '' : this.dataMobile['data']
    }
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }

  async getReOrder(id: number) {
    (await this.apiTransaction.getReOrder(id))
    .subscribe({
      next: (v) => {
        this.isLoading = false
        if(v.code == 200) {
          if(this.appServiceScreenMobile) {
            this.closeBottomSheet()
          } else {
            this.dialogRef.close()
          }
          this.appService.reloadPage(false, 'cart')
        } else {
          this.snackBar.open(v.message, '', {
            duration: 3 * 1000,
            panelClass: ['success-snackbar']
          })
        }
      },
      error: (e) => {
        this.isLoading = false
        this.snackBar.open(e.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }

  copy(data: string) {
    this.clipboard.copy(data)
    this.snackBar.open('Copy Success ' + data, '', {
      duration: 3 * 1000,
      panelClass: ['failed-snackbar']
    })
  }

  
}
