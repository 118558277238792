import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.css']
})
export class TncComponent {
  myHtml: String | undefined;
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;

  constructor(
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<TncComponent>,
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<TncComponent>,
    private bottomSheet: MatBottomSheet,
    private appService: AppService
  ) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA);
    
      this.myHtml = this.data == undefined ? '' : this.data['tnc']
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA);

      this.myHtml = this.dataMobile == undefined ? '' : this.dataMobile['tnc']
    }
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }

}
