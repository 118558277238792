import { Component, Inject, Injector, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { CartModel } from 'src/app/models/cart-model';
import { RecipientModel } from 'src/app/models/recipient-model';
import { ApiService as ApiRecipient } from 'src/app/services/recipient/api.service';

@Component({
  selector: 'app-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: ['./recipient.component.css']
})
export class RecipientComponent {
  isNew: boolean = false;
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  dataModel!: RecipientModel.Result[];
  dataEditModel: RecipientModel.Result | undefined;
  form!: FormGroup;
  isButton: boolean = true;
  isLoading: boolean = true;
  isLoadingSave: boolean = true;
  isDefault: boolean = false;
  title!: string;
  dataRecipient!: number;

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<RecipientComponent>,
    private bottomSheetRef: MatBottomSheetRef<RecipientComponent>,
    private apiRecipient: ApiRecipient,
    private snackBar: MatSnackBar,
    public fb: FormBuilder,
    public appService: AppService
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.form = this.fb.group({
      name: ['', Validators.required],
      phone_number: ['', Validators.required],
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA)
    
      this.dataRecipient = this.data == undefined ? '' : this.data['recipient']
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA)
    
      this.dataRecipient = this.dataMobile == undefined ? '' : this.dataMobile['recipient']
    }

    this.showData()
  }

  showData() {
    this.isLoading = false;
    
    this.apiRecipient.showRecipient()
    .subscribe({
      next: (response) => {
        this.isLoading = true
        this.dataModel = response.results
        if(this.dataModel.length > 0)
          this.isNew = false
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async send(title: string) {
    this.isLoadingSave = false;
    
    let api
    if(title == 'Edit_new_contact') {
      let editData: RecipientModel.Result = {
        name: this.form.get('name')?.value,
        phone_number: this.form.get('phone_number')?.value,
        id: this.dataEditModel?.id!,
        default: this.isDefault ? 'false' : 'true',
        active: this.dataEditModel?.active!
      }
      api = (await this.apiRecipient.updateRecipient(this.dataEditModel?.id!, editData))
    } else {
      var formData: any = new FormData();
      formData.append('name', this.form.get('name')?.value);
      formData.append('phone_number', this.form.get('phone_number')?.value);
      formData.append('is_default', this.isDefault);
      api = (await this.apiRecipient.createRecipient(formData))
    }
    
    api
    .subscribe({
      next: (response) => {
        this.isLoadingSave = true;
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })

        this.showData()
        this.clearForm()
      },
      error: (error) => {
        this.isLoadingSave = true;
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  clearForm() {
    this.form.setValue({'name': null, 'phone_number': null});
  }

  async deleteData(id: number) {
    this.isLoading = false;
    
    (await this.apiRecipient.deleteRecipient(id))
    .subscribe({
      next: (response) => {
        this.isLoading = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })

        this.showData()
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async setDefaultData(id: number) {
    this.isLoading = false;

    var formData: any = new FormData();
    formData.append('id', id);
    
    (await this.apiRecipient.setDefaultRecipient(formData))
    .subscribe({
      next: (response) => {
        this.isLoading = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })

        this.showData()
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async setRecipientTemp(id: number) {
    this.isLoading = false;
    
    var formData: any = new FormData();
    formData.append('recipient_id', id);
    
    (this.apiRecipient.setRecipient(formData))
    .subscribe({
      next: (response) => {
        this.isLoading = true

        if(this.appServiceScreenMobile) {
          this.bottomSheetRef.dismiss()
        } else {
          this.dialogRef.close()
        }

        // this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoading = true
      },
    })
  }

  checkListDefault(event: any) {
    this.isDefault = event.checked
  }

  ngAfterContentChecked(): void {
    const nm = this.form.value.name == null ? '' : this.form.value.name == '' ? '' : this.form.value.name;
    const hp = this.form.value.phone_number == null ? '' : this.form.value.phone_number == '' ? '' : this.form.value.phone_number;

    if(hp == '' || nm == '') {
      this.isButton = true;
    } else {
      this.isButton = false;
    }
  }

  addNew(title: string, recipientModel?: RecipientModel.Result) {
    this.isNew = true
    this.title = title
    if('Edit_new_contact')
      this.dataEditModel = recipientModel
      this.form.setValue({'name': this.dataEditModel?.name, 'phone_number': this.dataEditModel?.phone_number});
  }

  back_() {
    this.isNew = false
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }

  

}
