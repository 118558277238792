import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  isLoading = true;
  message: string;
  id: number;
  page: string;
  yes: boolean;
  no: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, 
    @Optional() private router: Router,
    private dialogRef: MatDialogRef<MessageComponent>
    ) { 
      
    this.message = data['message'];
    this.id = data['id'];
    this.page = data['page'];
    this.yes = data['yes'];
    this.no = data['no'];
  }

  ngOnInit(): void { }

  closeDialog() {
    this.dialogRef.close()
  }

  deleted() {
    if(this.page == 'home') {
      this.dialogRef.close()
    }
  }

}
