import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { PhotoModel } from 'src/app/models/photo-model';
import { DeletedComponent } from '../../dialog/deleted/deleted.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService as ApiPhoto } from 'src/app/services/photo/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingDialogComponent } from 'src/app/components/loading/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-list-photo',
  templateUrl: './list-photo.component.html',
  styleUrls: ['./list-photo.component.css']
})

export class ListPhotoComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  @Input() scrollUnit: number = 150;
  items: any;
  @Input() data!: PhotoModel.Daum[];
  myFiles:Array<any> = [];
  currentIndex: number = 0;
  currentQty: number = 1;
  isUpload: boolean = false;
  progress: number = 0;
  qty: any = [];
  localQty: any;
  localWhiteBorder: any;
  totalQty!: number;
  isWhiteBorder: boolean = false;
  isEdited: boolean = false;
  isToCart: string;
  // isDarkMode: any;

  constructor(private appService: AppService, private translate: TranslateService, private bottomSheet: MatBottomSheet, private dialog: MatDialog, private activedRoute: ActivatedRoute, private apiPhoto: ApiPhoto, private snackBar: MatSnackBar, private router: Router, private cd: ChangeDetectorRef) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })
    
    // this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    // console.log(this.isDarkMode)

    this.isToCart = this.activedRoute.snapshot.params['addToCart']

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en')
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!)
    }

    this.localQty = JSON.parse(localStorage.getItem('qty')!)
    this.localWhiteBorder = JSON.parse(localStorage.getItem('whiteBorder')!)

    this.totalQty = this.localQty.map((item: any) => item).reduce(function(total: number, score: string | number) {return total + +score}, 0)
    this.appService.setIsTotalQty(this.totalQty)

    this.isWhiteBorder = this.localWhiteBorder[this.currentIndex] == 0 ? false : true
    this.currentQty = this.localQty[this.currentIndex]
    this.isEdited = this.isWhiteBorder
  }

  goToEditor(id: number) {
    this.appService.reloadPage(false, 'editor/'+id+'/'+this.isToCart+'/photo')
  }

  async addToCart() {
    this.dialog.open(LoadingDialogComponent, { disableClose: true });

    (await this.apiPhoto.saveToCart(JSON.parse(localStorage.getItem('idPhoto')!), JSON.parse(localStorage.getItem('qty')!), JSON.parse(localStorage.getItem('whiteBorder')!)))
    .subscribe({
      next: (response) => {
        this.dialog.closeAll()

        if(response.code == 201) {
          const redirect = 'cart'
          this.router.navigate([redirect], { replaceUrl: true })

          localStorage.removeItem('idPhoto')
          localStorage.removeItem('qty')
          localStorage.removeItem('whiteBorder')
        }

        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })
      },
      error: (error) => {
        this.dialog.closeAll()
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  callDeleted(id: number) {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(DeletedComponent, { disableClose: true, data: {id: id, isDelete: 'photoById', title: 'Delete_photo', description: 'deleted_pc'}})
    } else {
      this.dialog.open(DeletedComponent, { disableClose: true, data: {id: id, isDelete: 'photoById', title: 'Delete_photo', description: 'deleted_pc'} })
    }
  }

  selectFile(event: any) {
    this.isUpload = true

    for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i])
    }

    const formData = new FormData();
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i])
    }
    formData.append('photo_size_id', this.activedRoute.snapshot.params['id'])

    this.apiPhoto.uploadFoto(formData, true, '')
    this.apiPhoto.getIsProgress().subscribe(appIsProgress => this.progress = appIsProgress)
    this.apiPhoto.getIsUpload().subscribe(appIsUpload => this.isUpload = appIsUpload)
    this.cd.detectChanges()
	}

  next() {
    if(this.data.length - 1 > this.currentIndex) {
      this.currentIndex = this.currentIndex + 1
    } else {
      this.currentIndex = 0
    }

    this.currentQty = this.localQty[this.currentIndex]
    this.isWhiteBorder = this.localWhiteBorder[this.currentIndex] ? true : false
    this.isEdited = this.isWhiteBorder
  }

  previous() {
    if(this.currentIndex == 0) {
      this.currentIndex = this.data.length - 1
    } else {
      this.currentIndex = this.currentIndex - 1
    }

    this.currentQty = this.localQty[this.currentIndex]
    this.isWhiteBorder = this.localWhiteBorder[this.currentIndex] ? true : false
    this.isEdited = this.isWhiteBorder
  }

  async updateQty(photoId: number, qty: number) {
    this.dialog.open(LoadingDialogComponent, { disableClose: true });

    var formData: any = new FormData();
    formData.append('photo_id', photoId);
    formData.append('qty', qty);

    (this.apiPhoto.postTempQty(formData))
    .subscribe({
      next: (v) => {
        this.dialog.closeAll()
        this.appService.reloadPage(true)
      },
      error: (e) => { 
        this.dialog.closeAll()
      },
      complete: () => { 
        this.dialog.closeAll()
      }
    })
  }

  async selectWhiteBorder(photoId: number, whiteBorder: any) {
    this.dialog.open(LoadingDialogComponent, { disableClose: true });

    var formData: any = new FormData();
    formData.append('photo_id', photoId);
    formData.append('white_border', whiteBorder.checked ? 1 : 0);

    (this.apiPhoto.postWhiteBorder(formData))
    .subscribe({
      next: (v) => {
        this.dialog.closeAll()
        this.appService.reloadPage(true)
      },
      error: (e) => { 
        this.dialog.closeAll()
      },
      complete: () => { 
        this.dialog.closeAll()
      }
    })
  }

  addQty(photoId: number) {
    if(this.currentQty >= 1)
      this.currentQty = this.currentQty + 1

    this.localQty[this.currentIndex] = this.currentQty
    localStorage.setItem('qty', JSON.stringify(this.localQty));
    this.updateQty(photoId, this.currentQty)
    this.totalQty = this.localQty.map((item: any) => item).reduce(function(total: number, score: string | number) {return total + +score}, 0)
    this.appService.setIsTotalQty(this.totalQty)
  }

  removeQty(photoId: number) {
    if(this.currentQty > 1)
      this.currentQty = this.currentQty - 1

    this.localQty[this.currentIndex] = this.currentQty
    localStorage.setItem('qty', JSON.stringify(this.localQty));
    this.updateQty(photoId, this.currentQty)
    this.totalQty = this.localQty.map((item: any) => item).reduce(function(total: number, score: string | number) {return total + +score}, 0)
    this.appService.setIsTotalQty(this.totalQty)
  }

  imageSelectNormal(i: number) {
    this.currentIndex = i - 1
    this.next()
  }

  imageSelect(i: number, id: number) {
    this.clickNew(i, id)
  }

  async clickNew(i: number, photoId: number) {
    var formData: any = new FormData();
    formData.append('photo_id', photoId);

    (this.apiPhoto.postClickNew(formData))
    .subscribe({
      next: (v) => { 
        this.appService.reloadPage(true)
        this.currentIndex = i - 1
        this.next()
      },
      error: (e) => { },
      complete: () => { }
    })
  }
}
