import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import packageJson from '../../../../../package.json';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() isFooterMobileBottom!: string;
  public version: string = packageJson.version;
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;

  constructor(private appService: AppService, private cd: ChangeDetectorRef, private router: Router) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(router.url == '/')
      this.appService.setIsFooterMobileBottom('25%')
  }

  ngOnInit(): void {
    this.appService.getIsFooterMobileBottom().subscribe(appFooterMobileBottom => this.isFooterMobileBottom = appFooterMobileBottom);
    this.cd.detectChanges();
  }

  clickTo(url: string) {
    window.open(url, '_blank')
  }
}
