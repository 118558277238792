import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-badget-qty',
  templateUrl: './badget-qty.component.html',
  styleUrls: ['./badget-qty.component.css']
})
export class BadgetQtyComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  @Input() isQty: number = 0;

  constructor(private appService: AppService, private cd: ChangeDetectorRef) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })
  }

  ngOnInit(): void {
    this.appService.getIsQty().subscribe(appQty => this.isQty = appQty);
    this.cd.detectChanges();
  }
}
