import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService as ApiProfile } from 'src/app/services/profile/api.service';
import { LoginComponent } from '../dialog/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  isLoading: boolean = true;
  isLoadingButton: boolean = true;
  isLoadingButtonReset: boolean = true;
  showPasswordNew: string = 'password';
  showPassword: string = 'password';
  formPassword!: FormGroup;
  isButton: boolean = true;
  isButtonReset: boolean = true;

  constructor(private appService: AppService, private translate: TranslateService, public fb: FormBuilder, private snackBar: MatSnackBar, private router: Router, private activedRoute: ActivatedRoute, private apiProfile: ApiProfile, private dialog: MatDialog, private bottomSheet: MatBottomSheet) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.appService.setIsPrintMobile(false)
    this.appService.setIsFooterMobileBottom('')

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en');
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
    }

    this.formPassword = this.fb.group({
      compare_password: ['', Validators.required, Validators.pattern(StrongPasswordRegx)],
      new_password: ['', Validators.required, Validators.pattern(StrongPasswordRegx)],
    })
  }

  async sendReset() {
    this.isLoadingButtonReset = false;

    var formData: any = new FormData();
    formData.append('password_confirmation', this.formPassword.get('compare_password')?.value);
    formData.append('password', this.formPassword.get('new_password')?.value);
    formData.append('forgot_password_token', this.activedRoute.snapshot.params['token']);
    
    (await this.apiProfile.putForgetPassword(formData))
    .subscribe({
      next: (response) => {
        this.isLoadingButtonReset = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        });
        this.appService.reloadPage(false, '/')
        this.callLogin()
      },
      error: (error) => {
        this.isLoadingButtonReset = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        });
      },
    })
  }

  callLogin() {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(LoginComponent, { disableClose: false });
    } else {
      this.dialog.open(LoginComponent, { disableClose: false })
    }
  }

  showHidePasswordNew() {
    if(this.showPasswordNew == 'password') {
      this.showPasswordNew = 'text'
    } else {
      this.showPasswordNew = 'password'
    }
  }

  showHidePassword() {
    if(this.showPassword == 'password') {
      this.showPassword = 'text'
    } else {
      this.showPassword = 'password'
    }
  }

  get passwordFormField() {
    return this.formPassword.get('new_password');
  }

  get passwordFormFieldCompare() {
    return this.formPassword.get('compare_password');
  }

  ngAfterContentChecked(): void {
    const opw = this.formPassword.value.compare_password == null ? '' : this.formPassword.value.compare_password == '' ? '' : this.formPassword.value.compare_password
    const npw = this.formPassword.value.new_password == null ? '' : this.formPassword.value.new_password == '' ? '' : this.formPassword.value.new_password
    if((opw == '') && (npw == '') || (npw != opw)) {
      this.isButtonReset = true
    } else {
      this.isButtonReset = false
    }
  }
}

export const StrongPasswordRegx: RegExp =
  /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
