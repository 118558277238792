<div>
  <div style="height: 550px">
    <pintura-editor
        [src]="currentImage!"
        (process)="handleProcess($event)"
        [frameStyles]="frameStyles"
        [frameOptions]="frameOptions"
        [shapePreprocessor]="shapePreprocessor"
        [imageCrop]="imageCrop"
        [annotateEnableButtonFlipVertical]="false"
        [cropEnableButtonFlipHorizontal]="false"
        [cropEnableButtonRotateLeft]="true"
        [cropEnableRotationInput]="false"
        [cropEnableZoomInput]="false"
        [willRenderToolbar]="willRenderToolbar"
        [previewUpscale]="true"
        [options]="editorDefaults"
        [enableZoom]="false"
        [enableSelectToolToAddShape]="true"
        [enableTapToAddText]="true"
        enableMoveTool="true"
        [enablePan]="true"
        [finetuneOptions]="finetuneOptions"
        [finetuneControlConfiguration]="finetuneControls"
        markupEditorTextInputMode="modal">
    </pintura-editor>
  </div>
</div>