import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin) { }

  public async getBanner() {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.httpClient.get<any>(`${this.baseUrl}banner`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
