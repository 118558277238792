<div *ngIf="isLoading == false; else hideLoading">
    <ng-image-slider #nav
        [images]="data"
        [infinite]="sliderInfinite"
        [imagePopup]="sliderImagePopup"
        [showArrow]="sliderArrowShow"
        [autoSlide]="sliderAutoSlide ? 1 : 0"
        [slideImage]="+sliderSlideImage"
        [lazyLoading]="true"
        [animationSpeed]="sliderAnimationSpeed"
        [imageSize]="{'width': appServiceScreenDesktop ? 380 : appServiceScreenMobileSmall ? 145 : 245, 'height': appServiceScreenDesktop ? 255 : appServiceScreenMobileSmall ? 100 : 160, space: 10}"
        [manageImageRatio]="true"
        [paginationShow]="false"
        [orderType]="slideOrderType"></ng-image-slider>
</div>
<ng-template #hideLoading>
    <div style="display: flex;justify-content: center;width: 100%;">
        <app-loading></app-loading>
    </div>
</ng-template>