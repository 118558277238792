import { Component, Inject, Injector, Input, Optional, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService as ApiCart } from 'src/app/services/cart/api.service';
import { ApiService as ApiPhoto } from 'src/app/services/photo/api.service';
import { ApiService as ApiTransaction } from 'src/app/services/transaction/api.service';

@Component({
  selector: 'app-deleted',
  templateUrl: './deleted.component.html',
  styleUrls: ['./deleted.component.css']
})
export class DeletedComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  id: number;
  isDelete: string;
  title: string;
  description: string;
  name: string;
  isLoading: boolean = true;
  mySubscription: any;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  yes: string = 'Delete';
  no: string = 'Cancel';

  constructor (
    @Inject(Injector)public injector: Injector,
    private router: Router,
    private dialogRef: MatDialogRef<DeletedComponent>,
    private bottomSheetRef: MatBottomSheetRef<DeletedComponent>,
    private apiPhoto: ApiPhoto,
    private apiCart: ApiCart,
    private apiTransaction: ApiTransaction,
    private snackBar: MatSnackBar,
    public appService: AppService
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA);
    
      this.id = this.data == undefined ? '' : this.data['id']
      this.isDelete = this.data == undefined ? '' : this.data['isDelete']
      this.title = this.data == undefined ? '' : this.data['title']
      this.description = this.data == undefined ? '' : this.data['description']
      this.name = this.data == undefined ? '' : this.data['name']
      if(this.isDelete == 'cancelOrder') {
        this.yes = this.data == undefined ? '' : this.data['yes']
        this.no = this.data == undefined ? '' : this.data['no']
      }
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA);
    
      this.id = this.dataMobile == undefined ? '' : this.dataMobile['id']
      this.isDelete = this.dataMobile == undefined ? '' : this.dataMobile['isDelete']
      this.title = this.dataMobile == undefined ? '' : this.dataMobile['title']
      this.description = this.dataMobile == undefined ? '' : this.dataMobile['description']
      this.name = this.dataMobile == undefined ? '' : this.dataMobile['name']
      if(this.isDelete == 'cancelOrder') {
        this.yes = this.dataMobile == undefined ? '' : this.dataMobile['yes']
        this.no = this.dataMobile == undefined ? '' : this.dataMobile['no']
      }
    }
  }

  async callDeleted() {
    this.isLoading = false;
    
    let api: any
    if(this.isDelete == 'photoById') {
      api = (await this.apiPhoto.deletePhotoById(this.id))
    } else if(this.isDelete == 'cartPhotoAll') {
      api = (await this.apiCart.deleteCartPhotoAll(this.id))
    } else if(this.isDelete == 'cancelOrder') {
      api = (await this.apiTransaction.cancelTransaction(this.id))
    }

    api
    .subscribe({
      next: (response: { message: string; }) => {
        this.isLoading = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })
        this.appService.reloadPage(true)
        this.closeBottomSheet()
      },
      error: (error: { error: { message: string; }; }) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
        this.appService.reloadPage(true)
        this.closeBottomSheet()
      },
    })
  }

  reloadPage(self: boolean, urlToNavigateTo?: string) {
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
      this.router.navigate([`/${url}`]).then(()=>{ })
    })
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile) {
      this.bottomSheetRef.dismiss()
    } else {
      this.dialogRef.close()
    }
  }
}
