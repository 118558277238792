import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService as ApiTransaction } from 'src/app/services/transaction/api.service';
import { TransactionModel } from 'src/app/models/transaction-model';
import { AppService } from 'src/app/app.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { DetailTransactionComponent } from '../../dialog/detail-transaction/detail-transaction.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DetailMyOrderComponent } from '../../dialog/detail-my-order/detail-my-order.component';
import { DeletedComponent } from '../../dialog/deleted/deleted.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  isLoading: boolean = true;
  @Input() data!: TransactionModel.Result[];

  constructor(private dialog: MatDialog, private bottomSheet: MatBottomSheet, private appService: AppService, private translate: TranslateService, private apiTransaction: ApiTransaction, private router: Router, private snackBar: MatSnackBar) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en')
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!)
    }
  }

  getCancelOrder(id: number, desc: string) {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(DeletedComponent, { disableClose: true, data: {id: id, isDelete: 'cancelOrder', title: 'cancel_order', description: desc, name: name, yes: 'yes', no: 'no'}})
    } else {
      this.dialog.open(DeletedComponent, { disableClose: true, data: {id: id, isDelete: 'cancelOrder', title: 'cancel_order', description: desc, name: name, yes: 'yes', no: 'no'} })
    }
  }

  async getReOrder(id: number) {
    (await this.apiTransaction.getReOrder(id))
    .subscribe({
      next: (v) => {
        this.isLoading = false
        if(v.code == 200) {
          this.appService.reloadPage(false, 'cart')
        } else {
          this.snackBar.open(v.message, '', {
            duration: 3 * 1000,
            panelClass: ['success-snackbar']
          })
        }
      },
      error: (e) => {
        this.isLoading = false
        this.snackBar.open(e.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }

  async getInvoice(id: number) {
    let wref = window.open();
    (await this.apiTransaction.getInvoice(id))
    .subscribe({
      next: (v) => {
        wref!.location = v.file_url;
        // window.open(v.file_url, '_blank')
        this.snackBar.open(v.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })
      },
      error: (e) => {
        this.snackBar.open(e.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
      complete: () => { }
    })
  }

  callDetailTransaction(indexArray: number) {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(DetailTransactionComponent, { disableClose: false, data: {data: this.data[indexArray]} });
    } else {
      this.dialog.open(DetailTransactionComponent, { disableClose: false, data: {data: this.data[indexArray]} })
    }
  }

  continuePayment(redirectUrl: string) {
    window.location.href = redirectUrl
  }

  callDetailPhoto(photo: TransactionModel.DetailProduct) {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(DetailMyOrderComponent, { disableClose: false, data: {photos: photo} })
    } else {
      this.dialog.open(DetailMyOrderComponent, { disableClose: false, data: {photos: photo} })
    }
  }
}
