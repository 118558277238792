<div *ngIf="appServiceScreenDesktop">
    <div style="text-align: center;text-align: -webkit-center;">
        <h2 style="margin-top: 80px;margin-bottom: 50px;color: #222222;font-weight: 700;">{{'Why' | translate}} ChatFoto?</h2>
        <div class="row">
            <div class="col-3"></div>
            <div class="col-2">
                <img alt="chatfoto" ngSrc="../../../../../assets/img/why1.webp" width="80px" height="80px" style="position: relative;z-index: 1;">
                <div style="height: 230px;border-radius: 16px;box-shadow: 0 0 15px #0000001A;top: -35px;position: relative;padding: 20px;">
                    <label style="font-size: 20px;font-weight: 700;color: #222222;padding-top: 40px;">{{'Easy' | translate}}</label>
                    <p style="font-size: 14px;font-weight: 400;color: #717171;padding-top: 20px;">{{'Easy_1' | translate}}</p>
                </div>
            </div>
            <div class="col-2">
                <img alt="chatfoto" ngSrc="../../../../../assets/img/why2.webp" width="80px" height="80px" style="position: relative;z-index: 1;">
                <div style="height: 230px;border-radius: 16px;box-shadow: 0 0 15px #0000001A;top: -35px;position: relative;padding: 20px;">
                    <label style="font-size: 20px;font-weight: 700;color: #222222;padding-top: 40px;">{{'Efficient' | translate}}</label>
                    <p style="font-size: 14px;font-weight: 400;color: #717171;padding-top: 20px;">{{'Efficient_1' | translate}}</p>
                </div>
            </div>
            <div class="col-2">
                <img alt="chatfoto" ngSrc="../../../../../assets/img/why3.webp" width="80px" height="80px" style="position: relative;z-index: 1;">
                <div style="height: 230px;border-radius: 16px;box-shadow: 0 0 15px #0000001A;top: -35px;position: relative;padding: 20px;">
                    <label style="font-size: 20px;font-weight: 700;color: #222222;padding-top: 40px;">{{'High_Quality_Prints' | translate}}</label>
                    <p style="font-size: 14px;font-weight: 400;color: #717171;padding-top: 20px;">{{'High_Quality_Prints_1' | translate}}</p>
                </div>
            </div>
            <div class="col-3"></div>
        </div>
    </div>

    <div *ngIf="isHomeWhy" style="text-align: center;text-align: -webkit-center;">
        <h2 style="margin-top: 80px;margin-bottom: 50px;color: #222222;font-weight: 700;">{{'We_deliver_the_best_quality_to_you' | translate}}</h2>
        <div class="row">
            <div class="col-3"></div>
            <div class="col-2">
                <label style="font-size: 56px;font-weight: 700;background: -webkit-linear-gradient(#1DAB87, #0092CC);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">20%</label>
                <p style="font-size: 20px;font-weight: 200;color: #222222;">{{'Save_more_money' | translate}}</p>
            </div>
            <div class="col-2">
                <label style="font-size: 56px;font-weight: 700;background: -webkit-linear-gradient(#1DAB87, #0092CC);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">90%</label>
                <p style="font-size: 20px;font-weight: 200;color: #222222;">{{'Sattisfied_customers' | translate}}</p>
            </div>
            <div class="col-2">
                <label style="font-size: 56px;font-weight: 700;background: -webkit-linear-gradient(#1DAB87, #0092CC);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">1mi+</label>
                <p style="font-size: 20px;font-weight: 200;color: #222222;">{{'Photos_delivered' | translate}}</p>
            </div>
            <div class="col-3"></div>
        </div>
    </div>
</div>
<div class="container" *ngIf="appServiceScreenMobile">
    <div style="text-align: start;">
        <h3 [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '30px' : '50px'}" style="margin-bottom: 30px;color: #222222;font-weight: 700;">{{'Why' | translate}} ChatFoto?</h3>
        <div style="display: flex;margin: 20px 0px 10px 0px;align-items: center;">
            <div style="margin-right: 20px;">
                <img alt="chatfoto" ngSrc="../../../../../assets/img/why1.webp" width="64px" height="64px">
            </div>
            <div style="display: flex;flex-direction: column;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #222222;font-weight: 700;">{{'High_Quality_Prints' | translate}}</label>
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'High_Quality_Prints_1' | translate}}</label>
            </div>
        </div>
        <div style="display: flex;margin: 20px 0px 10px 0px;align-items: center;">
            <div style="margin-right: 20px;">
                <img alt="chatfoto" ngSrc="../../../../../assets/img/why2.webp" width="64px" height="64px">
            </div>
            <div style="display: flex;flex-direction: column;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #222222;font-weight: 700;">{{'Easy_Ordering_Process' | translate}}</label>
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Easy_1' | translate}}</label>
            </div>
        </div>
        <div style="display: flex;margin: 20px 0px 10px 0px;align-items: center;">
            <div style="margin-right: 20px;">
                <img alt="chatfoto" ngSrc="../../../../../assets/img/why3.webp" width="64px" height="64px">
            </div>
            <div style="display: flex;flex-direction: column;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #222222;font-weight: 700;">{{'Efficient' | translate}}</label>
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">{{'Efficient_1' | translate}}</label>
            </div>
        </div>
    </div>
    <div *ngIf="isHomeWhy" style="text-align: start;">
        <h3 [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '30px' : '50px'}" style="margin-bottom: 30px;color: #222222;font-weight: 700;">{{'We_deliver_the_best_quality_to_you' | translate}}</h3>
        <div style="display: flex;margin: 20px 0px 10px 0px;align-items: center;">
            <div style="margin-right: 20px;background-color: #FAFAFA;border-radius: 8px;padding: 8px;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '20px' : '24px'}" style="font-weight: 700;background: -webkit-linear-gradient(#1DAB87, #0092CC);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">20%</label>
            </div>
            <div style="display: flex;flex-direction: column;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #222222;font-weight: 400;">{{'Save_more_money' | translate}}</label>
            </div>
        </div>
        <div style="display: flex;margin: 20px 0px 10px 0px;align-items: center;">
            <div style="margin-right: 20px;background-color: #FAFAFA;border-radius: 8px;padding: 8px;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '20px' : '24px'}" style="font-weight: 700;background: -webkit-linear-gradient(#1DAB87, #0092CC);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">90%</label>
            </div>
            <div style="display: flex;flex-direction: column;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #222222;font-weight: 400;">{{'Sattisfied_customers' | translate}}</label>
            </div>
        </div>
        <div style="display: flex;margin: 20px 0px 10px 0px;align-items: center;">
            <div style="margin-right: 20px;background-color: #FAFAFA;border-radius: 8px;padding: 8px;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '20px' : '24px'}" style="font-weight: 700;background: -webkit-linear-gradient(#1DAB87, #0092CC);-webkit-background-clip: text;-webkit-text-fill-color: transparent;">1mi+</label>
            </div>
            <div style="display: flex;flex-direction: column;">
                <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #222222;font-weight: 400;">{{'Photos_delivered' | translate}}</label>
            </div>
        </div>
    </div>
</div>