import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { ApiService as LoginService } from './services/login/api.service';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from './app.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from './components/pages/dialog/login/login.component';

@Injectable({
providedIn: 'root'
})

export class AuthguardGuard implements CanActivate {
    destroyed = new Subject<void>();
    appServiceScreenDesktop!: boolean;
    appServiceScreenMobile!: boolean;

    constructor(private apiService: LoginService, private router: Router, appService: AppService, private dialog: MatDialog, private bottomSheet: MatBottomSheet) {
        appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
            this.appServiceScreenDesktop = result
        })
      
        appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
            this.appServiceScreenMobile = result
        })
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | any {
        const routeurl: string = state.url
        return this.isLogin(routeurl)
    }

    isLogin(routeurl: string): true | any {
        if (this.apiService.isLoggedIn()) {
            return true
        }

        this.apiService.redirectUrl = routeurl
        this.router.navigate(['/'], {queryParams: { returnUrl: routeurl }})
        if(this.appServiceScreenMobile) {
            this.bottomSheet.open(LoginComponent, { disableClose: true, data: {returnUrl: routeurl} })
        } else {
            this.dialog.open(LoginComponent, { disableClose: true, data: {returnUrl: routeurl} })
        }
    }
}
