import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient) { }

  public async getData(highlight: boolean) {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("highlight", highlight);
    return this.httpClient.get<any>(`${this.baseUrl}photo-size`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }
}
