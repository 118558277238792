<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'Detail_voucher' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<div mat-dialog-content style="padding-top: 0px;">
    <p [innerHtml]="myHtml"></p>
</div>
<mat-dialog-actions *ngIf="isClose">
    <button [ngStyle]="{'margin': appServiceScreenMobile ? '0 0 20px 0' : '20px', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" (click)="back_()" style="width: 100%;border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;" mat-button>{{'Close' | translate}}</button>
</mat-dialog-actions>
