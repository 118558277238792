<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;" *ngIf="isNew == false; else back">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'Choose_recipient' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
    <ng-template #back>
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <label *ngIf="isNew == true" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">
                <button (click)="back_()" mat-icon-button aria-label="back"><i class="bi bi-arrow-left-short"></i></button>{{title | translate}}
            </label>
            <button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
        </div>
    </ng-template>
</h2>
<form [formGroup]="form" (ngSubmit)="send(title)">
    <div mat-dialog-content style="text-align: center;">
        <app-loading *ngIf="isLoading == false; else hideLoading"></app-loading>
        <ng-template #hideLoading>
            <div *ngIf="isNew == true; else list">
                <div [ngClass]="{'row': appServiceScreenDesktop}" style="text-align: left;">
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobile ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Recipient_name' | translate}}</label>
                        <input formControlName="name" type="text" [ngStyle]="{'height': appServiceScreenMobile ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="Example: Rara">
                    </div>
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobile ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Phone_number' | translate}}</label>
                        <input formControlName="phone_number" type="number" [ngStyle]="{'height': appServiceScreenMobile ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="Example: 0811xxxxxx">
                    </div>
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <section>
                            <mat-checkbox (change)="checkListDefault($event)">{{'Main_contact' | translate}}</mat-checkbox>
                        </section>
                    </div>
                </div>
            </div>
            <ng-template #list>
                <div *ngIf="dataModel.length > 0; else dataNotFound">
                    <button mat-button (click)="addNew('Add_new_contact')" [ngStyle]="{'width': appServiceScreenMobile ? '100%' : '432px', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="border-radius: 8px;background-color: transparent;font-weight: 700;color: #0e8667;border: 1px solid #DDDDDD;"><i style="color: #0e8667;" class="bi bi-plus-circle-fill"></i> {{'Add_new_contact' | translate}}</button>
                    <div *ngFor="let row of dataModel;let indexElementArray = index;" style="display: flex;justify-content: space-between;align-items: center;background-color: #FAFAFA;margin-top: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;padding: 15px;">
                        <div style="display: flex;flex-direction: column;align-items: start;">
                            <label [ngStyle]="{'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '12px' : '14px' : '16px'}" style="font-weight: 700;">
                                {{row.name}} <label *ngIf="row.default == 'true'" style="padding: 0px 10px;color: #0e8667;background-color: #EDFDF9;font-weight: 700;font-size: 12px;border-radius: 10px;">{{'Main_contact' | translate}}</label>
                            </label>
                            <label [ngStyle]="{'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '10px' : '12px' : '14px'}" style="font-weight: 400;text-align: start;">{{row.phone_number}}</label>
                            <p [ngStyle]="{'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '10px' : '12px' : '14px'}" style="font-weight: 700;margin-bottom: 0;">
                                <label (click)="addNew('Edit_new_contact', dataModel[indexElementArray])" style="color: #0e8667;cursor: pointer;">{{'Edit' | translate}}</label>&nbsp;|&nbsp;<label (click)="deleteData(row.id)" style="color: #b8505b;cursor: pointer;">{{'Delete' | translate}}</label><span *ngIf="row.default == 'false'">&nbsp;|&nbsp;<label (click)="setDefaultData(row.id)" style="color: #0e8667;cursor: pointer;">{{'set_as_main_contact' | translate}}</label></span>
                            </p>
                        </div>
                        <!-- <button (click)="setRecipientTemp(dataModel[indexElementArray].id)" [disabled]="row.default == 'true'" [ngStyle]="{'background-color': row.default == 'true' ? 'white' : '#0e8667', 'color': row.default == 'true' ? '#DDDDDD' : 'white', 'font-size': appServiceScreenMobile ? '12px' : '14px'}" style="border-radius: 8px;font-weight: 700;border: 1px solid #DDDDDD;margin: 0px 10px;" mat-button>
                            <mat-label>{{'Selected' | translate}}</mat-label>
                        </button> -->
                        <button (click)="setRecipientTemp(dataModel[indexElementArray].id)" [disabled]="dataRecipient == dataModel[indexElementArray].id || dataModel.length == 1" [ngStyle]="{'background-color': dataRecipient == dataModel[indexElementArray].id  || dataModel.length == 1 ? 'white' : '#0e8667', 'color': dataRecipient == dataModel[indexElementArray].id || dataModel.length == 1 ? '#DDDDDD' : 'white', 'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '10px' : '12px' : '14px'}" style="border-radius: 8px;font-weight: 700;border: 1px solid #DDDDDD;margin: 0px 10px;" mat-button>
                            <mat-label *ngIf="dataRecipient == dataModel[indexElementArray].id || dataModel.length == 1; else select">{{'Selected' | translate}}</mat-label>
                            <ng-template #select>
                                <mat-label>{{'Select' | translate}}</mat-label>
                            </ng-template>
                        </button>
                    </div>
                </div>
                <ng-template #dataNotFound>
                    <img alt="chatfoto" ngSrc="../../../../assets/img/recipient-not-found.png" width="192" height="120">
                    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'No_contacts_added_yet' | translate}}</p>
                    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #555555;">{{'You_haven_added_any_contact_yet' | translate}} <br> {{'Start_by_adding_your_contact_below' | translate}}</p>
                    <button mat-button (click)="addNew('Add_new_contact')" type="button" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px', 'margin': appServiceScreenMobileSmall ? '20px 0px 10px 0px' : '30px 0px 20px 0px'}" style="width: 100%;border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;"><i style="color: white;" class="bi bi-plus-circle-fill"></i> {{'Add_new_contact' | translate}}</button>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
    <mat-dialog-actions *ngIf="isNew == true" style="justify-content: center;">
        <button *ngIf="isLoadingSave else hideLoadingSave" [disabled]="isButton" [ngStyle]="{'margin': appServiceScreenMobile ? '0 0 20px 0' : '20px'}" style="width: 100%;border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;" mat-button>{{'Save' | translate}}</button>
        <ng-template #hideLoadingSave>
            <app-loading></app-loading>
        </ng-template>
    </mat-dialog-actions>
</form>
