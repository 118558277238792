import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ProfileModel } from 'src/app/models/profile-model';
import { ApiService as ApiProfile } from 'src/app/services/profile/api.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  isLoading: boolean = true;
  isLoadingButton: boolean = true;
  isLoadingButtonReset: boolean = true;
  isLoadingButtonResend!: boolean;
  data!: ProfileModel.Result;
  showPasswordOld: string = 'password';
  showPassword: string = 'password';
  form!: FormGroup;
  formPassword!: FormGroup;
  isButton: boolean = true;
  isButtonReset: boolean = true;

  constructor(private appService: AppService, private translate: TranslateService, private apiProfile: ApiProfile, public fb: FormBuilder, private snackBar: MatSnackBar, private router: Router) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.appService.setIsPrintMobile(false)
    this.appService.setIsFooterMobileBottom('')

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en');
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
    }

    this.form = this.fb.group({
      phone_number: ['', Validators.required],
      email: ['', Validators.required],
      full_name: ['', Validators.required],
    })

    this.formPassword = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', Validators.required, Validators.pattern(StrongPasswordRegx)],
    })

    this.getProfile()
  }

  async getProfile() {
    (await this.apiProfile.getProfile())
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.data = v.result
      },
      error: (e) => { 
        this.isLoading = false
        if(e.error == 'Unauthorized.')
          localStorage.clear()
          window.location.assign(environment.urlIndex)
      },
      complete: () => { 
        this.isLoading = false
      }
    })
  }

  async send() {
    this.isLoadingButton = false;

    var formData: any = new FormData();
    formData.append('phone_number', this.form.get('phone_number')?.value);
    formData.append('email', this.form.get('email')?.value);
    formData.append('fullname', this.form.get('full_name')?.value);
    
    (await this.apiProfile.putProfile(formData))
    .subscribe({
      next: (response) => {
        this.isLoadingButton = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        });
        this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoadingButton = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        });
      },
    })
  }

  async sendReset() {
    this.isLoadingButtonReset = false;

    var formData: any = new FormData();
    formData.append('old_password', this.formPassword.get('old_password')?.value);
    formData.append('new_password', this.formPassword.get('new_password')?.value);
    
    (await this.apiProfile.putProfilePassword(formData))
    .subscribe({
      next: (response) => {
        this.isLoadingButtonReset = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        });
        this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoadingButtonReset = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        });
      },
    })
  }

  async resend() {
    this.isLoadingButtonResend = false;

    var formData: any = new FormData();
    formData.append('email', this.data.email);
    
    (await this.apiProfile.resendEmail(formData))
    .subscribe({
      next: (response) => {
        this.isLoadingButtonResend = true
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        });
        this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoadingButtonResend = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        });
      },
    })
  }

  showHidePasswordOld() {
    if(this.showPasswordOld == 'password') {
      this.showPasswordOld = 'text'
    } else {
      this.showPasswordOld = 'password'
    }
  }

  showHidePassword() {
    if(this.showPassword == 'password') {
      this.showPassword = 'text'
    } else {
      this.showPassword = 'password'
    }
  }

  get passwordFormField() {
    return this.formPassword.get('new_password');
  }

  ngAfterContentChecked(): void {
    const hp = this.form.value.email == null ? '' : this.form.value.email == '' ? '' : this.form.value.email
    const pn = this.form.value.phone_number == null ? '' : this.form.value.phone_number == '' ? '' : this.form.value.phone_number
    const fn = this.form.value.full_name == null ? '' : this.form.value.full_name == '' ? '' : this.form.value.full_name
    if(hp == '' || pn == '' || fn == '') {
      this.isButton = true
    } else {
      this.isButton = false
    }

    const opw = this.formPassword.value.old_password == null ? '' : this.formPassword.value.old_password == '' ? '' : this.formPassword.value.old_password
    const npw = this.formPassword.value.new_password == null ? '' : this.formPassword.value.new_password == '' ? '' : this.formPassword.value.new_password
    if(opw == '' || !this.passwordFormField?.value?.match(StrongPasswordRegx)) {
      this.isButtonReset = true
    } else {
      this.isButtonReset = false
    }
  }
}

export const StrongPasswordRegx: RegExp =
  /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;