import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-image-promotion',
  templateUrl: './image-promotion.component.html',
  styleUrls: ['./image-promotion.component.css']
})
export class ImagePromotionComponent implements OnInit {
  message!: any
  file!: any
  isButton!: boolean

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ImagePromotionComponent>) { 
    this.message = data['message'];
    this.file = data['file'];
    this.isButton = data['isButton'];
  }

  ngOnInit(): void {
  }
}
