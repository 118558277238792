<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label style="font-size: 20px;font-weight: 700;">{{title | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<div mat-dialog-content>
    <p style="font-size: 16px;font-weight: 400;color: #555555;">
        {{description | translate : {name: name} }}
    </p>
</div>
<mat-dialog-actions style="display: flex;justify-content: center;" [ngStyle]="{'display': appServiceScreenMobile ? 'flex' : '', 'align-items': appServiceScreenMobile ? 'center' : '', 'margin': appServiceScreenMobile ? '' : '0px 20px 20px 20px'}">
    <app-loading *ngIf="isLoading == false; else hideLoading"></app-loading>
    <ng-template #hideLoading>
        <div class="col-6">
            <button (click)="closeBottomSheet()" [mat-dialog-close]="true" style="margin: -5px;width: 100%;border-radius: 8px;background-color: black;font-size: 16px;font-weight: 700;color: white;" mat-button>{{no | translate}}</button>
        </div>
        <div class="col-6">
            <button (click)="callDeleted()" style="margin: 5px;width: 100%;border-radius: 8px;background-color: white;font-size: 16px;font-weight: 700;color: #b8505b;border: 1px solid #DDDDDD;" mat-button>{{yes | translate}}</button>
        </div>
    </ng-template>
</mat-dialog-actions>
