<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;" *ngIf="isNew == false; else back">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'Choose_address' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
    <ng-template #back>
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <label *ngIf="isNew == true" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">
                <button (click)="back_()" mat-icon-button aria-label="back"><i class="bi bi-arrow-left-short"></i></button>{{title | translate}}
            </label>
            <button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
        </div>
    </ng-template>
</h2>
<form [formGroup]="form" (ngSubmit)="send(title)">
    <div mat-dialog-content style="text-align: center;">
        <app-loading *ngIf="isLoading == false; else hideLoading"></app-loading>
        <ng-template #hideLoading>
            <div *ngIf="isNew == true; else list">
                <div [ngClass]="{'row': appServiceScreenDesktop}" style="text-align: left;">
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Label_address' | translate}}</label>
                        <div [ngStyle]="{'display': appServiceScreenMobile || appServiceScreenMobileSmall ? 'flex' : ''}">
                            <button mat-stroked-button [ngClass]="{'bg': label == 'house'}" (click)="selectLabel('house')" type="button" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px', 'height': appServiceScreenMobileSmall || appServiceScreenMobile ? '50px' : ''}" style="margin: 0 5px;border-radius: 8px;"><i class="bi bi-house-door"></i> {{'house' | translate}}</button>
                            <button mat-stroked-button [ngClass]="{'bg': label == 'office'}" (click)="selectLabel('office')" type="button" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px', 'height': appServiceScreenMobileSmall || appServiceScreenMobile ? '50px' : ''}" style="margin: 0 5px;border-radius: 8px;"><i class="bi bi-building"></i> {{'office' | translate}}</button>
                            <button mat-stroked-button [ngClass]="{'bg': label == 'apartment'}" (click)="selectLabel('apartment')" type="button" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px', 'height': appServiceScreenMobileSmall || appServiceScreenMobile ? '50px' : ''}" style="margin: 0 5px;border-radius: 8px;"><i class="bi bi-bank"></i> {{'apartment' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'address' | translate}}</label>
                        <input formControlName="address" type="text" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="Example: Jln Seni Bangsa no.90">
                    </div>
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'note_address' | translate}}</label>
                        <textarea formControlName="note" type="text" style="border-radius: 8px;" class="form-control" rows="2" cols="10"></textarea>
                    </div>
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Province' | translate}}</label>
                        <mat-select style="border: 1px solid #ced4da;border-radius: 8px;padding: 12px;" [formControl]="provinceName" placeholder="{{hintProvince == null ? ('Provinces' | translate) : hintProvince}}" #selectProvince required>
                            <mat-option>
                              <ngx-mat-select-search [disableInitialFocus]="true" placeholderLabel="Find Province..." noEntriesFoundLabel="no matching province found"
                                                     [formControl]="provinceFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let province of filteredProvinces | async" [value]="province">
                              {{province}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'City' | translate}}</label>
                        <mat-select style="border: 1px solid #ced4da;border-radius: 8px;padding: 12px;" [formControl]="city" placeholder="{{hintCity == null ? ('City' | translate) : hintCity}}" #selectCity required>
                            <mat-option>
                              <ngx-mat-select-search [disableInitialFocus]="true" placeholderLabel="Find City..." noEntriesFoundLabel="no matching city found"
                                                     [formControl]="cityFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let city of filteredCitys | async" [value]="city">
                              {{city}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'District' | translate}}</label>
                        <mat-select style="border: 1px solid #ced4da;border-radius: 8px;padding: 12px;" [formControl]="district" placeholder="{{hintDistrict == null ? ('District' | translate) : hintDistrict}}" #selectDistrict required>
                            <mat-option>
                              <ngx-mat-select-search [disableInitialFocus]="true" placeholderLabel="Find District..." noEntriesFoundLabel="no matching district found"
                                                     [formControl]="districtFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let district of filteredDistricts | async" [value]="district.district">
                              {{district.district}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-12" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Sub_district' | translate}}</label>
                        <mat-select style="border: 1px solid #ced4da;border-radius: 8px;padding: 12px;" [formControl]="sub_district" placeholder="{{hintSubDistrict == null ? ('Sub_district' | translate) : hintSubDistrict}}" #selectSubDistrict required>
                            <mat-option>
                              <ngx-mat-select-search [disableInitialFocus]="true" placeholderLabel="Find Sub District..." noEntriesFoundLabel="no matching sub district found"
                                                     [formControl]="subDistrictFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let subdistrict of filteredSubDistricts | async" [value]="subdistrict">
                              {{subdistrict.subdistrict}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <input formControlName="postal_code" type="text" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;display: none;" class="form-control">
                    <input formControlName="destination_code" type="text" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;display: none;" class="form-control">
                    <div class="col-12" *ngIf="title != 'Edit_new_address'" [ngStyle]="{'margin-bottom': appServiceScreenMobileSmall ? '10px' : '20px'}">
                        <section>
                            <mat-checkbox (change)="checkListDefault($event)" [checked]="isDefault">{{'Main_address' | translate}}</mat-checkbox>
                        </section>
                    </div>
                </div>
            </div>
            <ng-template #list>
                <div *ngIf="dataModel.length > 0; else dataNotFound">
                    <button mat-button (click)="addNew('Add_new_address')" [ngStyle]="{'width': appServiceScreenMobile ? '100%' : '100%', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="border-radius: 8px;background-color: transparent;font-weight: 700;color: #0e8667;border: 1px solid #DDDDDD;"><i style="color: #0e8667;" class="bi bi-plus-circle-fill"></i> {{'Add_new_address' | translate}}</button>
                    <div *ngFor="let row of dataModel;let indexElementArray = index;" [ngStyle]="{'padding': appServiceScreenMobileSmall ? '10px' : '15px'}" style="display: flex;justify-content: space-between;align-items: center;background-color: #FAFAFA;margin-top: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;">
                        <div style="display: flex;flex-direction: column;align-items: flex-start;text-align: left;">
                            <label [ngStyle]="{'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '10px' : '12px' : '16px'}" style="font-weight: 700;">
                                <i *ngIf="row.label != null" class="bi bi-{{row.label == 'HOUSE' ? 'house-door' : row.label == 'OFFICE' ? 'building' : row.label == 'APARTMENT' ? 'bank' : ''}}"></i> {{row.address}} <label *ngIf="row.default == true" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px'}" style="padding: 0px 10px;color: #0e8667;background-color: #EDFDF9;font-weight: 700;border-radius: 10px;">{{'Main_address' | translate}}</label>
                            </label>
                            <label [ngStyle]="{'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '10px' : '12px' : '14px'}" style="font-weight: 400;text-align: start;">{{row.province}}, {{row.city}}, {{row.district}}, <br> {{row.sub_district}}, {{row.postal_code}}</label>
                            <p [ngStyle]="{'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '10px' : '12px' : '14px'}" style="font-weight: 700;margin-bottom: 0;">
                                <label (click)="addNew('Edit_new_address', dataModel[indexElementArray])" style="color: #0e8667;cursor: pointer;">{{'Edit' | translate}}</label>&nbsp;|&nbsp;<label (click)="deleteData(row.id)" style="color: #b8505b;cursor: pointer;">{{'Delete' | translate}}</label><span *ngIf="row.default == false">&nbsp;|&nbsp;<label (click)="setDefaultData(row.id)" style="color: #0e8667;cursor: pointer;">{{'set_as_main_address' | translate}}</label></span>
                            </p>
                        </div>
                        <button (click)="setAddressTemp(dataModel[indexElementArray].id)" [disabled]="dataAddress == dataModel[indexElementArray].id || dataModel.length == 1" [ngStyle]="{'background-color': dataAddress == dataModel[indexElementArray].id || dataModel.length == 1 ? 'white' : '#0e8667', 'color': dataAddress == dataModel[indexElementArray].id || dataModel.length == 1 ? '#DDDDDD' : 'white', 'font-size': appServiceScreenMobile ? appServiceScreenMobile ? '10px' : '12px' : '14px'}" style="background-color: #0e8667;color: white;border-radius: 8px;font-weight: 700;border: 1px solid #DDDDDD;margin: 0px 10px;" mat-button>
                            <mat-label *ngIf="dataAddress == dataModel[indexElementArray].id || dataModel.length == 1; else select">{{'Selected' | translate}}</mat-label>
                            <ng-template #select>
                                <mat-label>{{'Select' | translate}}</mat-label>
                            </ng-template>
                        </button>
                    </div>
                </div>
                <ng-template #dataNotFound>
                    <img alt="chatfoto" ngSrc="../../../../assets/img/address-not-found.png" width="192" height="120">
                    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'No_addresses_added_yet' | translate}}</p>
                    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #555555;">{{'You_havent_added_any_address_yet' | translate}} <br> {{'Start_by_adding_your_address_below' | translate}}</p>
                    <button mat-button (click)="addNew('Add_new_address')" type="button" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="width: 100%;border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;margin: 30px 0px 20px 0px;"><i style="color: white;" class="bi bi-plus-circle-fill"></i> {{'Add_new_address' | translate}}</button>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
    <mat-dialog-actions *ngIf="isNew == true" style="justify-content: center;">
        <button *ngIf="isLoadingSave else hideLoadingSave" [disabled]="isButton" [ngStyle]="{'margin': appServiceScreenMobile ? '0 0 20px 0' : '20px', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="width: 100%;border-radius: 8px;background-color: #0e8667;font-weight: 700;color: white;" mat-button>{{'Save' | translate}}</button>
        <ng-template #hideLoadingSave>
            <app-loading></app-loading>
        </ng-template>
    </mat-dialog-actions>
</form>
