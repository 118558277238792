import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  isHomeWhy!: boolean;
  data!: Results[];
  sliderArrowShow: boolean = false;
  sliderInfinite: boolean = true;
  sliderImagePopup: boolean = false;
  sliderAutoSlide: boolean = true;
  sliderSlideImage: Number = 1;
  sliderAnimationSpeed: any = 1;
  slideOrderType:string = 'DESC';

  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;

  constructor(private appService: AppService, private translate: TranslateService) {
    this.data = DATA;
    
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.appService.setIsPrintMobile(false)

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en');
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
    }
  }
  
  ngOnInit(): void {
    this.appService.setIsHomeWhy(false)
    this.appService.setIsFooterMobileBottom('')
  }
}

export interface Results {
  id: number
  thumbImage: string
}

export const DATA: Results[] = [
  {id: 1, thumbImage: 'https://dmmrnd.id/image1.webp'},
  {id: 2, thumbImage: 'https://dmmrnd.id/image2.webp'},
  {id: 3, thumbImage: 'https://dmmrnd.id/image3.webp'},
  {id: 4, thumbImage: 'https://dmmrnd.id/image2.webp'},
  {id: 5, thumbImage: 'https://dmmrnd.id/image1.webp'},
  {id: 5, thumbImage: 'https://dmmrnd.id/image3.webp'},
];
