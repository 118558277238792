<div class="container" [ngStyle]="{'width': appServiceScreenDesktop ? '80%' : ''}">
    <div>
        <p *ngIf="dataVoucher != undefined ? dataVoucher.length : 0 > 0" [ngStyle]="{'font-size': appServiceScreenDesktop ? '24px' : appServiceScreenMobileSmall ? '16px' : '20px', 'margin-top': appServiceScreenMobileSmall ? '90px' : '110px', 'margin-bottom': appServiceScreenMobileSmall ? '20px' : '30px'}" style="color: #222222;font-weight: 700;">{{'Check_out_this_special_offers' | translate}}</p>
        <div style="overflow: auto;white-space: nowrap;display: flex;">
            <div *ngIf="isLoadingVoucher == false; else hideLoadingVoucher" style="display: flex;">
                <div *ngFor="let row of dataVoucher" [ngStyle]="{'width': appServiceScreenDesktop ? '354px' : appServiceScreenMobileSmall ? '200px' : '250px', 'margin-right': appServiceScreenMobileSmall ? '10px' : '20px', 'height': appServiceScreenMobileSmall ? '80px' : '100px', 'padding': appServiceScreenMobileSmall ? '10px' : '15px'}" style="background-repeat: no-repeat;background-image: url(../../../../../assets/img/voucher-background.webp);border: 1px solid #E8E8E8;border-radius: 12px;display: flex;justify-content: space-between;align-items: center;">
                    <div style="white-space: nowrap;overflow: hidden;padding-right: 10px;">
                        <label [ngStyle]="{'font-size': appServiceScreenDesktop ? '12pt' : appServiceScreenMobileSmall ? '11pt' : '12pt'}" style="color: #222222;font-weight: 700;white-space: normal;text-align: start;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;display: -webkit-box;">{{lang == 'id' ? row.translations.id.name : row.translations.en.name}}</label>
                        <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '10pt' : appServiceScreenMobileSmall ? '9pt' : '10pt'}" style="color: #717171;font-weight: 400;white-space: normal;text-align: start;overflow: hidden;-webkit-line-clamp: 2;-webkit-box-orient: vertical;display: -webkit-box;margin: 0px;">{{lang == 'id' ? row.translations.id.description : row.translations.en.description}}</p>
                    </div>
                    <div>
                        <button (click)="callDetail(row.terms_and_conditions)" [ngStyle]="{'font-size': appServiceScreenDesktop ? '12px' : '10px', 'height': appServiceScreenMobileSmall ? '25px' : ''}" type="button" style="border-radius: 8px;font-weight: 700;color: #0e8667;border: 2px solid #DDDDDD;" mat-button>{{'Promo_details' | translate}}</button>
                    </div>
                </div>
            </div>
            <ng-template #hideLoadingVoucher>
                <div style="display: flex;justify-content: center;width: 100%;">
                    <app-loading></app-loading>
                </div>
            </ng-template>
        </div>
    </div>
    <div>
        <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '24px' : appServiceScreenMobileSmall ? '16px' : '20px', 'margin-top': appServiceScreenMobileSmall ? '30px' : '50px', 'margin-bottom': appServiceScreenMobileSmall ? '20px' : '30px'}" style="color: #222222;font-weight: 700;">{{'Our_Product' | translate}}</p>
        <div *ngIf="isLoading == false; else hideLoading" [ngClass]="{'row row-cols-3': appServiceScreenDesktop}">
            <div *ngFor="let row of data" [ngClass]="{'col': appServiceScreenDesktop, 'col-sm': appServiceScreenMobile}">
                <div class="card">
                    <img alt="chatfoto" class="card-img-top" style="border-top-left-radius: 16px;border-top-right-radius: 16px;object-fit: cover;" width="250" height="auto" ngSrc="{{row.image_big_url}}" alt="Card image cap">
                    <div style="padding: 10px;text-align: left;">
                      <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #222222;">{{lang == 'id' ? row.translation.id.name : row.translation.en.name}}</label>
                      <p [ngStyle]="{'height': row.description != null ? '' : '36px', 'font-size': appServiceScreenMobileSmall ? '10px' : '12px'}" style="font-weight: 400;color: #717171;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">{{lang == 'id' ? row.translation.id.description : row.translation.en.description}}</p>
                    </div>
                    <div style="display: flex;justify-content: space-between;padding: 0px 10px 10px 10px;align-items: center;">
                        <div style="display: flex;justify-content: space-between;padding: 0px 10px 10px 10px;align-items: start;flex-direction: column;">
                            <label [ngStyle]="{'height': row.price_strikethrough != null ? '' : '18px', 'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 700;text-decoration: line-through;color: #CCCCCC;">{{(row.price_strikethrough| currency:'Rp ':'symbol':'3.0')?.replace(',', '.')}}</label>
                            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;color: #0e8667;">{{(row.price| currency:'Rp ':'symbol':'3.0')?.replace(',', '.')}}<label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;">/pc</label></label>
                        </div>
                        <button routerLink="/photo/{{row.id}}/false/true/false" [ngStyle]="{'height': appServiceScreenMobileSmall ? '30px' : '36px'}" style="width: 128px;border-radius: 8px;background-color: #0e8667;font-size: 14px;font-weight: 700;color: white;" mat-button>{{'Order' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #hideLoading>
            <app-loading></app-loading>
        </ng-template>
    </div>
</div>