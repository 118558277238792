<div class="container" [ngStyle]="{'width': appServiceScreenDesktop ? '80%' : '100%'}">
    <app-loading *ngIf="isLoading; else hideLoading"></app-loading>
    <ng-template #hideLoading>
        <div [ngClass]="{'row': appServiceScreenDesktop}" [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '80px' : '110px'}">
            <p [ngStyle]="{'font-size': appServiceScreenMobile ? '16px' : '24px', 'margin-bottom': appServiceScreenMobileSmall ? '20px' : '30px'}" style="color: #222222;font-weight: 700;">{{'account_settings' | translate}}</p>
            <p [ngStyle]="{'font-size': appServiceScreenMobile ? '14px' : '18px', 'margin-bottom': appServiceScreenMobileSmall ? '20px' : '30px'}" style="color: #222222;font-weight: 700;">{{'profile' | translate}}</p>
            <form [formGroup]="form" (ngSubmit)="send()">
                <div class="row">
                    <div class="col-12" style="margin-bottom: 20px;">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Full_name' | translate}}</label>
                        <input formControlName="full_name" type="text" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="{{data.full_name}}">
                    </div>
                    <div class="col-sm" style="margin-bottom: 20px;">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Email_address' | translate}}</label>
                        <input formControlName="email" type="email" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="{{data.email}}">
                        <p *ngIf="data.email_verified_at == null || isLoadingButtonResend == true">{{'greating_mail' | translate}} <u (click)="resend()" style="cursor: pointer;color: #0e8667;">{{'resend_link' | translate}}</u></p>
                        <app-loading *ngIf="isLoadingButtonResend == false"></app-loading>
                    </div>
                    <div class="col-sm" style="margin-bottom: 20px;">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Phone_number' | translate}}</label>
                        <input formControlName="phone_number" type="number" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-radius: 8px;" class="form-control" placeholder="{{data.phone_number}}">
                    </div>
                    <div class="col-12" style="text-align: center;">
                        <button *ngIf="isLoadingButton else hideLoadingButton" [disabled]="isButton" [ngStyle]="{'width': appServiceScreenDesktop ? '230px' : '100%', 'height': appServiceScreenMobileSmall ? '36px' : '56px', 'background-color': isButton ? '#CCCCCC' : '#0e8667'}" style="border-radius: 8px;font-weight: 700;color: white;padding: 5px 25px;" mat-button>{{'Update' | translate}}</button>
                        <ng-template #hideLoadingButton>
                            <app-loading></app-loading>
                        </ng-template>
                    </div>
                </div>
            </form>
            <p [ngStyle]="{'font-size': appServiceScreenMobile ? '14px' : '18px', 'margin': appServiceScreenMobileSmall ? '20px 0px' : '30px 0px'}" style="color: #222222;font-weight: 700;">{{'reset_password' | translate}}</p>
            <form [formGroup]="formPassword" (ngSubmit)="sendReset()">
                <div class="row">
                    <div class="col-sm" style="margin-bottom: 20px;">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'old_password' | translate}}</label>
                        <div class="input-group">
                            <input formControlName="old_password" type="{{showPasswordOld}}" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-right: 1px;border-bottom-left-radius: 8px;border-top-left-radius: 8px;" class="form-control" placeholder="Example: P4ssw0rd">
                            <div (click)="showHidePasswordOld()" class="input-group-btn" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-bottom-right-radius: 8px;border-top-right-radius: 8px;border: 1px solid #ced4da;display: flex;justify-content: center;align-items: center;">
                                <span class="btn btn-default">
                                  <i [ngClass]="{'bi bi-eye-slash': showPasswordOld == 'password', 'bi bi-eye': showPasswordOld == 'text'}"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm" style="margin-bottom: 20px;">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'new_password' | translate}}</label>
                        <div class="input-group">
                            <input formControlName="new_password" type="{{showPassword}}" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-right: 1px;border-bottom-left-radius: 8px;border-top-left-radius: 8px;" class="form-control" placeholder="Example: P4ssw0rd">
                            <div (click)="showHidePassword()" class="input-group-btn" [ngStyle]="{'height': appServiceScreenMobileSmall ? '38px' : '48px'}" style="border-bottom-right-radius: 8px;border-top-right-radius: 8px;border: 1px solid #ced4da;display: flex;justify-content: center;align-items: center;">
                                <span class="btn btn-default">
                                  <i [ngClass]="{'bi bi-eye-slash': showPassword == 'password', 'bi bi-eye': showPassword == 'text'}"></i>
                                </span>
                            </div>
                        </div>
                        <div [ngStyle]="{'margin-bottom': appServiceScreenMobile ? '20px' : '0px'}" class="text-red-400 text-sm">
                            <span style="padding-top: 5px;font-size: small;color: #747474;">Password must contain more than <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('.{8,}')}">8 characters</label>, <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('^(?=.*[A-Z])')}">1 upper case letter</label>, <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('(?=.*[a-z])')}">1 lower case letter</label>, <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('(.*[0-9].*)')}">1 digit number</label> and <label [ngClass]="{'text-secondary': passwordFormField?.value?.match('(?=.*[!@#$%^&*])')}">1 special character</label></span>
                        </div>
                    </div>
                    <div class="col-12" style="text-align: center;">
                        <button *ngIf="isLoadingButtonReset else hideLoadingButtonReset" [disabled]="isButtonReset" [ngStyle]="{'width': appServiceScreenDesktop ? '230px' : '100%', 'height': appServiceScreenMobileSmall ? '36px' : '56px', 'background-color': isButtonReset ? '#CCCCCC' : '#0e8667'}" style="border-radius: 8px;font-weight: 700;color: white;padding: 5px 25px;" mat-button>{{'Update' | translate}}</button>
                        <ng-template #hideLoadingButtonReset>
                            <app-loading></app-loading>
                        </ng-template>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>
</div>