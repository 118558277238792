import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { LoginComponent } from '../../pages/dialog/login/login.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';
import { environment } from 'src/environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  flag!: string;
  isLogin: boolean;
  fullName!: string | null;
  destroyed = new Subject<void>();
  appServiceScreenMobileSmall!: boolean;

  constructor(public router: Router, public appService: AppService, private bottomSheet: MatBottomSheet, private translate: TranslateService, private apiLogin: ApiLogin) { 
    if(localStorage.getItem('my') == undefined && localStorage.getItem('key')) {
      this.isLogin = false
    } else {
      this.isLogin = apiLogin.isLoggedIn()
      let retrievedObject = localStorage.getItem('my')
      let results = JSON.parse(retrievedObject!)
      this.fullName = results?.result['full_name']
    }

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en');
      this.flag = 'en'
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
      this.flag = 'id'
    }

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })
  }

  clickMenu(path: String) {
    this.appService.sideBar();
    this.router.navigate([path]);
  }

  callLogin() {
    this.appService.sideBar();
    this.bottomSheet.open(LoginComponent, { disableClose: false })
  }

  callLogout() {
    localStorage.clear()
    window.location.assign(environment.urlIndex)
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language)
    if(language == 'id') {
      this.flag = 'id'
    } else {
      this.flag = 'en'
    }
  }

}
