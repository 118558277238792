import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin) { }

  public showDelivery(addressId: number) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    let params = new HttpParams()
      params = params.append("address_id", addressId);
    return this.httpClient.get(`${this.baseUrl}delivery`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }

  public setDelivery(deliveryId: number, deliveryType: any) {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    const data = JSON.stringify({
        "delivery_id": deliveryId,
        "delivery_type": deliveryType
      })
    return this.httpClient.post<any>(`${this.baseUrl}delivery-select`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
