import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DetailComponent } from './detail/detail.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService as ApiVoucher } from 'src/app/services/voucher/api.service';
import { VoucherModel } from 'src/app/models/voucher-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  isLoadingVoucher: boolean = true;
  isLoadingCheck: boolean = true;
  isLoadingCheckProses: boolean = true;
  dataVoucher!: VoucherModel.Result[];
  dataCheckVoucher!: VoucherModel.Result;
  form!: FormGroup;
  isButton: boolean = true;
  isLoading: boolean = true;
  setMessage: string = 'Voucher Not Found';
  setMessagePromotion!: string;
  selectVoucher!: VoucherModel.Result;
  
  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<VoucherComponent>,
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<VoucherComponent>,
    private bottomSheet: MatBottomSheet,
    public fb: FormBuilder,
    private apiVoucher: ApiVoucher,
    public appService: AppService
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.getVoucher()

    this.form = this.fb.group({
      code: ['', Validators.required],
    });
  }

  callDetail(tnc: any) {
    if(this.appServiceScreenMobile) {
      this.bottomSheetRef.dismiss()
      this.bottomSheet.open(DetailComponent, { data: {isClose: true, tnc: tnc} })
    } else {
      this.dialogRef.close()
      this.dialog.open(DetailComponent, { data: {isClose: true, tnc: tnc} })
    }
  }

  selectedVoucher(data: VoucherModel.Result) {
    this.selectVoucher = data
  }

  async setVoucher() {
    this.isLoading = false;
    
    var formData: any = new FormData();
    formData.append('voucher_id', this.selectVoucher.id);
    
    (await (this.apiVoucher.setVoucher(formData)))
    .subscribe({
      next: (response) => {
        this.isLoading = true

        if(this.appServiceScreenMobile) {
          this.bottomSheetRef.dismiss()
        } else {
          this.dialogRef.close()
        }

        this.appService.reloadPage(true)
      },
      error: (error) => {
        this.isLoading = true
      },
    })
  }

  async getVoucher() {
    (await this.apiVoucher.getVoucher(true))
    .subscribe({
      next: (v) => {
        this.isLoadingVoucher = false
        this.setMessagePromotion = v.message
        this.dataVoucher = v.results
      },
      error: (e) => {
        this.isLoadingVoucher = false
        this.dataVoucher = []
      },
      complete: () => {
        this.isLoadingVoucher = false
      }
    });
  }

  async checkVoucher() {
    this.isLoadingCheck = false;
    this.isLoadingCheckProses = true;

    (await this.apiVoucher.checkVoucher(this.form.get('code')?.value))
    .subscribe({
      next: (v) => {
        if(v.code == 200) {
          this.isLoadingCheckProses = false
          this.isLoadingCheck = false
          this.dataCheckVoucher = v.result
        } else {
          this.isLoadingCheckProses = true
          this.isLoadingCheck = true
          this.setMessage = v.message
        }
      },
      error: (e) => {
        this.isLoadingCheckProses = true
        this.isLoadingCheck = true
        this.setMessage = e.message
      },
      complete: () => { }
    });
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }

  ngAfterContentChecked(): void {
    const cd = this.form.value.code == null ? '' : this.form.value.code == '' ? '' : this.form.value.code;

    if(cd == '') {
      this.isButton = true;
    } else {
      this.isButton = false;
    }
  }

  
}
