import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient) { }

  public postLogin(formData: FormData) {
    return this.httpClient.post<any>(`${this.baseUrl}login`, formData)
      .pipe(map(response => {
        this.setToken(response.result.token);
        return response;
      }));
  }

  public postCheckUser(formData: FormData) {
    return this.httpClient.post<any>(`${this.baseUrl}check`, formData)
      .pipe(map(response => {
        return response;
      }));
  }

  public forgotPassword(formData: FormData) {
    const httpHeaders = new HttpHeaders();
    return this.httpClient.post<any>(`${this.baseUrl}send-forgot-password`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public callProfile(token: string) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.get<any>(`${this.baseUrl}profile`, { headers: httpHeaders })
      .pipe(map(response => {
        this.setProfile(JSON.stringify(response));
        return response;
      }));
  }

  setProfile(token: string) {
    localStorage.setItem('my', token);
  }

  getProfile() {
    return localStorage.getItem('my');
  }

  deleteToken() {
    localStorage.removeItem('key');
    localStorage.clear();
  }

  setToken(token: string) {
    localStorage.setItem('key', token);
  }

  getToken() {
    return localStorage.getItem('key');
  }

  isLoggedIn() {
    const usertoken = this.getToken();
    return usertoken != null;
  }
}
