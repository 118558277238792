import { ChangeDetectorRef, Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService as ApiPhoto } from 'src/app/services/photo/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.css']
})
export class UploadPhotoComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  selectedImages!: FileList;
  isLoading: boolean = true;
  myFiles:Array<any> = [];
  progress: number = 0;
  isUpload: boolean = false;


  constructor (private translate: TranslateService, private activedRoute: ActivatedRoute, private apiPhoto: ApiPhoto, private router: Router, private cd: ChangeDetectorRef, public appService: AppService, private snackBar: MatSnackBar) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en');
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
    }
  }

  selectFile(event: any) {
    this.isLoading = false
    
    for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i])
    }

    const formData = new FormData();
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i])
    }
    formData.append('photo_size_id', this.activedRoute.snapshot.params['id']);

    this.apiPhoto.uploadFoto(formData, true, '')
    this.apiPhoto.getIsProgress().subscribe(appIsProgress => this.progress = appIsProgress)
    this.apiPhoto.getIsUpload().subscribe(appIsUpload => this.isUpload = appIsUpload)
    this.cd.detectChanges()
	}
  
}
