import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, interval, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { LoginComponent } from '../login/login.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService as ApiRegister } from 'src/app/services/register/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TncComponent } from '../tnc/tnc.component';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  form!: FormGroup;
  isButton: boolean = true;
  isLoading: boolean = true;
  passwordStatus: string = '';
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  showPassword: string = 'password';
  dataPhone!: string;
  hold: boolean = false;
  url!: string;

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<RegistrationComponent>,
    private bottomSheetRef: MatBottomSheetRef<RegistrationComponent>,
    appService: AppService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    public fb: FormBuilder,
    private apiRegister: ApiRegister,
    private apiLogin: ApiLogin,
    private snackBar: MatSnackBar
  ) {
    this.form = this.fb.group({
      phone_number: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required, Validators.pattern(StrongPasswordRegx)],
      full_name: ['', Validators.required],
    })
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA)
    
      this.dataPhone = this.data == undefined ? '' : this.data['phone_number']
      this.hold = this.data == undefined ? false : this.data['disabled']
      this.url = this.data == undefined ? '' : this.data['returnUrl'];
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA)
    
      this.dataPhone = this.dataMobile == undefined ? '' : this.dataMobile['phone_number']
      this.hold = this.dataMobile == undefined ? false : this.dataMobile['disabled']
      this.url = this.dataMobile == undefined ? '' : this.dataMobile['returnUrl'];
    }

    console.log(this.url)

    if(this.hold) {
      this.form.get('phone_number')?.setValue(this.dataPhone)
      this.form.controls['phone_number'].disable();
    } else {
      this.form.controls['phone_number'].enable();
    }
  }

  callLogin() {
    if(this.appServiceScreenMobile) {
      this.bottomSheetRef.dismiss()
      this.bottomSheet.open(LoginComponent, { disableClose: false });
    } else {
      this.dialogRef.close()
      this.dialog.open(LoginComponent, { disableClose: false })
    }
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }

  get passwordFormField() {
    return this.form.get('password');
  }

  showHidePassword() {
    if(this.showPassword == 'password') {
      this.showPassword = 'text'
    } else {
      this.showPassword = 'password'
    }
  }

  send() {
    this.isLoading = false;

    var formData: any = new FormData()
    formData.append('phone_number', this.form.get('phone_number')?.value)
    formData.append('email', this.form.get('email')?.value)
    formData.append('password', this.form.get('password')?.value)
    formData.append('full_name', this.form.get('full_name')?.value)
    formData.append('token', '-')
    
    this.apiRegister.postRegistration(formData)
    .subscribe({
      next: (response) => {
        this.isLoading = true;
        this.snackBar.open(response.message, '', {
          duration: 10 * 1000,
          panelClass: ['success-snackbar']
        });

        this.profile(response.result.token)
      },
      error: (error) => {
        this.isLoading = true;
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        });
      },
    })
  }

  profile(token: string) {
    this.apiLogin.callProfile(token)
    .subscribe({
      next: (response) => {
        if(this.appServiceScreenMobile) {
          this.bottomSheetRef.dismiss()
        } else {
          this.dialogRef.close()
        }

        window.location.assign(environment.urlIndex + this.url)
      },
      error: (error) => {
        this.isLoading = true
      },
    })
  }

  callDetail() {
    let tnc = "Dengan mendaftar di ChatFoto, pengguna setuju memberikan informasi akurat, menjaga keamanan akun dan kata sandi, serta bertanggung jawab atas semua aktivitas di akun tersebut."
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(TncComponent, { data: {isClose: true, tnc: tnc} })
    } else {
      this.dialog.open(TncComponent, { data: {isClose: true, tnc: tnc} })
    }
  }

  ngAfterContentChecked(): void {
    const hp = this.form.value.email == null ? '' : this.form.value.email == '' ? '' : this.form.value.email;
    const pw = this.form.value.password == null ? '' : this.form.value.password == '' ? '' : this.form.value.password;
    const pn = this.form.value.phone_number == null ? '' : this.form.value.phone_number == '' ? '' : this.form.value.phone_number;
    const fn = this.form.value.full_name == null ? '' : this.form.value.full_name == '' ? '' : this.form.value.full_name;

    if(hp == '' || !this.passwordFormField?.value?.match(StrongPasswordRegx) || fn == '') {
      this.isButton = true;
    } else {
      this.isButton = false;
    }
  }
}

export const StrongPasswordRegx: RegExp =
  /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
