import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient) { }

  public postRegistration(formData: FormData) {
    return this.httpClient.post<any>(`${this.baseUrl}register`, formData)
      .pipe(map(response => {
        this.setToken(response.result.token);
        return response;
      }));
  }

  setToken(token: string) {
    localStorage.setItem('key', token);
  }

  getToken() {
    return localStorage.getItem('key');
  }
}
