import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService as ApiNotification } from 'src/app/services/notification/api.service';
import { ImagePromotionComponent } from '../dialog/image-promotion/image-promotion.component';
import { MessageComponent } from '../dialog/message/message.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isHomeWhy!: boolean;
  dataHowTo!: Results[];
  sliderArrowShow: boolean = false;
  sliderInfinite: boolean = true;
  sliderImagePopup: boolean = false;
  sliderAutoSlide: boolean = true;
  sliderSlideImage: Number = 1;
  sliderAnimationSpeed: any = 1;
  slideOrderType:string = 'DESC';
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  destroyed = new Subject<void>();
  appServiceScreenMobileSmall!: boolean;

  constructor(private appService: AppService, private translate: TranslateService, private apiNotification: ApiNotification, private dialog: MatDialog) {
    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en');
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
    }

    this.dataHowTo = DATAHOWTO;

    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })
  }
  ngOnInit(): void {
    this.appService.setIsHomeWhy(true)
    this.appService.setIsFooterMobileBottom('25%')

    // this.getNotification()
  }

  async getNotification() {
    (await this.apiNotification.getBanner())
    .subscribe({
      next: (v) => {
        if(v.results['type'] == 'promo') {
          if(v.results['voucher'] == null || v.results['voucher'] == undefined) {
            this.dialog.open(ImagePromotionComponent, {
              data: {message: v.results['voucher'], file: v.results.image_url, isButton: false}
            }); 
          } else {
            this.dialog.open(ImagePromotionComponent, {
              data: {message: v.results['voucher'], file: v.results.image_url, isButton: true}
            });
          }
        } else {
          this.dialog.open(MessageComponent, {
            data: {message: v.results['message'], page: 'home', yes: true, no: false}
          });
        }
      },
      error: (e) => {},
      complete: () => {}
    });
  }
}

export interface Results {
  id: number
  thumbImage: string
}

export const DATAHOWTO: Results[] = [
  {id: 1, thumbImage: 'https://dmmrnd.id/howtocf1.webp'},
  {id: 2, thumbImage: 'https://dmmrnd.id/howtocf2.webp'},
  {id: 3, thumbImage: 'https://dmmrnd.id/howtocf3.webp'},
  {id: 4, thumbImage: 'https://dmmrnd.id/howtocf4.webp'},
  {id: 5, thumbImage: 'https://dmmrnd.id/howtocf5.webp'},
];
