<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">{{'Voucher' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<div mat-dialog-content style="text-align: center;padding-top: 0px;">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab *ngIf="dataVoucher.length > 0" label="{{'Promo' | translate}}">
            <div *ngIf="isLoadingVoucher == false; else hideLoadingVoucher">
                <div *ngFor="let row of dataVoucher;let indexArr = index;" style="background-repeat: no-repeat;background-image: url(../../../../../assets/img/voucher-background.webp);display: flex;justify-content: space-between;align-items: center;background-color: white;margin-bottom: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;padding: 15px;margin-top: 20px;">
                    <div style="display: flex;flex-direction: column;align-items: start;padding-right: 50px;">
                        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;text-align: left;">{{row.name}}</label>
                        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;text-align: left;">{{row.description}}</label>
                        <label (click)="callDetail(row.terms_and_conditions)" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #0e8667;font-weight: 400;cursor: pointer;">{{'Check_promotion' | translate}}</label>
                    </div>
                    <mat-radio-button (change)="selectedVoucher(dataVoucher[indexArr])"></mat-radio-button>
                </div>
                <div *ngIf="dataVoucher.length == 0">
                    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px', 'padding-top': appServiceScreenMobileSmall ? '10px' : '20px'}" style="font-weight: 400;color: #717171;">{{setMessagePromotion}}</p>
                </div>
            </div>
            <ng-template #hideLoadingVoucher>
                <div style="display: flex;justify-content: center;">
                    <app-loading></app-loading>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{'Enter_code' | translate}}">
            <form [formGroup]="form" (ngSubmit)="checkVoucher()">
                <div style="display: flex;flex-wrap: wrap;text-align: left;margin-top: 20px;">
                    <div class="col-12">
                        <label class="form-label" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 700;">{{'Code_voucher' | translate}}</label>
                    </div>
                    <div class="col-12" style="display: flex;align-items: center;">
                        <input formControlName="code" type="text" style="width: 100%;border-radius: 8px;" class="form-control" placeholder="Example: CF2024">
                        <button [disabled]="isButton" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="margin-left: 10px;height: 100%;border-radius: 8px;border: 1px solid #DDDDDD;background-color: white;font-weight: 700;color: #0e8667;padding: 0px 20px;" mat-button>{{'Check' | translate}}</button>
                    </div>
                </div>
                <div *ngIf="isLoadingCheckProses == false && isLoadingCheck == false" style="background-repeat: no-repeat;background-image: url(../../../../../assets/img/voucher-background.webp);display: flex;justify-content: space-between;align-items: center;background-color: white;margin-bottom: 20px;border: 1px solid #DDDDDD;border-radius: 12px;padding: 15px;margin-top: 20px;">
                    <div style="display: flex;flex-direction: column;align-items: start;padding-right: 50px;">
                        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;text-align: left;">{{dataCheckVoucher.name}}</label>
                        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;text-align: left;">{{dataCheckVoucher.description}}</label>
                        <label (click)="callDetail(dataCheckVoucher.terms_and_conditions)" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #0e8667;font-weight: 400;cursor: pointer;">{{'Check_promotion' | translate}}</label>
                    </div>
                    <mat-radio-button (change)="selectedVoucher(dataCheckVoucher)"></mat-radio-button>
                </div>
                <div *ngIf="isLoadingCheck && isLoadingCheckProses">
                    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px', 'padding-top': appServiceScreenMobileSmall ? '10px' : '20px'}" style="font-weight: 400;color: #717171;">{{setMessage}}</p>
                </div>
                <app-loading *ngIf="isLoadingCheck == false && isLoadingCheckProses"></app-loading>
            </form>
        </mat-tab>
    </mat-tab-group>
</div>
<mat-dialog-actions [ngStyle]="{'display': appServiceScreenMobile ? 'flex' : '', 'align-items': appServiceScreenMobile ? 'center' : ''}">
    <!-- <div class="col-6" [ngStyle]="{'padding-left': appServiceScreenMobile ? '0px' : '20px'}">
        <label style="font-size: 12px;font-weight: 400;color: #717171;">{{'Total_deductions' | translate}}</label>
        <p style="font-size: 16px;font-weight: 700;color: #0e8667;">Rp 9.000</p>
    </div> -->
    <div class="col-12" style="text-align: end;">
        <button (click)="setVoucher()" disabled="{{selectVoucher != undefined ? false : true}}" [ngStyle]="{'background-color': selectVoucher != undefined ? '#0e8667' : '#DDDDDD'}" style="width: 100%;border-radius: 8px;font-size: 16px;font-weight: 700;color: white;" mat-button>{{'Continue' | translate}}</button>
    </div>
</mat-dialog-actions>
