import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Injectable()
export class AppService {
  redirectUrl: any;
  // private historyNavigation: String[] = [];

  private isFooter = new BehaviorSubject<boolean>(true);
  private isFooter$ = this.isFooter.asObservable();

  private isFooterMobileBottom = new BehaviorSubject<string>('');
  private isFooterMobileBottom$ = this.isFooterMobileBottom.asObservable();

  private isPrintMobile = new BehaviorSubject<boolean>(false);
  private isPrintMobile$ = this.isPrintMobile.asObservable();

  private isError = new BehaviorSubject<boolean>(true);
  private isError$ = this.isError.asObservable();

  private isHomeWhy = new BehaviorSubject<boolean>(false);
  private isHomeWhy$ = this.isHomeWhy.asObservable();

  private isMobile$ = new BehaviorSubject<boolean>(false);
  private isDesktop$ = new BehaviorSubject<boolean>(false);
  private isMobileSmall$ = new BehaviorSubject<boolean>(false);

  private isTotalQty = new BehaviorSubject<number>(0);
  private isTotalQty$ = this.isTotalQty.asObservable();

  private isQty = new BehaviorSubject<number>(0);
  private isQty$ = this.isQty.asObservable();

  constructor(private title: Title, @Inject(DOCUMENT) private document: Document, private breakpointObserver: BreakpointObserver, private router: Router) {
    this.breakpointObserver.observe([Breakpoints.XSmall, 'XSmall', Breakpoints.Small, 'Small']).pipe(map(breakpoint => {
      this.isMobile$.next(breakpoint.matches);
    })).subscribe();
    this.breakpointObserver.observe([Breakpoints.Medium, 'Medium',Breakpoints.Large, 'Large', Breakpoints.XLarge, 'XLarge']).pipe(map(breakpoint => {
      this.isDesktop$.next(breakpoint.matches);
    })).subscribe();
    this.breakpointObserver.observe(['(max-width: 1000px)']).pipe(map(breakpoint => {
      this.isMobileSmall$.next(breakpoint.matches);
    })).subscribe();
  }

  get breakpointMobile$() {
    return this.isMobile$.asObservable();
  }

  get breakpointDesktop$() {
    return this.isDesktop$.asObservable();
  }

  get breakpointMobileSmall$() {
    return this.isMobileSmall$.asObservable();
  }

  setPageTitle(title: string) {
    this.title.setTitle(title);
  }

  getPageTitle() {
      return this.title.getTitle();
  }

  createLinkForCanonicalURL() {
    let link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', this.document.URL);
  }

  sideBar() {
    this.document.body.classList.toggle('toggle-sidebar');
  }

  setIsFooter(isFooter: boolean) {
    this.isFooter.next(isFooter);
  }

  getIsFooter(): Observable<boolean> {
    return this.isFooter$;
  }

  setIsFooterMobileBottom(isFooterMobileBottom: string) {
    this.isFooterMobileBottom.next(isFooterMobileBottom);
  }

  getIsFooterMobileBottom(): Observable<string> {
    return this.isFooterMobileBottom$;
  }

  setIsPrintMobile(isPrintMobile: boolean) {
    this.isPrintMobile.next(isPrintMobile);
  }

  getIsPrintMobile(): Observable<boolean> {
    return this.isPrintMobile$;
  }

  setIsError(isError: boolean) {
    this.isError.next(isError);
  }

  getIsError(): Observable<boolean> {
    return this.isError$;
  }

  setIsHomeWhy(isHomeWhy: boolean) {
    this.isHomeWhy.next(isHomeWhy);
  }

  getIsHomeWhy(): Observable<boolean> {
    return this.isHomeWhy$;
  }

  setIsTotalQty(isTotalQty: number) {
    this.isTotalQty.next(isTotalQty);
  }

  getIsTotalQty(): Observable<number> {
    return this.isTotalQty$;
  }

  setIsQty(isQty: number) {
    this.isQty.next(isQty);
  }

  getIsQty(): Observable<number> {
    return this.isQty$;
  }

  reloadPage(self: boolean, urlToNavigateTo?: string) {
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
      this.router.navigate([`/${url}`]).then(()=>{ })
    })
  }
  
  // goBack(): void {
    // this.historyNavigation.pop();

    // if (this.historyNavigation.length > 0) {
      // this.location.back();
    // } else {
    //   this.router.navigateByUrl("/");
    // }
  // }

  // getPreviousUrl(): String {
  //   if (this.historyNavigation.length > 0) {
  //     return this.historyNavigation[this.historyNavigation.length - 2];
  //   }

  //   return '';
  // }
}