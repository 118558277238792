<div *ngIf="progress == 0" class="container" style="margin-top: 110px;display: flex;justify-content: center;">
    <div [ngStyle]="{'width': appServiceScreenMobile ? '' : '723px', 'height': appServiceScreenDesktop && isUpload ? '476px' : '', 'border': appServiceScreenMobile ? 'none' : '1px solid #DDDDDD'}" style="border-radius: 12px;padding: 20px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <div style="text-align: center;">
            <img alt="chatfoto" ngSrc="../../../../assets/img/first-upload.webp" width="{{appServiceScreenMobileSmall ? '180px' : '320px'}}" height="{{appServiceScreenMobileSmall ? '150px' : '250px'}}">
            <input style="display: none" multiple type="file" accept="image/png, image/jpg, image/jpeg, image/webp, image/heic" (change)="selectFile($event)" #hiddenfileinput>
            <button (click)="hiddenfileinput.click()" [ngStyle]="{'width': appServiceScreenMobile ? '100%' : '400px', 'height': appServiceScreenMobile ? '42px' : '56px', 'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '12px' : '14px' : '16px'}" mat-button style="border-radius: 8px;box-shadow: 0 0 50px rgba(48, 164, 135, 0.35);background: linear-gradient(90deg, #A5C296 0%, #1DAB87 21.87%, #1DAB87 47.92%, #0092CC 100%);font-weight: 700;color: white;margin: 30px 0px 20px 0px;"><i style="color: white;" class="bi bi-plus-circle-fill"></i> {{'Select_photo' | translate}}</button>
            <p [ngStyle]="{'font-size': appServiceScreenMobile ? appServiceScreenMobileSmall ? '10px' : '12px' : '14px'}" style="text-align: center;width: 100%;padding: 10px;margin-top: 20px;background-color: rgba(242, 251, 254, 1);font-weight: 400;color: #717171;">{{'Read_the_guidelines_for_uploading_photos' | translate}} <label (click)="appService.reloadPage(false, 'contact')" [ngStyle]="{'font-size': appServiceScreenMobile ? '12px' : '14px'}" style="font-weight: 700;color: #0e8667;cursor: pointer;">{{'here' | translate}}</label></p>
        </div>
    </div>
</div>
<div class="container" *ngIf="progress > 0" [ngStyle]="{'display': appServiceScreenDesktop ? 'flex' : 'block'}" style="margin-top: 110px;justify-content: center;">
    <div [ngStyle]="{'width': appServiceScreenMobile ? '' : '723px', 'height': appServiceScreenDesktop ? '476px' : '', 'border': appServiceScreenMobile ? 'none' : '1px solid #DDDDDD', 'padding': appServiceScreenDesktop ? '20px' : ''}" style="border-radius: 12px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <p [ngStyle]="{'font-size': appServiceScreenMobile ? '12px' : '14px'}" style="width: 100%;font-weight: 400;color: #717171;text-align: center;">Uploading, please wait...</p>
        <div class="progress" style="width: 100%;">
            <div
              class="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              attr.aria-valuenow="{{ progress }}"
              aria-valuemin="0"
              aria-valuemax="100"
              [ngStyle]="{ width: progress + '%' }">
              {{ progress }}%
            </div>
        </div>
    </div>
</div>
  