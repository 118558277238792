<div *ngIf="appServiceScreenDesktop" style="text-align: center;text-align: -webkit-center;">
    <h2 style="margin-top: 80px;color: #222222;font-weight: 700;">{{'Highlight_product' | translate}}</h2>
    <p style="font-size: 20px;font-weight: 400;color: #222222;">{{'Highlight_product_description' | translate}} <label style="color: #0e8667;">Rp 2.500</label></p>
    <div class="container">
        <div *ngIf="isLoading == false; else hideLoading" class="row">
          <div *ngFor="let row of data" class="col-sm">
            <div class="card">
                <img alt="chatfoto" class="card-img-top" style="border-top-left-radius: 16px;border-top-right-radius: 16px;object-fit: cover;" width="250" height="auto" ngSrc="{{row.image_big_url}}" alt="Card image cap">
                <div style="padding: 10px;text-align: left;">
                  <label style="font-size: 16px;font-weight: 700;color: #222222;">{{lang == 'id' ? row.translation.id.name : row.translation.en.name}}</label>
                  <p [ngStyle]="{'height': row.description != null ? '' : '36px'}" style="font-size: 12px;font-weight: 400;color: #717171;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">{{lang == 'id' ? row.translation.id.description : row.translation.en.description}}</p>
                </div>
                <div style="display: flex;justify-content: space-between;padding: 0px 10px 10px 10px;align-items: center;">
                    <div style="display: flex;justify-content: space-between;padding: 0px 10px 10px 10px;align-items: start;flex-direction: column;">
                        <label [ngStyle]="{'height': row.price_strikethrough != null ? '' : '18px'}" style="font-size: 14px;font-weight: 700;text-decoration: line-through;color: #CCCCCC;">{{(row.price_strikethrough| currency:'Rp ':'symbol':'3.0')?.replace(',', '.')}}</label>
                        <label style="font-size: 20px;font-weight: 700;color: #0e8667;">{{(row.price| currency:'Rp ':'symbol':'3.0')?.replace(',', '.')}}<label style="font-size: 14px;font-weight: 400;color: #717171;">/pc</label></label>
                    </div>
                    <button routerLink="/photo/{{row.id}}/false/true/false" style="width: 128px;height: 36px;border-radius: 8px;background-color: #0e8667;font-size: 14px;font-weight: 700;color: white;" mat-button>{{'Order' | translate}}</button>
                </div>
            </div>
          </div>
        </div>
        <ng-template #hideLoading>
            <app-loading></app-loading>
        </ng-template>
    </div>
</div>
<div class="container" *ngIf="appServiceScreenMobile" style="text-align: start;">
    <h4 [ngStyle]="{'margin-top': appServiceScreenMobileSmall ? '30px' : '50px'}" style="color: #222222;font-weight: 700;">{{'Highlight_product' | translate}}</h4>
    <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #222222;">{{'Highlight_product_description' | translate}} <label style="color: #0e8667;">Rp 2.500</label></p>
    <div>
        <div *ngFor="let row of data" style="display: flex;margin: 20px 0px 10px 0px;align-items: center;background-color: white;margin-top: 20px;width: 100%;border: 1px solid #E8E8E8;border-radius: 12px;padding: 15px;justify-content: space-between;">
            <div style="display: flex;align-items: center;">
                <div style="margin-right: 20px;">
                    <img alt="chatfoto" ngSrc="{{row.image_small_url}}" style="border-radius: 4px;object-fit: cover;" width="48px" height="48px">
                </div>
                <div style="display: flex;flex-direction: column;">
                    <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #222222;font-weight: 700;">{{row.name}}</label>
                    <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '10px' : '12px'}" *ngIf="row.price_strikethrough != null" style="font-weight: 700;color: #CCCCCC;text-decoration: line-through;">{{(row.price_strikethrough| currency:'Rp ':'symbol':'3.0')?.replace(',', '.')}}</label>
                    <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #0e8667;">{{(row.price| currency:'Rp ':'symbol':'3.0')?.replace(',', '.')}}<label style="font-size: 12px;font-weight: 400;color: #717171;">/pc</label></label>
                </div>
            </div>
            <div>
                <button routerLink="/photo/{{row.id}}/false/true/false" style="border-radius: 8px;background-color: #0e8667;font-size: 12px;font-weight: 700;color: white;" mat-button>{{'Order' | translate}}</button>
            </div>
        </div>
    </div>
</div>