<mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState1 = true"
                (closed)="panelOpenState1 = false" [ngStyle]="{'background-color': panelOpenState1 ? '#F2FBFE' : 'transparent', 'border-radius': panelOpenState1 ? '12px' : '0px'}">
        <mat-expansion-panel-header>
        <mat-panel-title [ngStyle]="{'font-size': appServiceScreenDesktop ? '20px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #222222;">
            {{'faqQ12' | translate}}
        </mat-panel-title>
        </mat-expansion-panel-header>
        <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;padding: 10px;text-align: start;">{{'faqA12' | translate}}</p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState2 = true" style="margin: 15px 0px 15px 0px;"
                (closed)="panelOpenState2 = false" [ngStyle]="{'background-color': panelOpenState2 ? '#F2FBFE' : 'transparent', 'border-radius': panelOpenState2 ? '12px' : '0px'}">
        <mat-expansion-panel-header>
        <mat-panel-title  [ngStyle]="{'font-size': appServiceScreenDesktop ? '20px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #222222;">
            {{'faqQ22' | translate}}
        </mat-panel-title>
        </mat-expansion-panel-header>
        <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;padding: 10px;text-align: start;">{{'faqA22' | translate}}</p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState3 = true" style="margin: 15px 0px 15px 0px;"
                (closed)="panelOpenState3 = false" [ngStyle]="{'background-color': panelOpenState3 ? '#F2FBFE' : 'transparent', 'border-radius': panelOpenState3 ? '12px' : '0px'}">
        <mat-expansion-panel-header>
        <mat-panel-title  [ngStyle]="{'font-size': appServiceScreenDesktop ? '20px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #222222;">
            {{'faqQ32' | translate}}
        </mat-panel-title>
        </mat-expansion-panel-header>
        <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;padding: 10px;text-align: start;">{{'faqA32' | translate}}</p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState4 = true" style="margin: 15px 0px 15px 0px;"
                (closed)="panelOpenState4 = false" [ngStyle]="{'background-color': panelOpenState4 ? '#F2FBFE' : 'transparent', 'border-radius': panelOpenState4 ? '12px' : '0px'}">
        <mat-expansion-panel-header>
        <mat-panel-title  [ngStyle]="{'font-size': appServiceScreenDesktop ? '20px' : appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 400;color: #222222;">
            {{'faqQ42' | translate}}
        </mat-panel-title>
        </mat-expansion-panel-header>
        <p [ngStyle]="{'font-size': appServiceScreenDesktop ? '16px' : appServiceScreenMobileSmall ? '12px' : '14px'}" style="font-weight: 400;color: #717171;padding: 10px;text-align: start;">{{'faqA42' | translate}}</p>
    </mat-expansion-panel>
</mat-accordion>