<header id="header">
    <div class="header fixed-top" [ngStyle]="{'height': appServiceScreenMobileSmall || appServiceScreenMobile ? '52px' : '72px'}" style="box-shadow: 0px 2px 10px 0px rgba(1, 41, 112, 0.1);display: flex;align-items: center;">
      <div style="display: flex;align-items: center;margin-left: 10px;">
        <button (click)="back()" style="display: flex;align-items: center;" mat-icon-button aria-label="back"><i class="bi bi-arrow-left-short"></i></button>
        <label *ngIf="appServiceScreenDesktop">{{'Back' | translate}}</label>
      </div>
      <div [ngStyle]="{'width': appServiceScreenMobile ? '' : '100%', 'justify-content': appServiceScreenMobile ? '' : 'center'}" style="display: flex;align-items: center;">
        <img alt="chatfoto" loading="eager" src={{data.photo_size.image_icon}} [ngStyle]="{'width': appServiceScreenMobileSmall || appServiceScreenMobile ? '38px' : '48px', 'height': appServiceScreenMobileSmall || appServiceScreenMobile ? '38px' : '48px'}" style="border-radius: 4px;margin-right: 10px;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall || appServiceScreenMobile ? '12px' : '14px'}" style="color: #222222;font-weight: 700;">
          {{data.photo_size.name}}
        </label>
      </div>
      <div *ngIf="appServiceScreenDesktop && data.data.length > 0">
        <button (click)="addToCart()" mat-button style="border-radius: 8px;background: linear-gradient(90deg, #A5C296 0%, #1DAB87 21.87%, #1DAB87 47.92%, #0092CC 100%);font-weight: 700;font-size: 14px;color: white;inline-size: max-content;margin-right: 10px;"> Save & add to cart ({{totalQty}})</button>
      </div>
    </div>
</header>