import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { ApiService as ApiCart } from 'src/app/services/cart/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent {
  myHtml: String | undefined;
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  isLoading: boolean = true;
  urlPayment: string = ''

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<OrderConfirmationComponent>,
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<OrderConfirmationComponent>,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private apiCart: ApiCart,
    private snackBar: MatSnackBar,
    public appService: AppService
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.myHtml = "<p>Your order is about to be finalized. Please note that we cannot cancel orders once they are placed. Ensure you have verified:</p><ul><li>Your delivery address</li><li>Payment method</li><li>Applied any applicable vouchers.</li></ul>";
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }
  
  async callPaymentMethod() {
    this.isLoading = false;

    (await this.apiCart.confirmOrder())
    .subscribe({
      next: (response) => {
        this.isLoading = true

        if(response.status == 201) {
          this.urlPayment = response.result.payment.redirect_url
          this.getQty()
        } else {
          this.snackBar.open(response.message, '', {
            duration: 3 * 1000,
            panelClass: ['failed-snackbar']
          })
        }
      },
      error: (error) => {
        this.isLoading = true
        this.appService.reloadPage(true)
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
        this.closeDialog()
      },
    })
  }

  payment(redirectUrl: string) {
    window.location.href = redirectUrl
    this.appService.reloadPage(false, 'my-order')
    this.closeDialog()
  }

  closeDialog() {
    if(this.appServiceScreenMobile) {
      this.bottomSheetRef.dismiss()
    } else {
      this.dialogRef.close()
    }
  }

  async getQty() {
    (await this.apiCart.cartQty())
    .subscribe({
      next: (v) => {
        this.appService.setIsQty(v.total)
      },
      error: (e) => { },
      complete: () => { }
    })
  }

  
}
