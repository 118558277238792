<aside id="sidebar" class="sidebar" [ngStyle]="{'top': appServiceScreenMobileSmall ? '60px' : '70px'}">
    <ul class="sidebar-nav" id="sidebar-nav">
       <li class="nav-item" *ngIf="isLogin == false; else show" (click)="callLogin()" style="cursor: pointer;padding: 10px;"> <i class="bi bi-person-circle" style="margin-right: 10px;"></i> <span [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #717171;">{{'Log_in' | translate}}</span> </li>
       <ng-template #show>
        <li class="nav-item" style="padding: 10px;display: flex;justify-content: space-between;align-items: center;"> 
            <div (click)="clickMenu('account')" routerLink="account">
                <i class="bi bi-person-circle" style="margin-right: 10px;"></i> <span [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="color: #717171;">{{fullName}}</span> 
            </div>
            <div (click)="callLogout()" style="cursor: pointer;color: #b8505b;">
                <i class="bi bi-box-arrow-right"></i> <label style="margin-left: 10px;padding: 10px;cursor: pointer;">{{'Log_out' | translate}}</label>
            </div>
        </li>
       </ng-template>
       <hr style="margin: 0px;">
       <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #999999;" (click)="clickMenu('/')" routerLink="/" aria-label="home"> {{'Home' | translate}} </a>
        <a class="nav-link" [routerLinkActive]="['active']" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #999999;" (click)="clickMenu('print')" routerLink="print" aria-label="print"> {{'Print' | translate}} </a>
        <a class="nav-link" [routerLinkActive]="['active']" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #999999;" (click)="clickMenu('my-order')" routerLink="my-order" aria-label="my-order"> {{'My_Order' | translate}} </a>
        <a class="nav-link" [routerLinkActive]="['active']" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #999999;" (click)="clickMenu('about-us')" routerLink="about-us" aria-label="about-us"> {{'About_us' | translate}} </a>
        <a class="nav-link" [routerLinkActive]="['active']" [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="font-weight: 700;color: #999999;" (click)="clickMenu('contact')" routerLink="contact" aria-label="contact"> {{'Contact' | translate}} </a>
       <hr style="margin: 0px;">
    </ul>
    <div style="padding: 15px;">
        <p [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;">{{'Language' | translate}}</p>
        <div style="border: 1px solid #DDDDDD;border-radius: 8px;width: max-content;display: flex;">
            <div (click)="switchLanguage('id')" [ngStyle]="{'background-color': flag == 'id' ? '#DDDDDD' : 'white', 'color': flag == 'id' ? '#0e8667' : '#717171'}" style="padding-left: 15px;border-top-left-radius: 6px;border-bottom-left-radius: 6px;"><img alt="chatfoto" ngSrc="../../../../../assets/img/id.webp" width="20px" height="16px"> <label [ngStyle]="{'padding': appServiceScreenMobileSmall ? '8px' : '10px'}">IND</label></div>
            <div (click)="switchLanguage('en')" [ngStyle]="{'background-color': flag == 'en' ? '#DDDDDD' : 'white', 'color': flag == 'en' ? '#0e8667' : '#717171'}" style="padding-left: 15px;background-color: #DDDDDD;border-top-right-radius: 6px;border-bottom-right-radius: 6px;"><img alt="chatfoto" ngSrc="../../../../../assets/img/en.webp" width="20px" height="16px"> <label [ngStyle]="{'padding': appServiceScreenMobileSmall ? '8px' : '10px'}">ENG</label></div>
        </div>
    </div>
</aside>