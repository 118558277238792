import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { PhotoModel } from 'src/app/models/photo-model';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService as ApiPhoto } from 'src/app/services/photo/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingDialogComponent } from 'src/app/components/loading/loading-dialog/loading-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header-photo',
  templateUrl: './header-photo.component.html',
  styleUrls: ['./header-photo.component.css']
})
export class HeaderPhotoComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  @Input() data!: PhotoModel.Results;
  @Input() totalQty!: number;
  isCart!: string;

  constructor(private location: Location, private translate: TranslateService, private appService: AppService, private cd: ChangeDetectorRef, private apiPhoto: ApiPhoto, private router: Router, private snackBar: MatSnackBar, private dialog: MatDialog, private activedRoute: ActivatedRoute) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en')
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!)
    }

    this.isCart = this.activedRoute.snapshot.params['addToCart']
  }

  ngOnInit(): void {
    this.appService.getIsTotalQty().subscribe(appTotalQty => this.totalQty = appTotalQty);
    this.cd.detectChanges();
  }

  async addToCart() {
    this.dialog.open(LoadingDialogComponent, { disableClose: true });

    (await this.apiPhoto.saveToCart(JSON.parse(localStorage.getItem('idPhoto')!), JSON.parse(localStorage.getItem('qty')!), JSON.parse(localStorage.getItem('whiteBorder')!)))
    .subscribe({
      next: (response) => {
        this.dialog.closeAll()

        if(response.code == 201) {
          const redirect = 'cart'
          this.router.navigate([redirect], { replaceUrl: true })

          localStorage.removeItem(this.activedRoute.snapshot.params['id'])
          localStorage.removeItem('idPhoto')
          localStorage.removeItem('qty')
          localStorage.removeItem('whiteBorder')
        }

        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })
      },
      error: (error) => {
        this.dialog.closeAll()
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  back() {
    if (this.isCart == 'true') {
      this.appService.reloadPage(false, 'cart')
    } else {
      this.appService.reloadPage(false, 'print')
    }
    localStorage.removeItem('idPhoto')
    localStorage.removeItem('qty')
    localStorage.removeItem('whiteBorder')
  }

  
}
