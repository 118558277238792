import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { DetailComponent } from '../dialog/voucher/detail/detail.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppService } from 'src/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService as ApiProduct } from 'src/app/services/product/api.service';
import { ApiService as ApiVoucher } from 'src/app/services/voucher/api.service';
import { ProductModel } from 'src/app/models/product-model';
import { VoucherModel } from 'src/app/models/voucher-model';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintComponent {
  isLoading: boolean = true;
  isLoadingVoucher: boolean = true;
  data!: ProductModel.Result[];
  dataVoucher!: VoucherModel.Result[]
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  lang: string = 'en'

  constructor(private dialog: MatDialog, private bottomSheet: MatBottomSheet, private appService: AppService, private translate: TranslateService, private apiProduct: ApiProduct, private apiVoucher: ApiVoucher) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.appService.setIsPrintMobile(false)
    this.appService.setIsFooterMobileBottom('')
    Promise.all([this.getVoucher(), this.getProduct()])

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en')
      this.lang = 'en'
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!)
      this.lang = localStorage.getItem('lang')!
    }
  }

  callDetail(tnc: any) {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(DetailComponent, { data: {isClose: false, tnc: tnc}, disableClose: true });
    } else {
      this.dialog.open(DetailComponent, { data: {isClose: false, tnc: tnc}, disableClose: true })
    }
  }

  async getVoucher() {
    (await this.apiVoucher.getVoucher(true))
    .subscribe({
      next: (v) => {
        this.isLoadingVoucher = false
        this.dataVoucher = v.results
      },
      error: (e) => {
        this.isLoadingVoucher = false
        this.dataVoucher = []
      },
      complete: () => {
        this.isLoadingVoucher = false
      }
    });
  }

  async getProduct() {
    (await this.apiProduct.getData(false))
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.data = v.results
      },
      error: (e) => {
        this.isLoading = false
      },
      complete: () => {
        this.isLoading = false
      }
    });
  }
}
