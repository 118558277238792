import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin) { }

  public async getTransaction(status: string) {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    let params = new HttpParams();
      params = params.append("status", status);
    return this.httpClient.get<any>(`${this.baseUrl}transaction`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }

  public async getReOrder(id: number) {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    let params = new HttpParams();
      params = params.append("transaction_id", id);
    return this.httpClient.get<any>(`${this.baseUrl}transaction-reorder`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }

  public async getInvoice(id: number) {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    let params = new HttpParams();
      params = params.append("transaction_id", id);
    return this.httpClient.get<any>(`${this.baseUrl}transaction-invoice`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }

  public async cancelTransaction(id: number) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    let params = new HttpParams();
      params = params.append("transaction_id", id);
    return this.httpClient.get<any>(`${this.baseUrl}transaction-cancel`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }
}
