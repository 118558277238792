<div mat-dialog-content style="padding-bottom: 20px;text-align: -webkit-center;">
    <span style="flex: 1 1 auto;">
      <mat-label>{{message | translate}}</mat-label>
    </span>
</div>

<div mat-dialog-actions>
    <div *ngIf="isLoading; else showLoading" style="display: flex;justify-content: space-between;">
      <button *ngIf="yes" mat-stroked-button (click)="deleted()" style="color: rgb(187, 187, 187);width: 100%;">{{'yes' | translate}}</button>&nbsp;
      <button *ngIf="no" mat-flat-button (click)="closeDialog()" style="background-color: #1eaa87;color: white;width: 100%;" mat-dialog-close>{{'no' | translate}}</button>
    </div>

    <ng-template #showLoading>
        <button mat-stroked-button disabled class="btn-block" style="color: rgb(187, 187, 187);width: 100%;">
            <mat-progress-spinner color="accent" diameter="20" style="margin: 10px;display: inline-block;" mode="indeterminate"></mat-progress-spinner>
        </button>
    </ng-template>
</div>