import { Component, ElementRef, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ApiService as ApiCart } from 'src/app/services/cart/api.service';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.css']
})
export class ParentComponent implements OnInit {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;

  constructor(private appService: AppService, private elementRef: ElementRef, private apiCart: ApiCart) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.getQty()
  }

  ngOnInit(): void {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../../assets/js/main.js";
    this.elementRef.nativeElement.appendChild(s);
  }

  async getQty() {
    (await this.apiCart.cartQty())
    .subscribe({
      next: (v) => {
        this.appService.setIsQty(v.total)
      },
      error: (e) => { },
      complete: () => { }
    })
  }

}
