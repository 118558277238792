<div class="container" *ngIf="progress > 0" [ngStyle]="{'display': appServiceScreenDesktop ? 'flex' : 'block'}" style="justify-content: center;padding: 30px;">
    <div [ngStyle]="{'width': appServiceScreenMobile ? '' : '723px', 'height': appServiceScreenDesktop ? '476px' : '', 'border': appServiceScreenMobile ? 'none' : '1px solid #DDDDDD', 'padding': appServiceScreenDesktop ? '20px' : ''}" style="border-radius: 12px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <p [ngStyle]="{'font-size': appServiceScreenMobile ? '12px' : '14px'}" style="width: 100%;font-weight: 400;color: #717171;text-align: center;">Uploading, please wait...</p>
        <div class="progress" style="width: 100%;">
            <div
              class="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              attr.aria-valuenow="{{ progress }}"
              aria-valuemin="0"
              aria-valuemax="100"
              [ngStyle]="{ width: progress + '%' }">
              {{ progress }}%
            </div>
        </div>
    </div>
</div>