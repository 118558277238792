import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';
import { RecipientModel } from 'src/app/models/recipient-model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient, private apiLogin: ApiLogin) { }

  public showRecipient() {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.get<any>(`${this.baseUrl}recipient`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async createRecipient(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}recipient`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async updateRecipient(id: number, recipientModel: RecipientModel.Result) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    let params = new HttpParams()
      params = params.append("name", recipientModel.name);
      params = params.append("phone_number", recipientModel.phone_number);
      params = params.append("is_default", recipientModel.default ? 1 : 0);
    return this.httpClient.put(`${this.baseUrl}recipient/${id}`, params, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async deleteRecipient(id: number) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.delete<any>(`${this.baseUrl}recipient/${id}`, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public async setDefaultRecipient(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}recipient-default`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }

  public setRecipient(formData: FormData) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.apiLogin.getToken());
    return this.httpClient.post<any>(`${this.baseUrl}recipient-select`, formData, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
