import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PinturaEditorComponent } from '@pqina/angular-pintura';
import { 
  createDefaultImageOrienter, createDefaultImageReader, createDefaultImageWriter, 
  createDefaultShapePreprocessor, createNode, getEditorDefaults, locale_en_gb, 
  PinturaNode, plugin_crop, plugin_crop_locale_en_gb, plugin_frame, 
  plugin_frame_locale_en_gb, plugin_sticker, plugin_sticker_locale_en_gb, 
  setPlugins, plugin_annotate, plugin_annotate_locale_en_gb, createMarkupEditorToolbar, 
  createMarkupEditorToolStyles, createMarkupEditorToolStyle, plugin_decorate, 
  plugin_decorate_locale_en_gb, createMarkupEditorShapeStyleControls, 
  markup_editor_defaults, plugin_finetune_defaults, plugin_finetune_locale_en_gb, 
  markup_editor_locale_en_gb, plugin_finetune, effectBrightness, PinturaNodeOptions 
} from '@pqina/pintura';
import { AppService } from 'src/app/app.service';
import { LoadingDialogComponent } from 'src/app/components/loading/loading-dialog/loading-dialog.component';
import { PhotoModel } from 'src/app/models/photo-model';
import { ApiService as ApiPhoto } from 'src/app/services/photo/api.service';

setPlugins(
  plugin_crop,
  // plugin_finetune,
  // plugin_frame, 
  // plugin_annotate, 
)

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {
  photoModel!: PhotoModel.Daum;
  result?: string;
  cropAspectRatio = 1;
  currentImage!: string | null;
  idPhoto!: number;
  idSize!: number;
  resultPhotoFinal: any;
  editorDefaults: any;
  
  shapePreprocessor: any = createDefaultShapePreprocessor();

  imageCrop: any

  frameOptions: any = [];
  frameStyles: any = {};

  finetuneOptions: any = [
    ['brightness', (locale: { finetuneLabelBrightness: any; }) => locale.finetuneLabelBrightness],
  ];

  finetuneControls: any = {
    brightness: {
        // the base value of the range input
        base: 0,

        // the minimum range input value
        min: -0.50,

        // the maximum range input value
        max: 0.50,

        // override the label value to render
        getLabel: (value: number) => Math.round(value * 100),

        // called to request access to an internal store
        getStore: (imageColorMatrix: any ) => imageColorMatrix,

        // called to read out a value of the store,
        // receives store returned by `getStore`
        getValue: (store: { brightness: any[]; }) => {
            if (!store.brightness) return;
            return store.brightness[4];
        },

        // updates `store` value based on range input value `v`
        // receives store returned by `getStore`
        setValue: (store: { update: (arg0: (matrices: any) => any) => any; }, v: number) =>
            store.update((matrices: any) => ({
                // the `imageColorMatrix` store contains multiple matrices,
                // we need to keep these intact and then overwrite the
                // brightness matrix
                ...matrices,

                // prettier-ignore
                brightness: [
                    1, 0, 0, 0, v,
                    0, 1, 0, 0, v,
                    0, 0, 1, 0, v,
                    0, 0, 0, 1, 0
                ],
            })),
    }
  };

  handleProcess($event: any) {
    this.putPhoto($event.dest, this.idSize, $event.imageState.crop)
  }

  constructor(private sanitizer: DomSanitizer, private apiPhoto: ApiPhoto, private snackBar: MatSnackBar, private activedRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, public appService: AppService) {
    this.loadFrameNew()
    this.idPhoto = this.activedRoute.snapshot.params['id']
    this.getPhoto(this.idPhoto)
  }
  ngOnInit(): void {
    this.frameStyles = {
      67: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/67.png',
        },
        thumb: 'assets/frame/67.png',
      },
      68: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/68.png',
        },
        thumb: 'assets/frame/68.png',
      },
      4: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/4.png',
        },
        thumb: 'assets/frame/14.png',
      },
      5: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/5.png',
        },
        thumb: 'assets/frame/15.png',
      },
      8: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/8.png',
        },
        thumb: 'assets/frame/18.png',
      },
      9: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/9.png',
        },
        thumb: 'assets/frame/19.png',
      },
      10: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/10.png',
        },
        thumb: 'assets/frame/20.png',
      },
      12: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/12.png',
        },
        thumb: 'assets/frame/22.png',
      },
      13: {
        shape: {
          frameStyle: 'nine',
          frameImage: 'assets/frame/13.png',
        },
        thumb: 'assets/frame/23.png',
      },
    };
  }

  willRenderToolbar = (
    toolbar: PinturaNode[],
  ): PinturaNode[] => {
    return [
      createNode('Button', 'back-button', {
        label: 'Back',
        onclick: () => {
          if(this.activedRoute.snapshot.params['page'] == 'cart') {
            this.appService.reloadPage(false, 'cart')
          } else {
            this.appService.reloadPage(false, "/photo/"+this.idSize+"/false/true/"+this.activedRoute.snapshot.params['addToCart'])
          }
        },
      }),
      ...toolbar,
    ]
  }

  async getPhoto(id: number) {
    (await this.apiPhoto.getPhotoById(id))
    .subscribe({
      next: (response) => {
        this.photoModel = response.results
        this.idSize = response.results.photo_size.id
        this.currentImage = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.photoModel.file_name_original))

        const locale = {
          ...locale_en_gb,
          ...plugin_crop_locale_en_gb,
          ...plugin_frame_locale_en_gb,
          ...plugin_annotate_locale_en_gb,
          ...markup_editor_locale_en_gb,
          ...plugin_finetune_locale_en_gb,
        };
    
        this.editorDefaults = {
          imageReader: createDefaultImageReader(),
          imageWriter: createDefaultImageWriter(),
          shapePreprocessor: createDefaultShapePreprocessor(),
          markupEditorToolStyles: createMarkupEditorToolStyles({
            text: createMarkupEditorToolStyle('text', {
                fontSize: '10%',
            }),
          }),
          locale,
          
          imageCropAspectRatio: response.results.photo_size.ratio,
        }

        this.imageCrop = JSON.parse(response.results.crop_position)
      },
      error: (error) => {
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async putPhoto(file: any, sizeId: any, cropPosition: any) {
    this.dialog.open(LoadingDialogComponent, { disableClose: true });
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('photo_size_id', sizeId);
    formData.append('crop_position', JSON.stringify(cropPosition));

    (await this.apiPhoto.putPhoto(this.idPhoto, formData))
    .subscribe({
      next: (response) => {
        this.dialog.closeAll()
        if(response.status == 201) {
          if(this.activedRoute.snapshot.params['page'] == 'cart') {
            this.appService.reloadPage(false, 'cart')
          } else {
            this.appService.reloadPage(false, "/photo/"+this.idSize+"/false/true/"+this.activedRoute.snapshot.params['addToCart'])
          }
        }
      },
      error: (error) => {
        this.dialog.closeAll()
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  loadFrameNew() {
    this.apiPhoto.showFrameNew()
    .subscribe(
      data => {
        var dt = [];
        for(var i = 0;i < data.length;i++) {
          dt.push([data[i]['id'], () => 'Frame']);
        }
        this.frameOptions = dt;
      }, () => { }
    );
  }

  
}
