<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex;justify-content: space-between;align-items: center;">
        <div style="display: flex;flex-direction: column;">
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '16px' : '20px'}" style="font-weight: 700;">{{photos.name}}</label>
            <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '12px' : '14px'}" style="color: #717171;font-weight: 400;">{{photos.qty}} {{'items' | translate}}</label>
        </div>
        <button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
<div mat-dialog-content style="text-align: center;">
    <div [ngClass]="checkPhoto">
        <div *ngFor="let row of photos.photos" [ngClass]="{'col': appServiceScreenDesktop, 'col-sm': appServiceScreenMobile}" style="padding: 20px 10px;text-align: center;justify-content: center;display: flex;align-items: center;">
            <div [ngStyle]="{'height': row.orientation == 'LANDSCAPE' ? '' : appServiceScreenDesktop ? '250px' : '150px', 'width': row.orientation == 'LANDSCAPE' ? appServiceScreenDesktop ? '250px' : '150px' : ''}" style="position: relative;text-align: center;">
                <span style="margin: 5px 5px;padding: 2px 5px;position: absolute;background-color: #ffffffcc;border-radius: 20%;"><i class="bi bi-image"></i> {{row.qty}}</span>
                <img [ngStyle]="{'padding': row.white_border == 1 ? '0.40rem' : '', 'border': row.white_border == 1 ? '1px solid #dee2e6' : '', 'height': row.orientation == 'LANDSCAPE' ? '' : '100%', 'width': row.orientation == 'LANDSCAPE' ? '100%' : ''}" style="background-position: center;background-size: contain;background-repeat: no-repeat;" src="{{row.file_name}}">
            </div>
        </div>
    </div>
</div>
