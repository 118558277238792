<div class="container" [ngStyle]="{'width': appServiceScreenDesktop ? '80%' : '100%'}">
    <div style="text-align: center;padding: 50px;">
        <img alt="chatfoto" ngSrc="../../../../assets/img/notfound.webp" width="192" height="150">
        <p style="font-size: 20px;font-weight: 700;">{{'Payment_Pending' | translate}}</p>
        <p style="font-size: 16px;font-weight: 400;color: #555555;">{{'order_success1' | translate}} <br> {{'order_success2'}}</p>
        <div class="row">
            <div class="col-sm">
                <button (click)="appService.reloadPage(false, 'my-order')" style="margin-top: 5px;width: 100%;border-radius: 8px;background-color: #0e8667;font-size: 16px;font-weight: 700;color: white;" mat-button>{{'continue_payment' | translate}}</button>
            </div>
        </div>
    </div>
</div>