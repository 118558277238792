import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ProductModel } from 'src/app/models/product-model';
import { ApiService as ApiProduct } from 'src/app/services/product/api.service';

@Component({
  selector: 'app-highlight-product',
  templateUrl: './highlight-product.component.html',
  styleUrls: ['./highlight-product.component.css']
})
export class HighlightProductComponent {
  isLoading: boolean = true;
  data!: ProductModel.Result[];
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  lang: string = 'en'

  constructor(private appService: AppService, private apiProduct: ApiProduct, private translate: TranslateService) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en')
      this.lang = 'en'
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!)
      this.lang = localStorage.getItem('lang')!
    }

    this.getProduct()
  }

  async getProduct() {
    (await this.apiProduct.getData(true))
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.data = v.results
      },
      error: (e) => {
        this.isLoading = false
      },
      complete: () => {
        this.isLoading = false
      }
    });
  }

}
