import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { TransactionModel } from 'src/app/models/transaction-model';

@Component({
  selector: 'app-detail-my-order',
  templateUrl: './detail-my-order.component.html',
  styleUrls: ['./detail-my-order.component.css']
})
export class DetailMyOrderComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  photos!: TransactionModel.DetailProduct;
  checkPhoto: string = 'row row-cols-4';

  constructor(@Inject(Injector)public injector: Injector,
  private dialogRef: MatDialogRef<DetailMyOrderComponent>,
  private bottomSheetRef: MatBottomSheetRef<DetailMyOrderComponent>,
  public appService: AppService) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA);
    
      this.photos = this.data == undefined ? '' : this.data['photos']
      if(this.appServiceScreenDesktop) {
        this.checkPhoto = this.photos.photos.length > 4 ? 'row row-cols-4' : 'row row-cols-'+this.photos.photos.length
      } else {
        this.checkPhoto = 'row'
      }
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA);
    
      this.photos = this.dataMobile == undefined ? '' : this.dataMobile['photos']
      if(this.appServiceScreenDesktop) {
        this.checkPhoto = this.photos.photos.length > 4 ? 'row row-cols-4' : 'row row-cols-'+this.photos.photos.length
      } else {
        this.checkPhoto = this.photos.photos.length == 1 ? 'row' : 'row row-cols-2'
      }
    }
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }
}
