import { Component, Inject, Injector, Optional } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { RegistrationComponent } from '../registration/registration.component';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService as ApiLogin } from 'src/app/services/login/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  form!: FormGroup;
  isButton: boolean = true;
  isLoading: boolean = true;
  url!: string;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  showPassword: string = 'password';
  existingUser: boolean = false;
  setButton: string = 'next';

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<LoginComponent>,
    private bottomSheetRef: MatBottomSheetRef<LoginComponent>,
    private appService: AppService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private apiLogin: ApiLogin,
    private snackBar: MatSnackBar,
    public fb: FormBuilder,
  ) {
    appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    })

    if(this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA);
    
      this.url = this.data == undefined ? '' : this.data['returnUrl'];
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA);
    
      this.url = this.dataMobile == undefined ? '' : this.dataMobile['returnUrl'];
    }

    console.log(`Url => ${this.url}`)
  }

  showHidePassword() {
    if(this.showPassword == 'password') {
      this.showPassword = 'text'
    } else {
      this.showPassword = 'password'
    }
  }

  send() {
    this.isLoading = false;

    var formData: any = new FormData()
    formData.append('email', this.form.get('email')?.value)
    formData.append('password', this.form.get('password')?.value)
    
    this.apiLogin.postLogin(formData)
    .subscribe({
      next: (response) => {
        this.isLoading = true;
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })

        this.profile(response.result['token'])
      },
      error: (error) => {
        this.isLoading = true;
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  checkUser() {
    this.isLoading = false;

    var formData: any = new FormData()
    formData.append('email', this.form.get('email')?.value)
    
    this.apiLogin.postCheckUser(formData)
    .subscribe({
      next: (response) => {
        this.isLoading = true;
        this.snackBar.open(response.message, '', {
          duration: 3 * 1000,
          panelClass: ['success-snackbar']
        })

        if(response.status == 200) {
          this.existingUser = true
          this.setButton = 'Log_in'
        }
      },
      error: (error) => {
        this.isLoading = true;
        if(error.status == 422) {
          this.callRegistration(true)
        }
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  profile(token: string) {
    this.apiLogin.callProfile(token)
    .subscribe({
      next: (response) => {
        if(this.appServiceScreenMobile) {
          this.bottomSheetRef.dismiss()
        } else {
          this.dialogRef.close()
        }

        window.location.assign(environment.urlIndex + this.url)
      },
      error: (error) => {
        this.isLoading = true
      },
    })
  }

  callForgetPassword() {
    if(this.appServiceScreenMobile) {
      this.bottomSheetRef.dismiss()
      this.bottomSheet.open(ForgetPasswordComponent, { disableClose: false });
    } else {
      this.dialogRef.close()
      this.dialog.open(ForgetPasswordComponent, { disableClose: false, width: '400px', })
    }
  }

  callRegistration(hold: boolean = false) {
    if(this.appServiceScreenMobile) {
      this.bottomSheetRef.dismiss()
      this.bottomSheet.open(RegistrationComponent, { disableClose: false, data: {phone_number: this.form.get('email')?.value, disabled: hold, returnUrl: this.url} });
    } else {
      this.dialogRef.close()
      this.dialog.open(RegistrationComponent, { disableClose: false, data: {phone_number: this.form.get('email')?.value, disabled: hold, returnUrl: this.url} })
    }
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }

  ngAfterContentChecked(): void {
    const hp = this.form.value.email == null ? '' : this.form.value.email == '' ? '' : this.form.value.email;
    const pw = this.form.value.password == null ? '' : this.form.value.password == '' ? '' : this.form.value.password;

    // if(hp == '' || pw == '') {
    //   this.isButton = true;
    // } else {
      this.isButton = false;
    // }
  }
}
